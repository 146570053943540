import styled from 'styled-components';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';

export const COLORS = {
  success: '#69bd4f',
  warning: '#f8ad14',
  error: 'f3d#f5e',
};

export const StyledTitle = styled.div`
  color: #031529;
  opacity: 0.4;
  font-style: italic;
  text-align: center;
  padding: 8px;
`;

export const StyledGroupTitle = styled.div`
  background-color: #031529;
  color: #fff;
  font-size: 16px;
  font-style: italic;
  padding: 8px;
`;

export const StyledTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 0 !important;
  }
  .ant-table.ant-table-small {
    font-size: 12px;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    text-align: center;
  }
`;

export const ItemWrapper = styled.div`
  &:hover {
    cursor: pointer;
    svg {
      display: block;
    }
  }
`;

export const StyledEditOutlined = styled(EditOutlined)`
  svg {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
  }
`;
