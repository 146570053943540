import React from 'react';
import { MapContainer, useMap } from 'react-leaflet';

/** Custom imports */
import { MapMarker, MarkerOptions } from 'components/elements/MapMarker';
import { LatLngExpression } from 'leaflet';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';
import { ARCGIS_TOKEN } from '../WorldMap';

interface Props {
  position: LatLngExpression;
  name: string;
  quantity: number;
}

export const StationMap: React.FC<Props> = ({ position, name, quantity }) => {
  const ChangeView = ({ center }: any) => {
    const map = useMap();
    map.panTo(center);
    return null;
  };

  const markerOptions: MarkerOptions = {
    name: name,
    position: position,
    quantity: quantity,
    units: '°C',
    showTooltip: true,
  };

  return (
    <>
      <div style={{ height: '100%' }}>
        <MapContainer
          center={position}
          zoom={12}
          scrollWheelZoom={false}
          style={{
            width: '100%',
            minHeight: '200px',
            height: '100%',
            zIndex: 0,
          }}
        >
          <ChangeView center={position} />
          <VectorBasemapLayer name="ArcGIS:Imagery" token={ARCGIS_TOKEN} />
          <MapMarker markerOptions={markerOptions} />
        </MapContainer>
      </div>
    </>
  );
};
