import React from 'react';
import video5 from '../../../assets/videos/video_5.mp4';

/*Styles*/
import { Row, Col } from 'antd';
import {
  Container,
  VideoInner,
  Video,
  StandardText,
  StyledTitle,
} from '../../../styles/tutorials';
import { Trans } from '@lingui/macro';

export const TutorialData = () => {
  return (
    <>
      <Container style={{ marginTop: '32px' }}>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>All data</Trans>
            </StyledTitle>
            <StandardText>
              <ul>
                <li>
                  <Trans>
                    Contains <b>all data</b> measured by the station at
                    10-minute intervals. The data can be filtered temporally and
                    then exported in <i>(in *.csv, *.xlsx, *.pdf format)</i>.
                  </Trans>
                </li>
              </ul>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video5} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
