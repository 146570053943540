import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Trans } from '@lingui/macro';

/** Custom Imports */
import { BotrytisReport } from 'components/elements/BotrytisReport';
import { BotrytisTable } from 'components/elements/BotrytisTable';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_BOTRYTIS } from 'graphql/queries';
import { Loader } from '..';
import _get from 'lodash/get';
import _first from 'lodash/first';
import { activeStationVar } from 'utils/cache';
import { BotrytisChart } from '../BotrytisChart';

const { Paragraph } = Typography;
const { Text } = Typography;

const InfoText = styled.div`
  text-align: left;
  padding-bottom: 32px;
`;

interface Props {
  selectedYear: number;
}

let currDate = new Date().toISOString().split('T')[0];
export const BotrytisSection: React.FC<Props> = ({ selectedYear }) => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const { data, loading, error } = useQuery(GET_BOTRYTIS, {
    variables: { stationId: activeStation.id, year: selectedYear },
    onCompleted(data: any) {},
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const botrytisData = _get(_first(data.botrytis), 'botrytisData', []);

  let botrytisIndex = undefined;

  const currentData: any = botrytisData.find((item: any) => {
    return item?.date_day == currDate;
  });

  if (currentData != undefined) {
    botrytisIndex = currentData.index_val;
  }

  return (
    <>
      <BotrytisReport botrytisIndex={botrytisIndex} />
      <BotrytisChart botrytisData={botrytisData} />
      <BotrytisTable botrytisData={botrytisData} />
    </>
  );
};
