import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { t, Trans } from '@lingui/macro';

const { Text } = Typography;
const { Paragraph } = Typography;

const InfoText = styled.div`
  text-align: left;
  padding-bottom: 32px;
`;

interface Props {
  oneDayCondition: any;
  twoDayCondition: any;
}

export const VineMoldReport: React.FC<Props> = ({
  oneDayCondition,
  twoDayCondition,
}) => {
  let oneDayText = t`Outside of the monitored period`;
  let oneDayType = undefined;

  if (oneDayCondition === true) {
    oneDayText = t`Fulfilled`;
    oneDayType = 'danger' as const;
  } else if (oneDayCondition === false) {
    oneDayText = t`Unfulfilled`;
    oneDayType = 'success' as const;
  }

  let twoDayText = t`Outside of the monitored period`;
  let twoDayType = undefined;

  if (twoDayCondition === true) {
    twoDayText = t`Fulfilled`;
    twoDayType = 'danger' as const;
  } else if (twoDayCondition === false) {
    twoDayText = t`Unfulfilled`;
    twoDayType = 'success' as const;
  }

  return (
    <InfoText>
      <Paragraph>
        <Text strong>
          <Trans>One-day conditions:</Trans>
        </Text>
        <Text type={oneDayType}>{oneDayText}</Text>
      </Paragraph>
      <Paragraph>
        <Text strong>
          <Trans>Two-day conditions:</Trans>
        </Text>
        <Text type={twoDayType}>{twoDayText}</Text>
      </Paragraph>
    </InfoText>
  );
};
