import React from 'react';
import _get from 'lodash/get';
import { Row, Col } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Trans } from '@lingui/macro';

import { GET_LOGGED_IN_USER, GET_EVENTS_BY_USER } from 'graphql/queries';

import { StyledTable, StyledTitle } from './style';

const DATE_FORMAT = 'DD.MM.YY - HH:mm:ss';

export const EventsTable = () => {
  let events = [];
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const { data, loading, error } = useQuery(GET_EVENTS_BY_USER, {
    variables: { email },
    pollInterval: 5000,
  });

  if (!loading) {
    try {
      events = data.events;
    } catch (error) {
      console.log(error);
    }
  }

  if (error) {
    return <Trans>Error</Trans>;
  }

  const columns = [
    {
      title: <Trans>Time</Trans>,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (item: any) => moment(item).format(DATE_FORMAT),
    },
    {
      title: <Trans>Condition title</Trans>,
      key: 'condition_name',
      render: (item: any) =>
        item.alarm === null ? 'Podozrenie na krádež' : item.alarm.name,
    },
    {
      title: <Trans>Weather station</Trans>,
      key: 'station',
      render: (item: any) => item.station.name,
    },
    {
      title: <Trans>Info</Trans>,
      dataIndex: 'message',
      key: 'message',
    },
  ];

  return (
    <>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTitle level={4}>
            <Trans>Events</Trans>
          </StyledTitle>
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTable
            rowKey={(record: any) => record.id}
            columns={columns}
            dataSource={loading ? null : events}
            pagination={{ position: ['bottomCenter'] }}
            scroll={{ x: 992 }}
          />
        </Col>
      </Row>
    </>
  );
};
