import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Trans } from '@lingui/macro';

const { Text } = Typography;
const { Paragraph } = Typography;
interface Props {
  mucnatkaIndex: any;
}

const InfoText = styled.div`
  text-align: left;
  padding-bottom: 32px;
`;

export const MucnatkaReport: React.FC<Props> = ({ mucnatkaIndex }) => {
  let infoText: JSX.Element = <Paragraph></Paragraph>;
  if (mucnatkaIndex === undefined) {
    infoText = (
      <Paragraph>
        <Trans>Current status: Outside of the monitored period</Trans>
      </Paragraph>
    );
  } else if (mucnatkaIndex < 30) {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status:</Trans>
          </Text>
          <Trans>
            <Text type="success">Weak spread risk</Text>, Long incubation period
            of the pathogen (15 days)
          </Trans>
        </Paragraph>
        <Paragraph>
          <Text strong>
            <Trans>Recommendation: </Trans>{' '}
          </Text>
          <Trans>Extended treatment interval (12-14 days)</Trans>
        </Paragraph>
      </>
    );
  } else if (mucnatkaIndex < 60) {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status:</Trans>
          </Text>
          <Trans>
            <Text type="warning">Medium spread risk</Text>, Average incubation
            period of the pathogen (8-11 days)
          </Trans>
        </Paragraph>
        <Paragraph>
          <Text strong>
            <Trans>Recommendation: </Trans>
          </Text>
          <Trans>Standard treatment interval (8-11 days)</Trans>
        </Paragraph>
      </>
    );
  } else {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status:</Trans>
          </Text>
          <Trans>
            <Text type="danger">High spread risk</Text>, Short incubation period
            of the pathogen (5 days)
          </Trans>
        </Paragraph>
        <Paragraph>
          <Text strong>
            <Trans>Recommendation: </Trans>
          </Text>
          <Trans>Shortened treatment interval (5-7 days)</Trans>
        </Paragraph>
      </>
    );
  }

  return <InfoText>{infoText}</InfoText>;
};
