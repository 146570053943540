import React from 'react';
import { Typography, Button, Row, Col } from 'antd';
import styled from 'styled-components';
import _round from 'lodash/round';
import _sumBy from 'lodash/sumBy';
import _minBy from 'lodash/minBy';
import _maxBy from 'lodash/maxBy';
import moment from 'moment';
import { Trans } from '@lingui/macro';

/** Custom imports */
import { Baterry } from 'components/elements/Baterry';

const { Title } = Typography;

const StyledCol = styled(Col)`
  padding: 20px;
`;
const Wrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  min-width: 100%;
`;

const TitleWrapper = styled.div`
  min-width: 100%;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
  display: inline-flex;
  justify-content: space-between;
`;

const Context = styled.div`
  padding: 12px;
`;

const StyledDiv = styled.div`
  width: 100%;
  font-size: 12px;
  display: inline-flex;
  justify-content: space-between;
`;

export const FavoriteStation = ({ data }: any) => {
  const calculatePressureHPa = (pressure: any) => {
    return _round(pressure / 100, 2);
  };
  const getDate = (hours: any) => {
    const newDate = moment().subtract(hours, 'hours');

    return newDate;
  };

  const getRainByHours = (hours: any) => {
    const newData = data.filter(
      (item: any) => moment(item.timestamp) > getDate(hours),
    );
    const rain = newData.length > 0 ? _sumBy(newData, 'rain_counter') : '-';

    return rain;
  };

  const getMinTemp = () => {
    const newData = data.filter(
      (item: any) => moment(item.timestamp) > getDate(24),
    );
    const min = _minBy(newData, 'temperature');
    //@ts-ignore
    return min?.temperature || '-';
  };

  const getMaxTemp = () => {
    const newData = data.filter(
      (item: any) => moment(item.timestamp) > getDate(24),
    );

    const max = _maxBy(newData, 'temperature');
    //@ts-ignore
    return max?.temperature || '-';
  };

  return (
    <StyledCol span={6} xs={24} sm={24} md={24} lg={12}>
      <Wrapper>
        <TitleWrapper>
          <Title level={5}>{data[0].name}</Title>
          <Button type="link" danger>
            <Trans>Remove</Trans>
          </Button>
        </TitleWrapper>
        <Context>
          <Row gutter={[24, 24]}>
            <Col span={24} style={{ textAlign: 'end', padding: '0 12px' }}>
              <Baterry kapacity={data[0].battery} />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={6} xs={24} sm={24} md={12} lg={12}>
              <StyledDiv>
                <div>
                  <Trans>Temperature</Trans>
                </div>
                <div>{`${data[0].temperature} °C`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>Pressure</Trans>
                </div>
                <div>{`${calculatePressureHPa(data[0].pressure)} hPa`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>Humidity</Trans>
                </div>
                <div>{`${data[0].humidity} %`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>Irradiation</Trans>
                </div>
                <div>{`${data[0].irradiation} W/m2`}</div>
              </StyledDiv>
            </Col>

            <Col span={6} xs={24} sm={24} md={12} lg={12}>
              <StyledDiv>
                <div>
                  <Trans>Rain in last 4h</Trans>
                </div>
                <div>{`${getRainByHours(4)} mm`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>Rain in last 24h</Trans>
                </div>
                <div>{`${getRainByHours(24)} mm`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>min. T in last 24h</Trans>
                </div>
                <div>{`${getMinTemp()} °C`}</div>
              </StyledDiv>

              <StyledDiv>
                <div>
                  <Trans>max. T in last 4h</Trans>
                </div>
                <div>{`${getMaxTemp()} °C`}</div>
              </StyledDiv>
            </Col>
          </Row>
        </Context>
      </Wrapper>
    </StyledCol>
  );
};
