import React from 'react';

/** Custom imports */
import { Content, Footer } from '../../../styles/layout';
import { DashboardForUser } from 'components/elements/DashboardForUser';

export const UserDashboard = () => {
  return (
    <>
      <Content>
        <DashboardForUser />
      </Content>
      <Footer>©{new Date().getFullYear()} Skymaps s.r.o.</Footer>
    </>
  );
};
