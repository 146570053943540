import React from 'react';
import video1 from '../../../assets/videos/video_1.mp4';

/*Styles*/
import { Row, Col } from 'antd';
import {
  Container,
  VideoInner,
  Video,
  StandardText,
} from '../../../styles/tutorials';
import { Trans } from '@lingui/macro';

export const TutorialMap = () => {
  return (
    <>
      <Container>
        <Row gutter={[64, 0]} align="middle" style={{ paddingTop: '32px' }}>
          <Col sm={24} lg={8}>
            <StandardText>
              <p>
                <Trans>
                  Overview of meteorological station locations. Hovering over
                  the icons will display the names of individual weather
                  stations.
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video1} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
