import styled from 'styled-components/macro';
import { Form } from 'antd';
import { MapContainer, Tooltip } from 'react-leaflet';

export const StyledFormItem = styled(Form.Item)`
  text-align: end;
  padding: 0 0 0 0;
  margin: 0;
`;

export const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 350px;
  z-index: 1;
  margin-bottom: 16px;
`;

export const StyledTooltip = styled(Tooltip)`
  background-color: white;
  font-family: Poppins, sans-serif;
`;
