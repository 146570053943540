import React from 'react';
import { Trans } from '@lingui/macro';
import video7 from '../../../assets/videos/video_7.mp4';

/*Styles*/
import { Col, Row } from 'antd';
import {
  Container,
  VideoInner,
  Video,
  StandardText,
  StyledTitle,
} from '../../../styles/tutorials';

export const TutorialTable = () => {
  return (
    <>
      <Container style={{ marginTop: '32px' }}>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Summary</Trans>
            </StyledTitle>
            <StandardText>
              <ul>
                <li>
                  <Trans>
                    Summary of all disease pressures and intervention history
                    for the entire season. The list is filterable and editable.
                    It's important to have the option to add intervention
                    records{' '}
                    <i>(displaying the protective period in the list)</i>.
                  </Trans>
                </li>
              </ul>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video7} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
