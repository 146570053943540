//@ts-nocheck
import React from 'react';
import { Select, Form } from 'antd';
import { Trans, t } from '@lingui/macro';
import { useQuery } from '@apollo/client';

import { GET_CONDITION_METHODS } from 'graphql/queries';
import { Loader } from '..';

const { Option } = Select;

export const ConditionMethods = () => {
  const { data, loading, error } = useQuery(GET_CONDITION_METHODS);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  return (
    <Form.Item
      label={<Trans>Method</Trans>}
      name="method"
      rules={[
        {
          required: true,
          message: <Trans>Select notification method!</Trans>,
        },
      ]}
    >
      <Select mode="multiple" showSearch placeholder={t`Search to Select`}>
        {data.alarmMethods.map((alarmMethod) => (
          <Option key={alarmMethod.method} value={alarmMethod.id}>
            {alarmMethod.method}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
