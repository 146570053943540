import React from 'react';
import { Alert, Popover } from 'antd';
import _round from 'lodash/round';
import { Trans } from '@lingui/macro';
import moment from 'moment';

/** Custom imports */
import { getPopoverOptions } from './PopoversOptions';
/** Data types */
import { PopoverOptions } from './PopoversOptions';
import { DevicesAvailable } from 'components/elements/StationTabs';
/** Styles */
import { StyledDiv, Value, StyledReactEcharts } from './style';

interface Props {
  lastDayHelixes: any;
  lastDayWinds: any;
  lastDayAgroes: any;
  sensorDepths: Array<string>;
  devicesAvailable: DevicesAvailable;
}

export const Popovers: React.FC<Props> = ({
  lastDayHelixes,
  lastDayWinds,
  lastDayAgroes,
  sensorDepths,
  devicesAvailable,
}) => {
  const createPopovers = (data: Array<PopoverOptions>) => {
    const filteredPopovers = data.filter((item: PopoverOptions) => {
      if (item.quantity === 'plantTemperature') {
        if (item.value === null || item.value === undefined) {
          return;
        }
      }

      return item;
    });

    const popovers = filteredPopovers.map((item: PopoverOptions) => (
      <Popover
        content={<StyledReactEcharts option={item.options} />}
        title=""
        style={{ width: 200 }}
        placement="rightBottom"
        key={item.quantity}
      >
        {/*@ts-ignore*/}
        <StyledDiv props={{ color: `${item.color}` }}>
          <div>{item.name}</div>
          <Value>{`${item.displayValue}` + ` ${item.units}`}</Value>
        </StyledDiv>
      </Popover>
    ));

    return popovers;
  };

  const popoverOptions: Array<PopoverOptions> = getPopoverOptions(
    lastDayHelixes,
    lastDayWinds,
    lastDayAgroes,
    devicesAvailable,
    sensorDepths,
  );

  const numberOfValues = popoverOptions.filter((item) => {
    if (item.value) {
      return item;
    }
  });

  if (numberOfValues.length === 0) {
    return (
      <Alert
        message={<Trans>Error</Trans>}
        description={<Trans>No data available for the last 24 hours</Trans>}
        type="error"
        showIcon
      />
    );
  }

  const popovers = createPopovers(popoverOptions);

  return <>{popovers}</>;
};
