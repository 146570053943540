import React from 'react';
import { Modal, Button, Form, Input, Select } from 'antd';
import { Trans } from '@lingui/macro';
import { useQuery, useMutation } from '@apollo/client';
import _get from 'lodash/get';

/*Custom imports*/
import { GET_LOGGED_IN_USER, GET_CONDITIONS_BY_USER } from 'graphql/queries';
import { MUTATION_CREATE_CONDITION } from 'graphql/mutations';
import { ConditionMethods } from 'components/elements/ConditionMethods';
import { ConditionStations } from 'components/elements/ConditionStations';
import { StyledFormItem } from './style';

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const infections = [
  { name: <Trans>Downy mildew</Trans>, key: 'inf_peronospora' },
  { name: <Trans>Downy mildew 2</Trans>, key: 'inf_peronospora2' },
  { name: <Trans>Powdery mildew</Trans>, key: 'inf_mucnatka' },
  { name: <Trans>Botrytis</Trans>, key: 'inf_botrytida' },
];
interface Props {
  setInfectionModal: any;
  setIsModalVisible: any;
  infectionModal: boolean;
}

export const ConditionInfectionModal: React.FC<Props> = ({
  setInfectionModal,
  infectionModal,
  setIsModalVisible,
}) => {
  const [form] = Form.useForm();

  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const userId = _get(user, 'me.id');

  const [createAlarm, { error }] = useMutation(MUTATION_CREATE_CONDITION, {
    refetchQueries: [{ query: GET_CONDITIONS_BY_USER, variables: { email } }],
  });

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const onFinish = (values: any) => {
    const { name, unit, stations, method } = values;

    createAlarm({
      variables: {
        input: {
          data: {
            name: name,
            unit: unit,
            operator: null,
            value: null,
            period: null,
            method: method,
            stations: stations,
            owner: userId,
          },
        },
      },
    });
    if (error) {
      console.error(error);
    }

    form.resetFields();
    setInfectionModal(false);
    setIsModalVisible(false);
  };

  const getKeyOptions = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.key} key={item.key}>
          {item.name}
        </Option>
      );
    });
  };

  return (
    <>
      <Modal
        title={<Trans> Add a new condition for disease pressure</Trans>}
        visible={infectionModal}
        onCancel={() => setInfectionModal(false)}
        footer={null}
      >
        <Form
          form={form}
          {...layout}
          name="basic"
          preserve={false}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<Trans>Name</Trans>}
            name="name"
            rules={[
              { required: true, message: <Trans>Add alarm name!</Trans> },
            ]}
          >
            <Input />
          </Form.Item>

          <ConditionStations />

          <Form.Item
            label={<Trans>Disease pressure</Trans>}
            name="unit"
            rules={[
              {
                required: true,
                message: <Trans>Select disease pressure!</Trans>,
              },
            ]}
          >
            <Select>{getKeyOptions(infections)}</Select>
          </Form.Item>

          <ConditionMethods />

          <StyledFormItem {...tailLayout}>
            <Button type="primary" htmlType="submit">
              <Trans>Add</Trans>
            </Button>
          </StyledFormItem>
        </Form>
      </Modal>
    </>
  );
};
