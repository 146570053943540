//@ts-nocheck
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Select,
  InputNumber,
  DatePicker,
  Input,
} from 'antd';

/** Custom imports */
import { useReactiveVar, useMutation } from '@apollo/client';
import { MUTATION_CREATE_APPLICATION } from 'graphql/mutations';
import { activeStationVar } from '../../../utils/cache';
import { GET_INFECTION_TABLE } from '../../../graphql/queries';
import { Trans, t } from '@lingui/macro';
import _get from 'lodash/get';
import moment from 'moment';

const { Option } = Select;

interface Props {
  selectedYear: number;
  user: any;
  showLoading: boolean;
  setShowLoading: any;
}

const infections = [
  { name: <Trans>Downy mildew</Trans>, key: 1 },
  { name: <Trans>Powdery mildew</Trans>, key: 2 },
  { name: <Trans>Downy mildew 2</Trans>, key: 3 },
  { name: <Trans>Botrytis</Trans>, key: 4 },
];

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const DATE_FORMAT2 = `YYYY-MM-DD`;

export const InfectionsModal: React.FC<Props> = ({
  selectedYear,
  user,
  showLoading,
  setShowLoading,
}) => {
  const userId = _get(user, 'me.id');
  const activeStation: any = useReactiveVar(activeStationVar);
  const [modalVisible, setModalVisible] = useState(false);

  const [createApplication, { error }] = useMutation(
    MUTATION_CREATE_APPLICATION,
    {
      onCompleted(data: any) {
        setShowLoading(false);
      },
    },
  );

  const onFinish = (values: any) => {
    const { infections, preparations, date, value } = values;
    const validDate = date.format(DATE_FORMAT2);

    createApplication({
      variables: {
        input: {
          data: {
            date: validDate,
            infection_type: infections,
            protection_interval: value,
            chemical: preparations,
            owner: userId,
            station: activeStation.id,
          },
        },
      },

      update: (cache, { data }) => {
        const application = data?.createApplication.application;

        const existingTable = cache.readQuery({
          query: GET_INFECTION_TABLE,
          variables: {
            stationId: activeStation.id,
            userId: userId,
            year: selectedYear,
          },
        });

        const newTableData = existingTable.infectionTables[0]?.infectionTableData.map(
          (item: any) => {
            if (item.date_day === application.date) {
              if (application.infection_type.name === 'Peronospora') {
                return Object.assign({}, item, {
                  peronospora_chemical: application.chemical,
                  peronospora_application_id: application.id,
                });
              } else if (application.infection_type.name === 'Plesen') {
                return Object.assign({}, item, {
                  peronospora2_chemical: application.chemical,
                  peronospora2_application_id: application.id,
                });
              } else if (application.infection_type.name === 'Mucnatka') {
                return Object.assign({}, item, {
                  mucnatka_chemical: application.chemical,
                  mucnatka_application_id: application.id,
                });
              } else {
                return Object.assign({}, item, {
                  botrytis_chemical: application.chemical,
                  botrytis_application_id: application.id,
                });
              }
            } else {
              return item;
            }
          },
        );

        cache.writeQuery({
          query: GET_INFECTION_TABLE,
          variables: {
            stationId: activeStation.id,
            userId: userId,
            year: selectedYear,
          },
          data: {
            infectionTables: [
              {
                infectionTableData: newTableData,
              },
            ],
          },
        });
      },
    });
    if (error) {
      console.error(error);
    }

    setModalVisible(false);
    setShowLoading(true);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getOptions = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.key} key={item.key}>
          {item.name}
        </Option>
      );
    });
  };

  const disabledDate = (current: any) => {
    const myValue = current > moment().endOf('day');
    return myValue;
  };

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        <Trans>Add intervention</Trans>
      </Button>
      <Modal
        title={<Trans>New intervention</Trans>}
        style={{ top: 20 }}
        visible={modalVisible}
        onOk={() => onFinish()}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          {...layout}
          name="infection"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={<Trans>Select disease</Trans>}
            name="infections"
            rules={[{ required: true, message: t`Select disease` }]}
            fieldKey={'infections'}
          >
            <Select mode="single" showSearch placeholder={t`Disease`}>
              {getOptions(infections)}
            </Select>
          </Form.Item>

          <Form.Item
            label={<Trans>Enter the product</Trans>}
            name="preparations"
            fieldKey={'preparations'}
            rules={[{ required: true, message: t`Enter the product` }]}
          >
            <Input placeholder={t`Product`}></Input>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<Trans>Select the date</Trans>}
            name="date"
            fieldKey="date"
            rules={[
              {
                required: true,
                message: t`Select the date`,
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            {...layout}
            step={1}
            label={<Trans>Enter the protection period</Trans>}
            name="value"
            fieldKey="value"
            rules={[
              { required: true, message: t`Enter the protective period` },
            ]}
          >
            <InputNumber
              {...layout}
              style={{ width: '100%' }}
              placeholder={t`Number of days`}
            />
          </Form.Item>

          <Form.Item
            {...tailLayout}
            style={{ textAlign: 'end', padding: '20px 0 0 0' }}
            fieldKey="submit-it"
          >
            <Button type="primary" htmlType="submit">
              <Trans>Add</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
