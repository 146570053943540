import React, { useState } from 'react';

import {
  Row,
  Col,
  Typography,
  Input,
  Form,
  Button,
  Radio,
  Switch,
  Slider,
  Select,
  message,
} from 'antd';

import axios from 'axios';
import { useHistory } from 'react-router';
import { Trans, t } from '@lingui/macro';

const { Title } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const FormApp = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = (values: any) => {
    setLoading(true);
    axios
      .post(`http://localhost:5000/users`, values)
      .then((res) => {
        setLoading(false);
        message.success('User Added Successfully!');
        history.push('/list');
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  // @ts-ignore
  return (
    <div>
      <Row gutter={[40, 0]}>
        <Col span={23}>
          <Title style={{ textAlign: 'center' }} level={2}>
            <Trans>Please Fill the User Form</Trans>
          </Title>
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={18}>
          <Form {...layout} onFinish={handleSubmit}>
            <Form.Item
              name="username"
              label={<Trans>UserName</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans>Please input your name</Trans>,
                },
              ]}
            >
              <Input placeholder={t`Please Enter your username`} />
            </Form.Item>
            <Form.Item
              name="email"
              label={<Trans>Email</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans>Please input your correct email</Trans>,
                  type: 'email',
                },
              ]}
            >
              <Input placeholder={t`Please Enter your email`} />
            </Form.Item>
            <Form.Item
              name="gender"
              label={<Trans>Gender</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans>Please select your gender</Trans>,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="male">
                  <Trans>Male</Trans>
                </Radio>
                <Radio value="female">
                  <Trans>Female</Trans>
                </Radio>
                <Radio value="others">
                  <Trans>Others</Trans>
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="hobbies"
              label={<Trans>Hobbies</Trans>}
              rules={[
                {
                  required: true,
                  message: <Trans>Please select your hobbies</Trans>,
                  type: 'array',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder={t`Please select you hobbies`}
              >
                <Select.Option value="Reading">
                  <Trans>Reading</Trans>
                </Select.Option>
                <Select.Option value="Writing">
                  <Trans>Writing</Trans>
                </Select.Option>
                <Select.Option value="Coding">
                  <Trans>Coding</Trans>
                </Select.Option>
                <Select.Option value="Singing">
                  <Trans>Singing</Trans>
                </Select.Option>
                <Select.Option value="Dancing">
                  <Trans>Dancing</Trans>
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="review" label={<Trans>Review</Trans>}>
              <Slider />
            </Form.Item>
            <Form.Item
              name="notificaiton"
              label={<Trans>Notificaiton</Trans>}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" loading={loading} htmlType="submit">
                <Trans>Save</Trans>
              </Button>{' '}
              <Button
                danger
                htmlType="button"
                onClick={() => history.push('/list')}
              >
                <Trans>Back</Trans>
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
