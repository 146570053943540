import styled from 'styled-components/macro';
import ReactEcharts from 'echarts-for-react';
import { breakpoints } from '../../../styles/breakpoints';

export const ChartsWrapper = styled.div`
  padding: 0px;

  @media ${breakpoints.screen420} {
    padding: 16px;
  }
`;

export const StyledReactEcharts = styled(ReactEcharts)`
  height: 200px !important;
  width: 310px;

  @media ${breakpoints.screen420} {
    height: 225px !important;
    width: 360px;
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  border-left: 3px solid ${(props: any) => props.props.color};
  font-size: 12px;
  padding: 0 12px;
  margin: 4px 0;
  &:hover {
    background-color: #f3f4f5;
  }

  @media ${breakpoints.screen420} {
    font-size: 14px;
  }
`;

export const Value = styled.div`
  white-space: nowrap;
`;
