import React from 'react';
import video2 from '../../../assets/videos/video_2.mp4';
import video3 from '../../../assets/videos/video_3.mp4';
import video4 from '../../../assets/videos//video_4.mp4';

/*Styles*/
import { Row, Col } from 'antd';
import {
  Container,
  VideoInner,
  Video,
  StyledDivider,
  StandardText,
  StyledTitle,
} from '../../../styles/tutorials';
import { Trans } from '@lingui/macro';

export const TutorialOverview = () => {
  return (
    <>
      <Container style={{ marginTop: '32px' }}>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Current data</Trans>
            </StyledTitle>
            <StandardText>
              <ul>
                <li>
                  <Trans>
                    Contains <b>current data</b> (temperature, pressure, etc.),
                    data collection interval is 10 minutes.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Hovering over the name of the quantity displays a graph with
                    data for the last 24 hours.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    The compass rose represents the current direction and speed
                    of the wind.
                  </Trans>
                </li>
              </ul>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video2} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <StyledDivider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Graph</Trans>
            </StyledTitle>
            <StandardText>
              <ul>
                <li>
                  <Trans>
                    The <b>graph</b> of quantities over time can be edited in
                    multiple ways. Tools in the top right corner are used for
                    changing the graphical representation of the graph,
                    refreshing, displaying, and exporting{' '}
                    <i>(in *.png format)</i>.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    The selection of displayed quantity and time interval can be
                    edited in the section below the graph.
                  </Trans>
                </li>
              </ul>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video3} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <StyledDivider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Statistical data</Trans>
            </StyledTitle>
            <StandardText>
              <ul>
                <li>
                  <Trans>
                    <b>Statistical data</b> are calculated from the time range
                    set for the graph. It is possible to export data from both
                    the graph and the statistical data table{' '}
                    <i>(in *.csv, *.xlsx, *.pdf format)</i>.
                  </Trans>
                </li>
              </ul>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video4} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
