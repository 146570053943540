import styled from 'styled-components';
import { Form, Button, Space } from 'antd';
import { ReactComponent as BinOutlined } from 'assets/icons/bin1.svg';

export const StyledSpace = styled(Space)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const StyledBinOutlined = styled(BinOutlined)`
  width: 20px;
  height: 20px;
  margin: 5px 0 0 10px;
  transition: all 0.4s;

  &:hover {
    cursor: pointer;
    fill: #ff3d5e;
  }
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 24px;
`;
