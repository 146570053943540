//@ts-nocheck
import React, { useState } from 'react';
import { Spin, Table, Typography, Row, Col } from 'antd';

import { nanoid } from 'nanoid';
import moment from 'moment';

/** Custom imports */
import { InfectionsTableSettings } from 'components/elements/InfectionsTableSettings';
import { InfectionsModal } from 'components/elements/InfectionsModal';
import { InfectionsEditModal } from 'components/elements/InfectionsEditModal';
import {
  renderPeronosporaState,
  renderPeronospora2State,
  renderBotrytidaState,
  renderMucnatkaState,
} from './Settings/Settings';

import {
  StyledTable,
  StyledTitle,
  StyledGroupTitle,
  StyledEditOutlined,
  ItemWrapper,
} from './style';
import { Trans, t } from '@lingui/macro';

const { Title } = Typography;
const { Column, ColumnGroup } = Table;

const DATE_FORMAT = 'DD.MM.YYYY';

interface Props {
  selectedYear: number;
  user: any;
  data: any;
}

export const InfectionsTable: React.FC<Props> = ({
  selectedYear,
  user,
  data,
}: any) => {
  const [showColumns, setShowColumns] = useState({
    minT: true,
    maxT: true,
    averageT: true,
    rain: true,
    peronospora: true,
    mucnatka: true,
    peronospora2: true,
    botrytis: true,
  });

  const [isEditable, setIsEditable] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const showModal = (record: any, columnKey: any) => {
    setCurrentRecord(record[columnKey]);
    setIsEditable(true);
  };

  const infectionGroups = [
    {
      show: showColumns.peronospora,
      title: t`Downy mildew`,
      id: 1,
      column1: {
        title: t`Occurrence`,
        dataIndex: 'peronospora_index',
        key: `peronosporaIndex ${nanoid(10)}`,
        myMethod: renderPeronosporaState,
      },
      column2: {
        title: t`Intervention`,
        dataIndex: 'peronospora_chemical',
        key: `peronosporaZasah ${nanoid(10)}`,
      },
      application: {
        dataIndex: 'peronospora_application_id',
        key: `peronosporaIdZasahu ${nanoid(10)}`,
      },
    },
    {
      show: showColumns.peronospora2,
      title: t`Downy mildew 2`,
      id: 2,
      column1: {
        title: t`Conditions`,
        dataIndex: 'peronospora2_index',
        key: `peronospora2Index ${nanoid(10)}`,
        myMethod: renderPeronospora2State,
      },
      column2: {
        title: t`Intervention`,
        dataIndex: 'peronospora2_chemical',
        key: `peronospora2Zasah ${nanoid(10)}`,
      },
      application: {
        dataIndex: 'peronospora2_application_id',
        key: `peronospora2IdZasahu ${nanoid(10)}`,
      },
    },
    {
      show: showColumns.mucnatka,
      title: t`Powdery mildew`,
      id: 3,
      column1: {
        title: t`Risk`,
        dataIndex: 'mucnatka_index',
        key: `mucnatkaIndex ${nanoid(10)}`,
        myMethod: renderMucnatkaState,
      },
      column2: {
        title: t`Intervention`,
        dataIndex: 'mucnatka_chemical',
        key: `mucnatkaZasah ${nanoid(10)}`,
      },
      application: {
        dataIndex: 'mucnatka_application_id',
        key: `mucnatkaIdZasahu ${nanoid(10)}`,
      },
    },
    {
      show: showColumns.botrytis,
      title: t`Botrytis`,
      id: 4,
      column1: {
        title: t`Risk`,
        dataIndex: 'botrytis_index',
        key: `botrytisIndex ${nanoid(10)}`,
        myMethod: renderBotrytidaState,
      },
      column2: {
        title: t`Intervention`,
        dataIndex: 'botrytis_chemical',
        key: `botrytisZasah ${nanoid(10)}`,
      },
      application: {
        dataIndex: 'botrytis_application_id',
        key: `botrytidaIdZasahu ${nanoid(10)}`,
      },
    },
  ];

  const renderInfectionGroups = infectionGroups.map((group) =>
    group.show ? (
      <ColumnGroup
        title={() => <StyledGroupTitle>{group.title}</StyledGroupTitle>}
        key={group.id}
      >
        <ColumnGroup key={nanoid(10)}>
          <Column
            title={() => <StyledTitle>{group.column1.title}</StyledTitle>}
            dataIndex={group.column1.dataIndex}
            key={group.column1.key}
            render={(num: any) => {
              const items = group.column1.myMethod(num);
              return items;
            }}
          />
          <Column
            title={() => <StyledTitle>{group.column2.title}</StyledTitle>}
            dataIndex={group.column2.dataIndex}
            key={group.column2.key}
            render={(item, record) =>
              item === null ? (
                ''
              ) : (
                <>
                  <ItemWrapper
                    key={record}
                    onClick={() =>
                      showModal(record, group.application.dataIndex)
                    }
                  >
                    {item}
                    <StyledEditOutlined />
                  </ItemWrapper>
                </>
              )
            }
          />
        </ColumnGroup>
      </ColumnGroup>
    ) : (
      ''
    ),
  );

  return (
    <>
      <Spin spinning={showLoading}>
        {isEditable ? (
          <InfectionsEditModal
            selectedYear={selectedYear}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            itemId={currentRecord}
            setShowLoading={setShowLoading}
          />
        ) : null}
        <Row gutter={[40, 0]} key={nanoid(10)}>
          <Col span={6} xs={6} sm={6} lg={6} key={nanoid(10)}>
            <InfectionsModal
              selectedYear={selectedYear}
              user={user}
              showLoading={showLoading}
              setShowLoading={setShowLoading}
            ></InfectionsModal>
          </Col>
          <Col span={12} xs={24} sm={12} lg={12} key={nanoid(10)}>
            <Title level={4} style={{ textAlign: 'center' }}>
              <Trans>Interventions</Trans>
            </Title>
          </Col>
          <Col
            span={6}
            xs={24}
            sm={6}
            lg={6}
            style={{ textAlign: 'end', padding: '0 24px 24px' }}
            key={nanoid(10)}
          >
            <InfectionsTableSettings
              setShowColumns={setShowColumns}
              showColumns={showColumns}
            />
          </Col>
        </Row>
        <StyledTable
          key={`infectionsTable ${nanoid(10)}`}
          dataSource={data}
          pagination={{ defaultPageSize: 20 }}
          size="small"
          bordered
          scroll={{ x: 1050 }}
          rowKey="date_day"
        >
          <ColumnGroup key={nanoid(10)}>
            <ColumnGroup key={nanoid(10)}>
              <Column
                title={() => (
                  <StyledTitle>
                    <Trans>Date</Trans>
                  </StyledTitle>
                )}
                dataIndex="date_day"
                key={`date ${nanoid(10)}`}
                width="90px"
                render={(date: any) => (
                  <>{`${moment(date).format(DATE_FORMAT)} `}</>
                )}
                defaultSortOrder={'descend'}
                sorter={(a, b) =>
                  Date.parse(a.date_day) - Date.parse(b.date_day)
                }
              />

              {showColumns.minT && (
                <Column
                  title={() => (
                    <StyledTitle>
                      <Trans>min. T</Trans>
                    </StyledTitle>
                  )}
                  dataIndex="min_t"
                  key={`min ${nanoid(10)}`}
                  width="80px"
                  render={(item: any) => <>{item} °C</>}
                />
              )}

              {showColumns.maxT && (
                <Column
                  title={() => (
                    <StyledTitle>
                      <Trans>max. T</Trans>
                    </StyledTitle>
                  )}
                  dataIndex="max_t"
                  key={`max ${nanoid(10)}`}
                  width="80px"
                  render={(item: any) => <>{item} °C</>}
                />
              )}

              {showColumns.averageT && (
                <Column
                  title={() => (
                    <StyledTitle>
                      <Trans>avg. T</Trans>
                    </StyledTitle>
                  )}
                  dataIndex="avg_t"
                  key={`average ${nanoid(10)}`}
                  width="80px"
                  render={(item: any) =>
                    item === null ? '' : item.toFixed(2) + ' °C'
                  }
                />
              )}

              {showColumns.rain && (
                <Column
                  title={() => (
                    <StyledTitle>
                      <Trans>Precipitation</Trans>
                    </StyledTitle>
                  )}
                  dataIndex="rain_sum"
                  key={`rain ${nanoid(10)}`}
                  render={(item: any) =>
                    item === null ? '' : item.toFixed(1) + ' mm'
                  }
                />
              )}
            </ColumnGroup>
          </ColumnGroup>
          {renderInfectionGroups}
        </StyledTable>
      </Spin>
    </>
  );
};
