import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */

export const getPeronosporaChartOptions = (
  peronosporaData = [],
  options = {},
): any => {
  const dates = peronosporaData.map((item: any) => {
    return item.date_day;
  });

  const hazard1 = peronosporaData.map((item: any) => {
    return item.hazard1;
  });

  const hazard2 = peronosporaData.map((item: any) => {
    return item.hazard2;
  });

  const rainSum = peronosporaData.map((item: any) => {
    return item.rain_sum === null ? null : +item.rain_sum.toFixed(2);
  });

  const perononosporaChartOptions = {
    color: ['green', 'red', 'blue'],
    legend: {
      orient: 'horizontal',
      top: 28,
      selectedMode: false,
      data: [
        t`Threshold for sporadic-calamitous occurrence`,
        t`Threshold for calamitous occurrence`,
        t`Current cumulative precipitation`,
      ],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'cross',
      },
    },
    xAxis: {
      type: 'category',
      name: t`Date`,
      nameLocation: 'center',
      nameTextStyle: {
        padding: 20,
      },
      data: dates,
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      scale: true,
      name: t`Cumulative precipitation in mm`,
      nameLocation: 'center',
      nameTextStyle: {
        padding: 20,
      },
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
    },
    grid: {
      top: 100,
      bottom: 80,
      show: true,
    },
    icon: 'circle',
    series: [
      {
        name: t`Threshold for sporadic-calamitous occurrence`,
        type: 'line',
        data: hazard1,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
      },
      {
        name: t`Threshold for calamitous occurrence`,
        type: 'line',
        data: hazard2,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
      },
      {
        name: t`Current value`,
        type: 'line',
        data: rainSum,
        smooth: false,
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
      },
    ],
  };

  return perononosporaChartOptions;
};
