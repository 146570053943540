import styled from 'styled-components/macro';
import { breakpoints } from '../../../styles/breakpoints';
import { Layout, Menu, AutoComplete } from 'antd';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

export { Menu, Layout, AutoComplete };
export const { SubMenu } = Menu;
export const {
  Header: DefaultHeader,
  Footer: DefaultFooter,
  Sider,
  Content: DefaultContent,
} = Layout;

const Header = styled(DefaultHeader)`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-left: 28px;
  position: fixed;
  top: 0;
  z-index: 99;

  @media ${breakpoints.screen992} {
    width: calc(100% - 200px);
  }
`;

export const MyContent = styled(DefaultContent)`
  background-color: #ffffff;
  min-height: 280px;
`;

export const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`;

export const StyledSider = styled(Sider)`
  overflow: hidden;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: block;
  transition: all 0.4s;
  width: 0%;
  flex: 0 0 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
  width: 230px !important;

  ul {
    visibility: visible;
    opacity: 1;
    transition: all 1s;
  }

  div {
    visibility: visible;
    opacity: 1;
    transition: all 1s;
  }
  &.isHidden {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;

    ul {
      visibility: hidden;
      opacity: 0;
    }

    div {
      visibility: hidden;
      opacity: 0;
    }
  }

  @media ${breakpoints.screen992} {
    overflow: hidden;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;

    &.isHidden {
      flex: 0 0 230px !important;
      max-width: 230px !important;
      min-width: 230px !important;
      width: 230px !important;

      ul {
        visibility: visible;
        opacity: 1;
        transition: all 1s;
      }

      div {
        visibility: visible;
        opacity: 1;
        transition: all 1s;
      }
    }
  }
`;

export const StyledMenu = styled(Menu)`
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const Logo = styled.div`
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  line-height: 32px;
  cursor: pointer;
  justify-content: center;

  @media ${breakpoints.screen992} {
    height: 64px;
    padding: 16px 16px;
  }
`;

export const LogoImg = styled.img`
  display: inline-block;
  height: 32px;
  vertical-align: middle;
`;

export const Footer = styled(DefaultFooter)`
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledSearchItem = styled(Menu.Item)`
  padding-left: 12px !important;
`;

export const StyledMapItem = styled(Menu.Item)`
  &&.ant-menu-item {
    svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 12px !important;
      .st18 {
        fill: white !important;
      }
    }
  }
  &&.ant-menu-item-selected {
    background-color: #214635 !important;
    border-right: 3px solid #69bd4f !important;
    svg {
      .st18 {
        fill: #69bd4f !important;
      }
    }
  }
`;

export const StyledAlarmItem = styled(Menu.Item)`
  &&.ant-menu-item-selected {
    background-color: #4e2039 !important;
    border-right: 3px solid #ff3d5e !important;
    svg {
      fill: #ff3d5e;
    }
  }
`;

export const StyledSensorsItem = styled(SubMenu)`
  &&.ant-menu-submenu {
    svg {
      width: 16px !important;
      margin-right: 12px !important;
      .st19 {
        fill: white !important;
        stroke: white !important;
      }
    }
    ul {
      overflow-y: auto;
      max-height: calc(-228px + 100vh);
    }
  }
`;

export const StyledDashboardItem = styled(Menu.Item)`
  &&.ant-menu-item-selected {
    background-color: #123969 !important;
    border-right: 3px solid #3690ff !important;
    svg {
      fill: #3690ff;
    }
  }
`;

export const StyledUDashboardItem = styled(Menu.Item)`
  &&.ant-menu-item-selected {
    background-color: #123969 !important;
    border-right: 3px solid #3690ff !important;
    svg {
      fill: #3690ff;
    }
  }
`;

export const StyledMenuIcon = styled(MenuFoldOutlined)`
  margin-top: 7px;
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100000;
  display: block;

  @media ${breakpoints.screen992} {
    display: none;
  }
`;

export const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  display: block;
  font-size: 18px;

  @media ${breakpoints.screen992} {
    display: none;
  }
`;
