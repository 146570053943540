import styled from 'styled-components';
import { Button } from 'antd';
import { breakpoints } from '../../../styles/breakpoints';

export const InfectionButton = styled(Button)`
  background-color: #ff8c00;
  color: white;
  border: none;
  outline: none;
  margin-right: 32px;

  &:focus,
  &:visited {
    background-color: #ff8c00 !important;
  }

  &:hover {
    background-color: #ffa500 !important;
  }

  &:active {
    background-color: #d97700 !important;
  }
`;
