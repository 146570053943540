import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Trans } from '@lingui/macro';

const { Text } = Typography;
const { Paragraph } = Typography;

interface Props {
  botrytisIndex: number;
}

const InfoText = styled.div`
  text-align: left;
  padding-bottom: 32px;
`;

export const BotrytisReport: React.FC<Props> = ({ botrytisIndex }) => {
  let infoText: JSX.Element = <Paragraph></Paragraph>;
  if (botrytisIndex === undefined) {
    infoText = (
      <Paragraph>
        <Trans>Current status: Outside of the monitored period</Trans>
      </Paragraph>
    );
  }
  if (botrytisIndex <= 0) {
    infoText = (
      <Paragraph>
        <Text strong>
          <Trans>Current status:</Trans>
        </Text>
        <Text type="success">
          <Trans>No danger of Botrytis cinerea infection</Trans>
        </Text>
      </Paragraph>
    );
  } else if (botrytisIndex > 0 && botrytisIndex <= 0.5) {
    infoText = (
      <Paragraph>
        <Text strong>
          <Trans>Current status:</Trans>
        </Text>
        <Text type="success">
          <Trans>Low danger of Botrytis cinerea infection</Trans>
        </Text>
      </Paragraph>
    );
  } else if (botrytisIndex > 0.5 && botrytisIndex <= 1) {
    infoText = (
      <Paragraph>
        <Text strong>
          <Trans>Current status:</Trans>
        </Text>
        <Text type="warning">
          <Trans>Medium danger of Botrytis cinerea infection</Trans>
        </Text>
      </Paragraph>
    );
  } else if (botrytisIndex > 1) {
    infoText = (
      <Paragraph>
        <Text strong>
          <Trans>Current status:</Trans>
        </Text>
        <Text type="danger">
          <Trans>High danger of Botrytis cinerea infection</Trans>
        </Text>
      </Paragraph>
    );
  }

  return <InfoText>{infoText}</InfoText>;
};
