import React from 'react';
import { Button, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';
import { useQuery } from '@apollo/client';

import { GET_LOGGED_IN_USER } from 'graphql/queries';
/** Custom imports */
import { SettingsModal } from 'components/elements/SettingsModal';
import { ReactComponent as Avatar } from 'assets/icons/Meteo_Log-out.svg';
import { PlayCircleOutlined } from '@ant-design/icons';
import { isLoggedInVar } from 'utils/cache';

import { StyledSubMenu, StyledMenuItem, StyledLogoutIcon } from './style';

export const HorizontalMenu = () => {
  const { data: user, loading: loadingUser } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const username = _get(user, 'me.username');
  const client = useApolloClient();

  const handleLogout = () => {
    client.cache.evict({ fieldName: 'me' });
    client.cache.gc();

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('remember');

    isLoggedInVar(false);
  };

  return (
    <>
      <Menu mode="horizontal">
        <StyledMenuItem key="/tutorials">
          <Link to="/tutorials">
            <Trans>Manuals</Trans>
            <PlayCircleOutlined />
          </Link>
        </StyledMenuItem>
        <StyledSubMenu key="SubMenu" title={username} icon={<Avatar />}>
          <Menu.Item key="0">
            <Button type="link" onClick={handleLogout}>
              <Trans>
                Log out <StyledLogoutIcon />
              </Trans>
            </Button>
          </Menu.Item>
          <Menu.Item key="1">
            <SettingsModal />
          </Menu.Item>
        </StyledSubMenu>
      </Menu>
    </>
  );
};
