import { InMemoryCache, makeVar } from '@apollo/client';
import { Sensors } from './models/sensors';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        activeStation: {
          read() {
            return activeStationVar();
          },
        },
        sensors: {
          read() {
            return sensorsVar();
          },
        },
      },
    },
  },
});

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem('token'));
export const activeStationVar = makeVar({});

export const sensorsInitialValues: Sensors = [
  {
    id: 0,
    name: 'Teplota',
    quantity: 'temperature',
  },
  {
    id: 1,
    name: 'Tlak',
    quantity: 'pressure',
  },
  {
    id: 2,
    name: 'Vlhkosť',
    quantity: 'humidity',
  },
  {
    id: 3,
    name: 'Slnečný svit',
    quantity: 'irradiation',
  },
  {
    id: 4,
    name: 'Napätie batérie',
    quantity: 'battery',
  },
  {
    id: 5,
    name: 'Zrážky za posledných 10 minút',
    quantity: 'rain',
  },
  {
    id: 6,
    name: 'Kumulatívne zrážky za posledných 24 hodín',
    quantity: 'cumulativeRain',
  },
  {
    id: 7,
    name: 'Rýchlosť vetra',
    quantity: 'wind',
  },
  {
    id: 8,
    name: 'Vlhkosť listov',
    quantity: 'plant',
  },
  {
    id: 9,
    name: 'Teplota pôdy v hĺbke 10cm',
    quantity: 'soil_temp1',
  },
  {
    id: 10,
    name: 'Teplota pôdy v hĺbke 50cm',
    quantity: 'soil_temp2',
  },
  {
    id: 11,
    name: 'Teplota pôdy v hĺbke 90cm',
    quantity: 'soil_temp3',
  },
  {
    id: 12,
    name: 'Vlhkosť pôdy v hĺbke 10cm',
    quantity: 'soil_moisture1',
  },
  {
    id: 13,
    name: 'Vlhkosť pôdy v hĺbke 50cm',
    quantity: 'soil_moisture2',
  },
  {
    id: 14,
    name: 'Vlhkosť pôdy v hĺbke 90cm',
    quantity: 'soil_moisture3',
  },
];

export const sensorsVar = makeVar<Sensors>(sensorsInitialValues);
