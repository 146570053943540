import {
  ApolloClient,
  NormalizedCacheObject,
  gql,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  /** get the authentication token from local storage if it exists */
  const token = localStorage.getItem('token');
  /** return the headers to the context so httpLink can read them */
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

/**
 * Setup apollo-client to point at the our graphql server
 * this can be local or a remote endpoint.
 */
export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  typeDefs,
  resolvers: {},
  connectToDevTools: true,
});
