import React from 'react';

/** Custom Imports */
import { ReactComponent as Baterry100 } from 'assets/icons/Meteo_Baterry-100.svg';
import { ReactComponent as Baterry80 } from 'assets/icons/Meteo_Baterry-80.svg';
import { ReactComponent as Baterry60 } from 'assets/icons/Meteo_Baterry-60.svg';
import { ReactComponent as Baterry40 } from 'assets/icons/Meteo_Baterry-40.svg';
import { ReactComponent as Baterry20 } from 'assets/icons/Meteo_Baterry-20.svg';

const baterry = {
  '100': <Baterry100 style={{ width: '16px' }} />,
  '80': <Baterry80 style={{ width: '16px' }} />,
  '60': <Baterry60 style={{ width: '16px' }} />,
  '40': <Baterry40 style={{ width: '16px' }} />,
  '20': <Baterry20 style={{ width: '16px' }} />,
};

const getType = ({ kapacity }: any) => {
  // minimum: 3V, maximum 4,1V
  const type =
    kapacity > 3.88
      ? '100'
      : kapacity > 3.66
      ? '80'
      : kapacity > 3.44
      ? '60'
      : kapacity > 3.22
      ? '40'
      : '20';
  return type;
};

export const Baterry = (kapacity: any) => {
  return baterry[getType(kapacity)];
};
