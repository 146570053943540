import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Typography } from 'antd';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Trans } from '@lingui/macro';

const { Title } = Typography;

export const List = () => {
  const history = useHistory();
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    axios.get(`http://localhost:5000/users`).then((res) => {
      setAllData(res.data);
    });
  }, []);

  const columns = [
    {
      title: <Trans>Username</Trans>,
      dataIndex: 'username',
    },
    {
      title: <Trans>Email</Trans>,
      dataIndex: 'email',
    },
    {
      title: <Trans>Gender</Trans>,
      dataIndex: 'gender',
    },
    {
      title: <Trans>Review</Trans>,
      dataIndex: 'review',
    },
  ];

  const handleClick = () => {
    history.push('/form');
  };

  return (
    <div>
      <Row gutter={[40, 0]}>
        <Col span={18}>
          <Title level={2}>
            <Trans>User List</Trans>
          </Title>
        </Col>
        <Col span={6}>
          <Button onClick={handleClick} block>
            <Trans>Add User</Trans>
          </Button>
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <Table columns={columns} dataSource={allData} />
        </Col>
      </Row>
    </div>
  );
};
