import React from 'react';
import _get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';

/*Custom imports*/
import { Loader } from '..';
import { TutorialOverview } from '../TutorialOverview';
import { TutorialData } from '../TutorialData';
import { TutorialTable } from '../TutorialTable';
import { TutorialInfection } from '../TutorialInfection';
import { GET_LOGGED_IN_USER } from 'graphql/queries';

/*Styles*/
import { Tabs } from 'antd';
import { Container, TextBox, TextBoxInner } from '../../../styles/tutorials';

const { TabPane } = Tabs;

export const TutorialStation = () => {
  const { data: user, loading, error } = useQuery(GET_LOGGED_IN_USER);

  if (loading) return <Loader />;
  if (error) return <p>ERROR: {error.message}</p>;

  const userType = _get(user, 'me.user_type.name');

  return (
    <>
      <TextBox style={{ marginTop: '32px' }}>
        <TextBoxInner>
          <Container>
            <Trans>
              List of individual stations. Each station collects and contains
              data divided into the following categories:
            </Trans>
          </Container>
        </TextBoxInner>
      </TextBox>
      <Tabs type="line">
        <TabPane tab={<Trans>Overview</Trans>} key="1">
          <TutorialOverview />
        </TabPane>
        <TabPane tab={<Trans>Data</Trans>} key="2">
          <TutorialData />
        </TabPane>
        {userType === 'Winer' ? (
          <>
            <TabPane tab={<Trans>Disease pressure</Trans>} key="3">
              <TutorialInfection />
            </TabPane>
            <TabPane tab={<Trans>Intervention table</Trans>} key="4">
              <TutorialTable />
            </TabPane>
          </>
        ) : null}
      </Tabs>
    </>
  );
};
