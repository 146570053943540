import React from 'react';
import {
  errorCell,
  successCell,
  warningCell,
} from '../../TableSettings/TableSettings';
import { t } from '@lingui/macro';

export const renderPeronosporaState = (num: any) => {
  switch (num) {
    case 1:
      return successCell(t`Without occurrence`);
    case 2:
      return warningCell(t`Spor. Calamitous`);
    case 3:
      return errorCell(t`Calamitous`);
    default:
      return successCell(t`Not monitored`);
  }
};

export const renderPeronospora2State = (num: number) => {
  switch (num) {
    case 1:
      return successCell(t`Unfulfilled`);
    case 2:
      return warningCell(t`Fulfilled 2-day`);
    case 3:
      return errorCell(t`Fulfilled 1-day`);
    default:
      return successCell(t`Not monitored`);
  }
};

export const renderMucnatkaState = (num: number) => {
  switch (num) {
    case 1:
      return successCell(t`Low`);
    case 2:
      return warningCell(t`Medium`);
    case 3:
      return errorCell(t`High`);
    default:
      return successCell(t`No infection`);
  }
};

export const renderBotrytidaState = (num: number) => {
  switch (num) {
    case 2:
      return successCell(t`Low`);
    case 3:
      return warningCell(t`Medium`);
    case 4:
      return errorCell(t`High`);
    case -1:
    case 0:
    case 1:
    default:
      return successCell(t`None`);
  }
};
