import React, { Dispatch, SetStateAction, useState } from 'react';
import _get from 'lodash/get';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';

/** Custom imports */
import { MapMarker, MarkerOptions } from 'components/elements/MapMarker';
import { Loader } from '../../elements';
import {
  GET_LOGGED_IN_USER,
  GET_STATION_POSITIONS,
  GET_STATION_CURRENT_VALUES,
  GET_RAIN_AGGREGATES,
} from '../../../graphql/queries';
import { StyledMapContainer } from './style';
import { useHistory } from 'react-router';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';

export const ARCGIS_TOKEN =
  'AAPK7de1e04821fb43479c0a7ac0ea65aec1_yDoymq4oLV_gRChu4JcMUsG0ZyweRAMiY0T1CQ89y5Poh7YxMXK9NzQLqd3-MXK';

const Markers = (data: Array<MarkerOptions>) => {
  return data.map((markerOptions: MarkerOptions, idx: number) => (
    <MapMarker markerOptions={markerOptions} key={idx} />
  ));
};

interface Props {}

export const WorldMap: React.FC<Props> = () => {
  let stationIdentifiers: any;
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const history = useHistory();
  const email = _get(user, 'me.email');

  const {
    data: stationData,
    loading: stationDataLoading,
    error: stationDataError,
  } = useQuery(GET_STATION_POSITIONS, {
    variables: { email: email },
  });

  const stations = _get(stationData, 'stations');
  if (stations) {
    stationIdentifiers = stations.map((item: any) => {
      return item.id;
    });
  }

  const [markerData, setMarkerData]: [
    Array<MarkerOptions>,
    Dispatch<SetStateAction<any>>,
  ] = useState([]);

  const markerEventHandlers = (path: string) => {
    return {
      click: () => {
        history.push(path);
      },
    };
  };

  const getUnitForDimension = (dimension: string): string => {
    switch (dimension) {
      case 'last_temp':
        return '°C';
      case 'last_rain':
      case 'rain1':
      case 'rain3':
      case 'rain30':
      case 'rain365':
        return ' mm';
      case 'last_irradiation':
        return ' W/m2';
      case 'last_pressure':
        return ' hPa';
      case 'last_humidity':
        return ' %';
      default:
        return '';
    }
  };

  const getMarkerData = (
    currentData: any,
    aggData: any,
    dimension: string,
  ): any => {
    const processedCurrentData = _get(currentData, 'currentValues');
    const processedAggData = _get(aggData, 'rainAggregates');

    const dataForMarkers = stations?.map((item: any) => {
      const path = '/dashboard/' + item.id;
      let marker: MarkerOptions = {
        name: item.name,
        position: [item.latitude, item.longitude],
        units: getUnitForDimension(dimension),
        eventHandlers: markerEventHandlers(path),
        showTooltip: true,
      };
      return marker;
    });

    processedCurrentData?.map((item: any, index: number) => {
      dataForMarkers[index].quantity =
        item[dimension] == null ? null : +item[dimension].toFixed(2);

      return item;
    });

    processedAggData?.map((item: any, index: number) => {
      dataForMarkers[index].quantity =
        item[dimension] == null
          ? dataForMarkers[index].quantity
          : +item[dimension].toFixed(2);

      return item;
    });

    return dataForMarkers;
  };

  const {
    data: stationCurrentData,
    loading: stationCurrentDataLoading,
    error: stationCurrentDataError,
  } = useQuery(GET_STATION_CURRENT_VALUES, {
    skip: !stations,
    variables: { stationIds: stationIdentifiers, intervalMins: 60 },
    onCompleted: (data) => {
      setMarkerData(getMarkerData(data, null, 'last_temp'));
    },
  });

  const {
    data: stationRainAggData,
    loading: stationRainAggDataLoading,
    error: stationRainAggDataError,
  } = useQuery(GET_RAIN_AGGREGATES, {
    skip: !stations,
    variables: { stationIds: stationIdentifiers },
  });

  if (
    stationDataLoading ||
    stationCurrentDataLoading ||
    stationRainAggDataLoading
  ) {
    return <Loader />;
  }

  if (stationDataError || stationCurrentDataError || stationRainAggDataError) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const bounds = stations?.map((item: any) => {
    return [item.latitude, item.longitude];
  });

  const changeDimension = (event: any) => {
    setMarkerData(
      getMarkerData(stationCurrentData, stationRainAggData, event.target.value),
    );
  };

  return (
    <>
      <StyledMapContainer
        bounds={bounds}
        boundsOptions={{ maxZoom: 11 }}
        scrollWheelZoom={true}
      >
        <VectorBasemapLayer name="ArcGIS:Imagery" token={ARCGIS_TOKEN} />
        <select
          defaultValue={'temp'}
          onChange={changeDimension}
          style={{ position: 'absolute', top: 20, right: 20, zIndex: 999 }}
        >
          <option value={'last_temp'}>
            <Trans>Current temperature</Trans>
          </option>
          <option value={'last_pressure'}>
            <Trans>Current pressure</Trans>
          </option>
          <option value={'last_humidity'}>
            <Trans>Current humidity</Trans>
          </option>
          <option value={'last_irradiation'}>
            <Trans>Current irradiation</Trans>
          </option>
          <option value={'last_rain'}>
            <Trans>Precipitation in the last 10 minutes</Trans>
          </option>
          <option value={'rain1'}>
            <Trans>Precipitation in the last 24 hours</Trans>
          </option>
          <option value={'rain3'}>
            <Trans>Precipitation in the last 3 days</Trans>
          </option>
          <option value={'rain30'}>
            <Trans>Precipitation in the last 30 days</Trans>
          </option>
          <option value={'rain365'}>
            <Trans>Precipitation in the last 365 days</Trans>
          </option>
        </select>
        {Markers(markerData)}
      </StyledMapContainer>
    </>
  );
};
