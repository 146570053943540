import React from 'react';
import { Form, Select, Typography } from 'antd';
import { Trans } from '@lingui/macro';

/*Custom imports*/
import { StyledDivider, FormSection, FormItemWrapper } from 'styles/settings';

const { Title } = Typography;
const { Option } = Select;

export const SettingsRainSensors = () => {
  return (
    <>
      <FormSection>
        <Form.Item>
          <Title level={5}>
            <Trans>Rain gauge settings</Trans>
          </Title>
          <StyledDivider />
        </Form.Item>
        <FormItemWrapper>
          <Form.Item
            label={<Trans>Rain gauge resolution in mm</Trans>}
            name="rainGauge"
          >
            <Select style={{ width: 80, margin: '0 8px' }}>
              <Option value={0.2} key="0.2">
                0.2
              </Option>
              <Option value={0.5} key="0.5">
                0.5
              </Option>
            </Select>
          </Form.Item>
        </FormItemWrapper>
      </FormSection>
    </>
  );
};
