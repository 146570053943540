import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Select, Typography } from 'antd';

/**Custom imports */
import { Loader } from '..';
import { activeStationVar } from '../../../utils/cache';
import { GET_BOTRYTIS_YEARS } from '../../../graphql/queries';
import { BotrytisSection } from '../BotrytisSection';
import { SelectLabel, SelectWrapper, StyledSelect } from 'styles/infections';

const { Paragraph } = Typography;
const { Text } = Typography;
const { Option } = Select;

interface Props {}

let currYear = new Date().getFullYear();

export const Botrytis: React.FC<Props> = () => {
  const [selectedYear, setSelectedYear] = useState(currYear);
  const activeStation: any = useReactiveVar(activeStationVar);
  const { data, loading, error } = useQuery(GET_BOTRYTIS_YEARS, {
    variables: { stationId: activeStation.id },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const changeYear = (value: any) => {
    const yearNum = parseInt(value);
    setSelectedYear(yearNum);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <SelectWrapper>
        <SelectLabel>
          <Trans>Select the year</Trans>
        </SelectLabel>
        <StyledSelect defaultValue={currentYear} onChange={changeYear}>
          {data.botrytis.map((botrytis: any) => (
            <Option key={botrytis.year} value={botrytis.year}>
              {botrytis.year}
            </Option>
          ))}
        </StyledSelect>
      </SelectWrapper>
      <Paragraph>
        <Text strong>
          <Trans>Model by Broom</Trans>
        </Text>
        <Trans>The index is functional from berry softening.</Trans>
      </Paragraph>
      <BotrytisSection selectedYear={selectedYear}></BotrytisSection>
    </>
  );
};
