//@ts-nocheck
import React, { useState } from 'react';
import { Checkbox, Dropdown, Menu, Button } from 'antd';

import { nanoid } from 'nanoid';
import { SettingOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';

interface Props {}

export const InfectionsTableSettings: React.FC<Props> = ({
  setShowColumns,
  showColumns,
}: any) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (item) => {
    setShowColumns({
      ...showColumns,
      [item.target.name]: item.target.checked,
    });
  };

  const menuItemsSettings = [
    { key: 1, checkboxName: t`Min.T`, checkboxKey: 'minT' },
    { key: 2, checkboxName: t`Max.T`, checkboxKey: 'maxT' },
    { key: 3, checkboxName: t`Average.T`, checkboxKey: 'averageT' },
    { key: 4, checkboxName: t`Precipitation`, checkboxKey: 'rain' },
    { key: 5, checkboxName: t`Downy mildew`, checkboxKey: 'peronospora' },
    { key: 7, checkboxName: t`Downy mildew 2`, checkboxKey: 'peronospora2' },
    { key: 6, checkboxName: t`Powdery mildew`, checkboxKey: 'mucnatka' },
    { key: 8, checkboxName: t`Botrytis`, checkboxKey: 'botrytis' },
  ];

  const menuItems = menuItemsSettings.map((item) => (
    <Menu.Item key={item.key}>
      <Checkbox
        key={item.checkboxKey}
        name={item.checkboxKey}
        checked={showColumns[item.checkboxKey]}
        onChange={handleClick}
      >
        {item.checkboxName}
      </Checkbox>
    </Menu.Item>
  ));

  const menu = (
    <Menu style={{ padding: '20px 0 10px 10px' }} key={nanoid(10)}>
      {menuItems}
      <Menu.Item style={{ textAlign: 'end' }}>
        <Button
          key="infectionTable"
          onClick={() => setVisible(false)}
          size="small"
        >
          <Trans>OK</Trans>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={visible}
      key={nanoid(10)}
    >
      <Button
        className="ant-dropdown-link"
        onClick={() => setVisible(!visible)}
      >
        <Trans>Filter</Trans> <SettingOutlined />
      </Button>
    </Dropdown>
  );
};
