import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import moment from 'moment';
/*Styles*/
import { StyledButton, StyledRangePicker, StyledRadioGroup } from './style';

const timezoneOffset = moment().utcOffset();

const intervals = {
  last31days: moment()
    .subtract(31, 'days')
    .utc()
    .add(timezoneOffset, 'minutes'),
  last7days: moment().subtract(7, 'days').utc().add(timezoneOffset, 'minutes'),
  last3days: moment().subtract(3, 'days').utc().add(timezoneOffset, 'minutes'),
  todayStart: moment().startOf('day').utc().add(timezoneOffset, 'minutes'),
  todayEnd: moment().endOf('day').utc().add(timezoneOffset, 'minutes'),
  moment: moment().utc().add(timezoneOffset, 'minutes'),
  yesterday: moment()
    .subtract(1, 'days')
    .startOf('day')
    .utc()
    .add(timezoneOffset, 'minutes'),
};

interface Props {
  loadData(dateFrom: moment.Moment, dateTo: moment.Moment): void;
  defaultSelected: string;
}

export const FilterButtons: React.FC<Props> = ({
  loadData,
  defaultSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelected);
  const [selectedRange, setSelectedRange] = useState(null as any);

  const changeInterval = (
    dateFrom: moment.Moment,
    dateTo: moment.Moment,
    value: string,
  ) => {
    setSelectedValue(value);
    setSelectedRange([]);
    loadData(dateFrom, dateTo);
  };

  const filterItems = [
    {
      name: <Trans>31 days</Trans>,
      value: 'a',
      func: () => changeInterval(intervals.last31days, intervals.moment, 'a'),
    },
    {
      name: <Trans>7 days</Trans>,
      value: 'b',
      func: () => changeInterval(intervals.last7days, intervals.moment, 'b'),
    },
    {
      name: <Trans>3 days</Trans>,
      value: 'c',
      func: () => changeInterval(intervals.last3days, intervals.moment, 'c'),
    },
    {
      name: <Trans>Yesterday</Trans>,
      value: 'd',
      func: () =>
        changeInterval(intervals.yesterday, intervals.todayStart, 'd'),
    },
    {
      name: <Trans>Today</Trans>,
      value: 'e',
      func: () => changeInterval(intervals.todayStart, intervals.moment, 'e'),
    },
  ];

  const filterByDate = (e: any) => {
    const date1 = moment(e[0])
      .startOf('day')
      .utc()
      .add(timezoneOffset, 'minutes');
    const date2 = moment(e[1])
      .endOf('day')
      .utc()
      .add(timezoneOffset, 'minutes');
    changeInterval(date1, date2, '');
    setSelectedRange([e[0], e[1]]);
  };

  const disabledDate = (current: any) => {
    const myValue =
      current > moment().endOf('day') ||
      current < moment().subtract(1, 'year').startOf('year');
    return myValue;
  };

  const filterButtons = filterItems.map((button) => (
    <StyledButton
      value={button.value}
      key={button.value}
      onClick={button.func}
      className="noBorder"
    >
      {button.name}
    </StyledButton>
  ));

  return (
    <div>
      <StyledRadioGroup value={selectedValue} buttonStyle="solid">
        {filterButtons}
      </StyledRadioGroup>
      <StyledRangePicker
        value={selectedRange}
        allowClear={false}
        className="styled-input"
        bordered={false}
        onChange={(e) => filterByDate(e)}
        disabledDate={disabledDate}
      />
    </div>
  );
};

export default FilterButtons;
