/* eslint-disable @typescript-eslint/no-unused-vars */
import fill from 'lodash/fill';
import round from 'lodash/round';
import { EChartOption } from 'echarts';
import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
export const getWindChartOptions = (chartData = [], options: any = {}): any => {
  let label = fill(Array(16), '');
  label[0] = t`N`;
  label[2] = t`NE`;
  label[4] = t`E`;
  label[6] = t`SE`;
  label[8] = t`S`;
  label[10] = t`SW`;
  label[12] = t`W`;
  label[14] = t`NW`;

  const getData = () => {
    let isMax = options.max === true;

    let newData = fill(Array(16), '');
    //@ts-ignore
    let dir = isMax ? chartData[0]?.dir_max10 : chartData[0]?.dir_ave10;
    let index = round((dir / 3.6) * 0.16);

    // @ts-ignore
    let strengthMax = chartData[0]?.wind_max10;
    //@ts-ignore
    let strengthAvg = chartData[0]?.wind_ave10;

    newData[index] = isMax ? strengthMax : strengthAvg;

    return newData;
  };

  const windChartOptions: EChartOption = {
    color: ['#74fc47'],
    angleAxis: {
      type: 'category',
      data: label,
      boundaryGap: false,
    },
    radiusAxis: {
      axisLabel: {
        verticalAlign: 'bottom',
        margin: 4,
      },
    },
    polar: {},
    series: [
      {
        type: 'bar',
        data: getData(),
        coordinateSystem: 'polar',
        name: 'A',
        stack: 'a',
      },
    ],
    legend: {
      show: false,
      data: ['m/s'],
    },
    tooltip: {
      show: true,
      formatter: 'Rýchlosť vetra: {c} m/s',
    },
  };

  return windChartOptions;
};
