import { Menu } from 'antd';
import { breakpoints } from '../../../styles/breakpoints';
import styled from 'styled-components';

import { LogoutOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;

export const StyledSubMenu = styled(SubMenu)`
  border-bottom: none !important;

  div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  span {
    font-size: 12px;
    color: black !important;
    margin-right: 10px;

    @media ${breakpoints.screen350} {
      font-size: 14px;
    }
  }

  svg {
    width: 20px;
    height: 20px;

    @media ${breakpoints.screen350} {
      width: 25px;
      height: 25px;
    }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  a {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;

    @media ${breakpoints.screen350} {
      font-size: 14px;
    }
  }

  span {
    margin: 0 0 0 10px !important;
    font-size: 20px !important;

    @media ${breakpoints.screen350} {
      font-size: 25px !important;
    }
  }
`;

export const StyledLogoutIcon = styled(LogoutOutlined)`
  svg {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }
`;
