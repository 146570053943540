import { RouteComponentProps } from 'react-router';
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { activeStationVar } from 'utils/cache';

import { GET_STATION_BY_ID } from 'graphql/queries';
import { StationTabs } from 'components/elements/StationTabs';
import { Content, Footer } from '../../../styles/layout';
import { Trans } from '@lingui/macro';

interface Props extends RouteComponentProps<{ id: string }> {}

export const Dashboard: React.FC<Props> = ({ match }) => {
  const { data, loading, error } = useQuery(GET_STATION_BY_ID, {
    variables: { id: match.params.id },
    onCompleted({ station }) {
      activeStationVar(station);
    },
  });

  if (loading) return null;
  if (error)
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );

  return (
    <>
      <Content>
        <StationTabs></StationTabs>
      </Content>
      <Footer>©{new Date().getFullYear()} Skymaps s.r.o.</Footer>
    </>
  );
};
