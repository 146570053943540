/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

/** css can be imported from antd only for a single component */
// import 'antd/es/button/style/index.css';

/** custom import's */
import { PageTemplate, GenericTemplate } from 'components/templates';
import { AppRoutes } from 'routes/AppRoutes';
import { NotAuthAppRoutes } from 'routes';
import { client } from 'utils/apollo-client';
import { IS_LOGGED_IN } from 'graphql/queries';
import { defaultLocale, dynamicActivate } from 'utils/i18n';
import { detect, fromNavigator, fromStorage } from '@lingui/detect-locale';

const DEFAULT_FALLBACK = () => defaultLocale;

export const preferredDefaultLanguage = detect(
  fromStorage('lang'),
  fromNavigator(),
  DEFAULT_FALLBACK,
);

const IsLoggedIn = () => {
  const { data } = useQuery(IS_LOGGED_IN);

  if (data.isLoggedIn) {
    return (
      <Router>
        <PageTemplate>
          <AppRoutes />
        </PageTemplate>
      </Router>
    );
  }

  return (
    <Router>
      <GenericTemplate>
        <NotAuthAppRoutes />
      </GenericTemplate>
    </Router>
  );
};

export function App() {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(preferredDefaultLanguage?.split('-')[0] || defaultLocale);
  }, []);
  return (
    <ApolloProvider client={client}>
      <I18nProvider i18n={i18n}>
        <IsLoggedIn />
      </I18nProvider>
    </ApolloProvider>
  );
}
