import React from 'react';
import _get from 'lodash/get';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Trans } from '@lingui/macro';

/*Custom imports*/
import { StationDashboard } from 'components/elements/StationDashboard';
import { MeasurementTable } from 'components/blocks/MeasurementTable';
import { Infections } from 'components/elements/Infections';
import { InfectionsMain } from 'components/elements/InfectionsMain';
import { Loader } from '..';
import { SettingsCard } from 'components/elements/SettingsCard';
import { activeStationVar } from 'utils/cache';
import { GET_LOGGED_IN_USER } from 'graphql/queries';

/*Styles*/
import { StyledTabs, StyledTabPane } from './style';

export interface DevicesAvailable {
  helix: boolean;
  wind: boolean;
  agro: boolean;
}
export interface DevicesID {
  helix: any;
  wind: any;
  agro: any;
}

export const StationTabs = () => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const devices = activeStation.devices;
  const { data: user, loading, error } = useQuery(GET_LOGGED_IN_USER);

  if (loading) return <Loader />;
  if (error) return <p>ERROR: {error.message}</p>;

  const userType = _get(user, 'me.user_type.name');

  const getDevice = (devices: any, type: string) => {
    const device = devices.find((device: any) => {
      return device?.device_type?.type === type;
    });

    if (device !== undefined) {
      return { id: device.id, available: true };
    } else {
      return { id: '0', available: false };
    }
  };

  const helixDevice = getDevice(devices, 'H');
  const windDevice = getDevice(devices, 'W');
  const agroDevice = getDevice(devices, 'A');

  let devicesAvailable: DevicesAvailable = {
    helix: helixDevice.available,
    wind: windDevice.available,
    agro: agroDevice.available,
  };

  let devicesId: DevicesID = {
    helix: helixDevice.id,
    wind: windDevice.id,
    agro: agroDevice.id,
  };

  return (
    <StyledTabs type="line">
      <StyledTabPane tab={<Trans>Overview</Trans>} key="1">
        <StationDashboard
          devicesAvailable={devicesAvailable}
          devicesId={devicesId}
        />
      </StyledTabPane>
      <StyledTabPane tab={<Trans>Data</Trans>} key="2">
        <MeasurementTable devicesAvailable={devicesAvailable} />
      </StyledTabPane>
      <StyledTabPane tab={<Trans>Settings</Trans>} key="3">
        <SettingsCard />
      </StyledTabPane>
      {userType === 'Winer' ? (
        <>
          <StyledTabPane tab={<Trans>Disease pressure</Trans>} key="5">
            <Infections />
          </StyledTabPane>
          <StyledTabPane tab={<Trans>Intervention table</Trans>} key="6">
            <InfectionsMain />
          </StyledTabPane>
        </>
      ) : null}
    </StyledTabs>
  );
};
