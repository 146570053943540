export const breakpoints = {
  screen320: `(min-width: 320px)`,
  screen350: `(min-width: 350px)`,
  screen375: `(min-width: 375px)`,
  screen420: `(min-width: 420px)`,
  screen568: `(min-width: 568px)`,
  screen768: `(min-width: 768px)`,
  screen992: `(min-width: 992px)`,
  screen1200: `(min-width: 1200px)`,
  screen1400: `(min-width: 1400px)`,
  screen1600: `(min-width: 1600px)`,
};
