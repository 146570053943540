import React, { Fragment } from 'react';
import backgroundWine from '../../../assets/images/wallpaper_wine.jpg';
import backgroundFarm from '../../../assets/images/wallpaper_farm.jpg';
import { Wallpaper, Wrapper } from './style';

export interface GenericTemplate {
  children?: any;
}

export const GenericTemplate = ({ children, ...props }: GenericTemplate) => {
  if (
    window.location.hostname === 'viti.skymaps.sk' ||
    window.location.hostname === 'www.viti.skymaps.sk'
  ) {
    return (
      <Wallpaper style={{ backgroundImage: `url(${backgroundWine})` }}>
        <Wrapper {...props}>
          <Fragment>{children}</Fragment>
        </Wrapper>
      </Wallpaper>
    );
  }

  return (
    <Wallpaper style={{ backgroundImage: `url(${backgroundFarm})` }}>
      <Wrapper {...props}>
        <Fragment>{children}</Fragment>
      </Wrapper>
    </Wallpaper>
  );
};
