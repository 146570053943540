import moment from 'moment';
import _round from 'lodash/round';
import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */

export const getAllSensorsChartOptions = (
  data: any,
  windsData: any,
  agroData: any,
  activeStation: any,
  sensorDepths: Array<string>,
  isHwAvailable: {
    helix: boolean;
    wind: boolean;
    agro: boolean;
  },
): any => {
  const dates = data.map((item: any) => {
    return item.timestamp;
  });

  const createQuantityData = (anyData: any, quantity: string) => {
    const finalData = anyData.map((item: any) => {
      if (item[quantity] === null || item[quantity] === undefined) {
        return null;
      }
      return [item.timestamp, _round(item[quantity], 2)];
    });

    return finalData;
  };

  const createCumulativeRainData = (anyData: any, quantity: string) => {
    let cumulativeValue = 0;
    const reversedData = anyData.slice().reverse();
    const finalData = reversedData.map((item: any) => {
      if (item[quantity] === null || item[quantity] === undefined) {
        return null;
      } else {
        cumulativeValue = cumulativeValue + item[quantity];
        return [item.timestamp, _round(cumulativeValue, 2)];
      }
    });

    return finalData;
  };

  const temperature = createQuantityData(data, 'temperature');
  const pressure = data.map((item: any) => {
    if (item.pressure === null || item.pressure === undefined) {
      return null;
    }
    return [item.timestamp, _round(item.pressure / 100, 2)];
  });
  const humidity = createQuantityData(data, 'humidity');
  const irradiation = createQuantityData(data, 'irradiation');
  const battery = createQuantityData(data, 'battery');
  const rain = createQuantityData(data, 'rain');
  const cumulativeRain = createCumulativeRainData(data, 'rain');
  const wind = createQuantityData(windsData, 'wind_ave10');
  const windMax = createQuantityData(windsData, 'wind_max10');
  const leaf = createQuantityData(agroData, 'plant_canopy_sensor');
  const leafTemperature = createQuantityData(
    agroData,
    'plant_canopy_temperature',
  );
  const soil_temp1 = createQuantityData(agroData, 'soil_temp1');
  const soil_temp2 = createQuantityData(agroData, 'soil_temp2');
  const soil_temp3 = createQuantityData(agroData, 'soil_temp3');
  const soil_moisture1 = createQuantityData(agroData, 'soil_moisture1');
  const soil_moisture2 = createQuantityData(agroData, 'soil_moisture2');
  const soil_moisture3 = createQuantityData(agroData, 'soil_moisture3');

  let sensorPlantTemp: boolean = false;
  if (leafTemperature[0] !== null && leafTemperature[0] !== undefined) {
    sensorPlantTemp = true;
  }

  const allSensorsChartOptions = {
    title: {
      text: `${activeStation.name}`,
      subtext: `${activeStation.latitude}, ${activeStation.longitude}`,
      textStyle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'normal',
        fontSize: 18,
      },
    },
    color: [
      '#f86c6b',
      '#6f42c1',
      '#20a8d8',
      '#ffc107',
      '#20c997',
      '#0f75f1',
      '#e83e8c',
      '#74fc47',
      '#20a8d8',
      '#ff7700',
      '#f86c6b',
      '#6f42c1',
      '#20a8d8',
      '#e83e8c',
      '#ffc107',
      '#20c997',
    ],
    legend: {
      type: 'scroll',
      bottom: 0,
      icon: 'circle',
      inactiveColor: '#777',
      selectedMode: 'single',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'line',
      },
      renderMode: 'html',
      formatter: (params: any) => {
        return `${moment(params[0].data[0]).format('DD.MM. HH:mm')}
        <br />${params[0].seriesName}: ${params[0].data[1]}`;
      },
    },
    toolbox: {
      show: true,
      feature: {
        mark: {
          show: true,
        },
        magicType: {
          show: true,
          type: ['line', 'bar'],
          title: {
            line: t`Change to line chart`,
            bar: t`Change to bar chart`,
          },
        },
        restore: {
          show: true,
          title: t`Refresh`,
        },
        saveAsImage: {
          show: true,
          title: t`Save as image`,
          pixelRatio: 2,
        },
      },
    },
    xAxis: {
      data: dates.reverse(),
      type: 'time',
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
      axisLabel: {
        formatter: (item: any) => moment(item).format('DD.MM. HH:mm'),
      },
    },
    yAxis: {
      scale: true,
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
    },
    grid: {
      top: 80,
      bottom: 140,
      show: true,
    },
    dataZoom: [
      {
        labelFormatter: (item: any) => moment(item).format('DD.MM. HH:mm'),
        textStyle: {
          color: '#151B1E',
        },
        handleIcon:
          'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: '80%',
        dataBackground: {
          areaStyle: {
            color: '#151B1E',
          },
          lineStyle: {
            opacity: 0.8,
            color: '#151B1E',
          },
        },
        top: '80%',
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2,
        },
      },
      {
        type: 'inside',
      },
    ],
    animation: false,
    series: [
      ...(isHwAvailable.helix
        ? [
            {
              name: t`Temperature °C`,
              type: 'line',
              data: temperature.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Pressure hPa`,
              type: 'line',
              data: pressure.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Humidity %`,
              type: 'line',
              data: humidity.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Solar radiation W/m2`,
              type: 'bar',
              data: irradiation.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Battery voltage V`,
              type: 'line',
              data: battery.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Precipitation mm`,
              type: 'bar',
              data: rain.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            {
              name: t`Accumulated precipitation mm`,
              type: 'line',
              data: cumulativeRain,
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
          ]
        : []),
      ...(isHwAvailable.wind
        ? [
            {
              name: t`Wind speed m/s`,
              type: 'line',
              data: wind.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
          ]
        : []),
      ...(isHwAvailable.wind
        ? [
            {
              name: t`Maximum wind gust in 10 min m/s`,
              type: 'line',
              data: windMax.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
          ]
        : []),
      ...(isHwAvailable.agro
        ? [
            {
              name: t`Leaf relative humidity %`,
              type: 'line',
              data: leaf.reverse(),
              smooth: false,
              showSymbol: false,
              lineStyle: {
                width: 1.5,
              },
            },
            ...(sensorPlantTemp
              ? [
                  {
                    name: t`Leaf temperature °C`,
                    type: 'line',
                    data: leafTemperature.reverse(),
                    smooth: false,
                    showSmybol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[0]
              ? [
                  {
                    name: t`Soil temperature at depth ${sensorDepths[0]} °C`,
                    type: 'line',
                    data: soil_temp1.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[1]
              ? [
                  {
                    name: t`Soil temperature at depth ${sensorDepths[1]} °C`,
                    type: 'line',
                    data: soil_temp2.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[2]
              ? [
                  {
                    name: t`Soil temperature at depth ${sensorDepths[2]} °C`,
                    type: 'line',
                    data: soil_temp3.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[0]
              ? [
                  {
                    name: t`Soil moisture at depth ${sensorDepths[0]} kPa`,
                    type: 'line',
                    data: soil_moisture1.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[1]
              ? [
                  {
                    name: t`Soil moisture at depth ${sensorDepths[1]} kPa`,
                    type: 'line',
                    data: soil_moisture2.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
            ...(sensorDepths[2]
              ? [
                  {
                    name: t`Soil moisture at depth ${sensorDepths[2]} kPa`,
                    type: 'line',
                    data: soil_moisture3.reverse(),
                    smooth: false,
                    showSymbol: false,
                    lineStyle: {
                      width: 1.5,
                    },
                  },
                ]
              : []),
          ]
        : []),
    ],
  };

  return allSensorsChartOptions;
};
