import styled from 'styled-components/macro';
import { breakpoints } from './breakpoints';
import { Layout, Menu, AutoComplete } from 'antd';

export { Menu, Layout, AutoComplete };
export const { SubMenu } = Menu;
export const { Content: DefaultContent, Footer: DefaultFooter } = Layout;

export const Content = styled(DefaultContent)`
  background-color: #ffffff;
  margin: 64px 0 0 0;
  padding: 16px;
  min-height: 280px;
  @media ${breakpoints.screen420} {
    padding: 24px;
  }

  @media ${breakpoints.screen992} {
    margin: 88px 24px 0 24px;
    padding: 24px;
  }
`;

export const MapContent = styled(DefaultContent)`
  background-color: #ffffff;
  margin: 64px 0 0 0;
  min-height: 280px;
`;

export const Footer = styled(DefaultFooter)`
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;
