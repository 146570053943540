import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spin, Alert } from 'antd';
import { QrcodeOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';

/** Custom imports */
import { CHANGE_PASSWORD } from 'graphql/mutations';
import {
  Wrapper,
  StyledLabel,
  StyledInput,
  StyledPassword,
  StyledButton,
  StyledAlert,
} from './style';

export const RecoverCodeForm = () => {
  let history = useHistory();
  const [codeError, setCodeError] = useState(false);
  const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD, {
    onCompleted({ resetPassword }) {
      if (resetPassword) {
        history.push('/users', { from: 'recover-page' });
      }
    },
    onError(error) {
      handleError(error);
    },
  });

  const handleError = (error: any) => {
    console.error('error :>>', error.message);
    setCodeError(true);
  };

  const onFinish = (values: any) => {
    const { code, password, confirm } = values;
    changePassword({
      variables: {
        code: code,
        password: password,
        passwordConfirmation: confirm,
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Form name="token" onFinish={onFinish} layout="vertical">
          {codeError ? (
            <StyledAlert
              message={<Trans>Incorrect code! Please check your email!</Trans>}
              type="error"
              showIcon
            />
          ) : null}
          <Form.Item
            name="code"
            label={
              <StyledLabel>
                <Trans>Unique code</Trans>
              </StyledLabel>
            }
            rules={[
              {
                required: true,
                message: <Trans>Please fill in your unique code!</Trans>,
              },
            ]}
          >
            <StyledInput
              prefix={<QrcodeOutlined />}
              placeholder={t`Unique code`}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <StyledLabel>
                <Trans>New password</Trans>
              </StyledLabel>
            }
            rules={[
              {
                required: true,
                message: <Trans>Please fill in your new password!</Trans>,
              },
            ]}
            hasFeedback
          >
            <StyledPassword
              prefix={<LockOutlined />}
              placeholder={t`New password`}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={
              <StyledLabel>
                <Trans>Confirm new password</Trans>
              </StyledLabel>
            }
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Confirm new password',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(t`Passwords do not match!`));
                },
              }),
            ]}
          >
            <StyledPassword
              prefix={<LockOutlined />}
              placeholder={t`Confirm new password`}
            />
          </Form.Item>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit">
              <Trans>Change password</Trans>
            </StyledButton>
          </Form.Item>
        </Form>
      </Wrapper>
    </Spin>
  );
};
