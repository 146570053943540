import React, { useEffect, useState } from 'react';
import { Form, message, Row } from 'antd';
import { Trans } from '@lingui/macro';
import { useReactiveVar, useMutation } from '@apollo/client';
import { activeStationVar } from '../../../utils/cache';
import _get from 'lodash/get';

/** Custom imports */
import { MUTATION_EDIT_STATION } from 'graphql/mutations';
import { SettingsStationInfo } from 'components/elements/SettingsStationInfo';
import { SettingsDepthSensors } from 'components/elements/SettingsDepthSensors';
import { SettingsRainSensors } from 'components/elements/SettingsRainSensors';
import { SettingsMap } from 'components/elements/SettingsMap';
import { SaveButton, StornoButton } from './style';

interface Props {}

export const SettingsForm: React.FC<Props> = ({}) => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0 });
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [isAgroAvailable, setIsAgroAvailable] = useState(false);
  const [form] = Form.useForm();
  const name = activeStation.name;
  const id = activeStation.id;
  const settings = activeStation.Settings;
  const devices = activeStation.devices;

  useEffect(() => {
    setMapPosition({
      lat: activeStation.latitude,
      lng: activeStation.longitude,
    });

    const getAgroID = devices.find(
      (device: any) => device.device_type.type === 'A',
    );
    if (getAgroID !== undefined) {
      setIsAgroAvailable(true);
    }
  }, [activeStation]);

  const [editStationInfo] = useMutation(MUTATION_EDIT_STATION, {
    onCompleted(data: any) {
      const newData = _get(data, 'updateStation.station');
      activeStationVar(newData);

      setTimeout(() => {
        setLoadingStatus(false);
        success();
      }, 3000);
    },
    onError() {
      setTimeout(() => {
        setLoadingStatus(false);
        error();
      }, 3000);
    },
  });

  const success = () => {
    message.success(
      <Trans>New settings have been successfully saved</Trans>,
      8,
    );
  };

  const error = () => {
    message.error(<Trans>Something went wrong!</Trans>);
  };

  const onFinish = (values: any) => {
    const { lat, lng, name, rainGauge, depthSensors } = values;
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);

    const Settings = {
      rain_sensor_volume: rainGauge,
      ground_sensor_depths: depthSensors,
    };

    editStationInfo({
      variables: {
        input: {
          data: {
            name: name,
            latitude: latitude,
            longitude: longitude,
            Settings: Settings,
          },
          where: {
            id: id,
          },
        },
      },
    });
  };

  const discardChanges = () => {
    form.resetFields();
    const formValues = form.getFieldsValue();
    setMapPosition({ lat: formValues.lat, lng: formValues.lng });
  };

  const handleValuesChange = (changedFields: any) => {
    if (!(changedFields.lat || changedFields.lng)) {
      return;
    }

    const newPosition = {
      lat: changedFields.lat || mapPosition.lat,
      lng: changedFields.lng || mapPosition.lng,
    };

    if (isNaN(newPosition.lat) || isNaN(newPosition.lng)) {
      return;
    }

    setMapPosition(newPosition);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        preserve={true}
        onFinish={onFinish}
        initialValues={{
          name: name,
          lat: activeStation.latitude,
          lng: activeStation.longitude,
          depthSensors: settings?.ground_sensor_depths,
          rainGauge: settings?.rain_sensor_volume,
        }}
        onValuesChange={handleValuesChange}
      >
        <SettingsStationInfo activeStation={activeStation} />
        <SettingsMap
          activeStation={activeStation}
          mapPosition={mapPosition}
          setMapPosition={setMapPosition}
          form={form}
        />
        {isAgroAvailable ? <SettingsDepthSensors /> : null}
        <SettingsRainSensors />

        <Form.Item style={{ marginTop: '64px' }}>
          <Row justify="center">
            <Form.Item shouldUpdate>
              {() => (
                <SaveButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loadingStatus}
                  disabled={!form.isFieldsTouched()}
                  onClick={() => setLoadingStatus(true)}
                >
                  <Trans>Save settings</Trans>
                </SaveButton>
              )}
            </Form.Item>

            <Form.Item>
              <StornoButton
                type="primary"
                size="large"
                danger
                onClick={discardChanges}
              >
                <Trans>Cancel</Trans>
              </StornoButton>
            </Form.Item>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
