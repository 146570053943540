import styled from 'styled-components';
import { Input, Button, Radio, Alert, Checkbox } from 'antd';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  max-width: 300px;
  margin: auto !important;
`;

export const StyledLabel = styled.span`
  font-style: italic;
  color: #7cb04a;
`;

export const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: none !important;
  :hover {
    border-bottom: 2px solid #e4e4e4;
  }
  :focus {
    border-bottom: 1px solid #e4e4e4;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover {
    border: 1px solid #e4e4e4 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:focus .ant-input-affix-wrapper-focused {
    border: 1px solid #e4e4e4;
    box-shadow: none !important;
  }
`;

export const StyledPassword = styled(Input.Password)`
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: none !important;
  :hover {
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none !important;
  }
  :focus {
    border: 1px solid #e4e4e4;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:focus .ant-input-affix-wrapper-focused {
    border: 1px solid #e4e4e4;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:hover {
    border: 1px solid #e4e4e4 !important;
    box-shadow: none !important;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  background-color: #7cb04a;
  border-color: #7cb04a;
  border-radius: 4px;
  font-weight: bold;
  :hover {
    background-color: #7cb04a;
    border-color: #e8e8e8;
  }
`;

export const StyledLink = styled.a`
  color: #7cb04a;
  font-size: 12px;
  float: right;
  &:hover {
    color: #7cb04a;
    font-weight: bold;
  }
`;

export const StyledRecoveryLink = styled(Link)`
  color: #7cb04a;
  font-size: 12px;
  float: right;
  &:hover {
    color: #7cb04a;
    font-weight: bold;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: #7cb04a;
  font-weight: bold;

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #7cb04a !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7cb04a;
    border-color: #7cb04a;
  }
  .ant-checkbox-inner::after {
    background-color: #7cb04a;
  }

  .ant-checkbox-checked::after {
    border-color: #7cb04a;
  }
`;

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
`;
