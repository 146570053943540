import React from 'react';

/** Custom imports */
import { WorldMap } from 'components/elements/WorldMap';
import _get from 'lodash/get';
import { MapContent } from '../../../styles/layout';

export const World = () => {
  return (
    <MapContent>
      <WorldMap />
    </MapContent>
  );
};
