import styled from 'styled-components/macro';

export const Wallpaper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Wrapper = styled.div`
  max-width: 400px;
  background-color: white;
  padding: 50px;
  border-radius: 5px;
  margin: auto;
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    width: 150px;
    background-color: #323440 !important;
    border-bottom-color: #323440 !important;
    border-radius: 6px;
    .ant-tabs-tab-btn {
      color: #f4f4f4 !important;
      font-weight: bold;
    }
    border-bottom: none !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom
    > div
    > .ant-tabs-nav
    .ant-tabs-tab:not(:last-of-type) {
    width: 150px;
    background-color: #f4f4f4;
    border-bottom-color: #f4f4f4;
    border-radius: 6px;
    .ant-tabs-tab-btn {
      color: #323440;
      font-weight: bold;
    }
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      border-bottom: none;
    }
  }
`;
