import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation loginUser($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
      }
    }
  }
`;

export const CHANGE_USER_SETTINGS = gql`
  mutation editUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
    }
  }
`;

export const MUTATION_CREATE_CONDITION = gql`
  mutation setAlarm($input: createAlarmInput!) {
    createAlarm(input: $input) {
      alarm {
        id
        name
        unit
        operator
        value
        method {
          method
        }
        period
        stations {
          name
        }
      }
    }
  }
`;

export const MUTATION_EDIT_CONDITION = gql`
  mutation updateAlarm($input: updateAlarmInput!) {
    updateAlarm(input: $input) {
      alarm {
        id
        name
        unit
        operator
        value
        method {
          method
          id
        }
        period
        stations {
          name
          id
        }
      }
    }
  }
`;

export const MUTATION_DELETE_CONDITION = gql`
  mutation deleteAlarm($input: deleteAlarmInput!) {
    deleteAlarm(input: $input) {
      alarm {
        id
      }
    }
  }
`;

export const MUTATION_DELETE_EVENT = gql`
  mutation deleteEvent($input: deleteEventInput!) {
    deleteEvent(input: $input) {
      event {
        id
      }
    }
  }
`;

export const MUTATION_EDIT_STATION = gql`
  mutation editStationInfo($input: updateStationInput!) {
    updateStation(input: $input) {
      station {
        id
        name
        latitude
        longitude
        ground_level_altitude
        sea_level_altitude
        Settings
        devices {
          id
          sn
          device_type {
            name
            type
          }
        }
      }
    }
  }
`;

export const MUTATION_CREATE_APPLICATION = gql`
  mutation setApplication($input: createApplicationInput!) {
    createApplication(input: $input) {
      application {
        id
        infection_type {
          id
          name
        }
        chemical
        protection_interval
        date
      }
    }
  }
`;

export const MUTATION_EDIT_APPLICATION = gql`
  mutation editApplication($input: updateApplicationInput!) {
    updateApplication(input: $input) {
      application {
        id
        infection_type {
          id
          name
        }
        chemical
        protection_interval
        date
      }
    }
  }
`;

export const MUTATION_DELETE_APPLICATION = gql`
  mutation deleteApplication($input: deleteApplicationInput!) {
    deleteApplication(input: $input) {
      application {
        id
        date
      }
    }
  }
`;
