import React from 'react';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';
import _first from 'lodash/first';
import moment from 'moment';

/** Custom Imports */
import { VineMoldReport } from 'components/elements/VineMoldReport';
import { VineMoldTable } from 'components/elements/VineMoldTable';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PLESEN } from 'graphql/queries';
import { Loader } from '..';
import { activeStationVar } from 'utils/cache';
interface Props {
  selectedYear: number;
}

let currDate = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');

export const VineMoldSection: React.FC<Props> = ({ selectedYear }) => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const { data, loading, error } = useQuery(GET_PLESEN, {
    variables: { stationId: activeStation.id, year: selectedYear },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const plesenData = _get(_first(data.plesens), 'plesenData', []);
  let oneDayCondition = undefined;
  let twoDayCondition = undefined;

  const currentData: any = plesenData.find((item: any) => {
    return item?.date_day == currDate;
  });

  if (currentData != undefined) {
    oneDayCondition = currentData.one_day_condition;
    twoDayCondition = currentData.two_day_condition;
  }

  return (
    <>
      <VineMoldReport
        oneDayCondition={oneDayCondition}
        twoDayCondition={twoDayCondition}
      />
      <VineMoldTable plesenData={plesenData} />
    </>
  );
};
