import React from 'react';
import { Row, Col, Typography, Divider, Empty } from 'antd';
import { Trans } from '@lingui/macro';

/** Custom imports */
import { FavoriteStation } from 'components/elements/FavoriteStation';
import { FavoriteStationForm } from 'components/elements/FavoriteStationForm';
import { EventsTable } from 'components/elements/EventsTable';

const { Title } = Typography;

const stationData = [
  {
    name: 'station name',
    battery: 4,
    humidity: 76.8,
    id: '2',
    irradiation: 258,
    pressure: 100165,
    rain_counter: 30,
    temperature: 5.9,
    timestamp: '2020-12-14T11:30:15.000Z',
  },
  {
    name: 'station name',
    battery: 4,
    humidity: 76.8,
    id: '2',
    irradiation: 258,
    pressure: 100166,
    rain_counter: 129,
    temperature: -1.9,
    timestamp: '2020-12-14T11:30:15.000Z',
  },
];

export const DashboardForUser = () => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Title level={5}>
            <Trans>Weather forecast</Trans>
          </Title>
          <div style={{ padding: '40px 0' }}>
            <Empty />
          </div>
        </Col>
        <Col span={6} xs={24} sm={24} md={12} lg={12}>
          <Title level={5}>
            <Trans>Alert</Trans>
          </Title>
          <EventsTable />
        </Col>
      </Row>
      <Divider type="horizontal" />
      <Title level={5}>
        <Trans>Favorites</Trans>
      </Title>
      <Row gutter={[24, 24]}>
        <FavoriteStation data={stationData} />
        <FavoriteStation data={stationData} />
        <FavoriteStation data={stationData} />
        <FavoriteStationForm />
      </Row>
    </>
  );
};
