import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import XLSX from 'xlsx';
import { jsPDF } from 'jspdf';

/*Custom imports*/
import { StyledMenuItem, StyledCSVLink } from './style';
import { Trans } from '@lingui/macro';

interface Props {
  data: any;
  columns: any;
}

export const ExportMenu: React.FC<Props> = ({ data, columns }) => {
  const getFileName = (type: string, fileType: string) => {
    const date = `sensor-statistic-${type}-data_${moment(new Date()).format(
      'YYYY-MM-DD_hh-mm-ss',
    )}.${fileType}`;
    return date;
  };

  const generatePDFfromTable = () => {
    const doc = new jsPDF('l', 'mm', [500, 210]);
    doc.setFont('Poppins-Regular');
    //@ts-ignore
    doc.autoTable(columns, data, {
      styles: {
        font: 'Poppins-Regular',
        fontStyle: 'normal',
      },
    });
    const fileName = getFileName('table', 'pdf');
    doc.save(fileName);
  };

  const generateXLSXfromTable = () => {
    const titles = columns.map((item: any) => {
      return item.title;
    });

    const wsHeading = [titles];

    const filteredData = data.map((item: any) => {
      const pressurehPa = item.pressure / 100;
      return {
        time: item.timestamp,
        temp: item.temperature,
        pressure: pressurehPa,
        humidity: item.humidity,
        irradiation: item.irradiation,
        battery: item.helix_battery,
        rain: item.rain,
        win_ave: item.wind_ave10,
        win_max: item.wind_max10,
        dir_ave: item.dir_ave10,
        plant: item.plant_canopy_sensor,
        plantTemperature: item.plant_canopy_temperature,
        soil_temp1: item.soil_temp1,
        soil_temp2: item.soil_temp2,
        soil_temp3: item.soil_temp3,
        soil_moisture1: item.soil_moisture1,
        soil_moisture2: item.soil_moisture2,
        soil_moisture3: item.soil_moisture3,
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData, { skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, wsHeading);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    const fileName = getFileName('table', 'xlsx');
    XLSX.writeFile(wb, fileName);
  };

  const menu = (
    <Menu>
      <StyledMenuItem key="0">
        <StyledCSVLink
          headers={columns}
          data={data}
          filename={getFileName('table', 'csv')}
        >
          <Trans>Data from table (.csv)</Trans>
        </StyledCSVLink>
      </StyledMenuItem>
      <StyledMenuItem key="1" onClick={generateXLSXfromTable}>
        <Trans>Data from table (.xlsx)</Trans>
      </StyledMenuItem>
      <StyledMenuItem key="2" onClick={generatePDFfromTable}>
        <Trans>Data from table (.pdf)</Trans>
      </StyledMenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="topRight">
      <Button type="primary" icon={<DownloadOutlined />}>
        <Trans>Export</Trans>
      </Button>
    </Dropdown>
  );
};
