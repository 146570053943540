import styled from 'styled-components';
import { Typography, DatePicker, Radio, Menu } from 'antd';
import { breakpoints } from '../../../styles/breakpoints';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export const StyledTitle = styled(Title)`
  font-size: 18px !important;
  text-align: center;
  margin: 4px 0 20px 0px !important;

  @media ${breakpoints.screen420} {
    font-size: 20px !important;
    margin: 0 0 8px 0 !important;
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  margin: 0 0 16px 0;

  @media ${breakpoints.screen768} {
    margin: 0;
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  padding: 0;
  &&.styled-input .ant-picker-input {
    width: 80px !important;
  }
`;

export const StyledButton = styled(Radio.Button)`
  &&.noBorder {
    padding-top: 8px;
    border: none !important;
    font-size: 14px;
    line-height: 1.2;
    opacity: 0.4;
    color: #031529;
    &&.ant-radio-button-wrapper-checked {
      opacity: 1;
      background: transparent !important;
      border-bottom: 2px solid #3690ff !important;
      color: #3690ff;
    }
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent !important;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  padding: 6px 20px;
`;
