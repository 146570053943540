/* eslint-disable @typescript-eslint/no-unused-vars */
import _round from 'lodash/round';
import _get from 'lodash/get';
import { EChartOption } from 'echarts';
import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
export const getBatteryChartOptions = (chartData = [], color: string): any => {
  const last24HoursValues = chartData.map((item: any) => {
    return item.battery;
  });

  const last24HoursDates = chartData.map((item: any) => {
    return new Date(item.timestamp).toLocaleTimeString('sk', {
      hour: '2-digit',
      minute: '2-digit',
    });
  });

  const currentBattery = _get({ chartData }, 'chartData[0].battery');

  const BatteryChartOptions: EChartOption = {
    backgroundColor: color,
    title: {
      text: currentBattery
        ? `${_round(currentBattery, 2)} ${t`V Voltage of the battery`}`
        : '-',
      top: 10,
      left: 10,
      textStyle: {
        color: '#ffffff',
      },
    },
    tooltip: {
      show: true,
      trigger: 'item',
      position: 'inside',
    },
    grid: {
      show: false,
      left: 56,
      top: 46,
      right: 16,
      bottom: 26,
      borderColor: 'transparent',
    },
    xAxis: {
      type: 'category',
      data: last24HoursDates.reverse(),
      show: true,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
    },

    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 10,
        axisLabel: {
          formatter: '{value} V',
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
    ],
    series: [
      {
        name: t`Last 24 hours`,
        type: 'line',
        smooth: true,
        tooltip: {
          formatter: '{b} {c} V',
        },
        itemStyle: {
          color: '#ffffff',
        },
        data: last24HoursValues.reverse(),
        areaStyle: {
          opacity: 0.3,
        },
      },
    ],
  };

  return BatteryChartOptions;
};
