import React from 'react';
import { Row, Col } from 'antd';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';

/** Custom imports */
import { ConditionsTable } from 'components/elements/ConditionsTable';
import { ConditionPickAlarmType } from 'components/elements/ConditionPickAlarmType';
import { GET_LOGGED_IN_USER, GET_CONDITIONS_BY_USER } from 'graphql/queries';
import { Loader } from '..';

import { StyledTitle } from './style';

export const Conditions = () => {
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const userType = _get(user, 'me.user_type.name');

  const { data: userConditions, loading, error } = useQuery(
    GET_CONDITIONS_BY_USER,
    {
      variables: { email },
    },
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const conditions = _get(userConditions, 'alarms');

  return (
    <>
      <Row gutter={[40, 0]}>
        <ConditionPickAlarmType userType={userType} />
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTitle level={4}>
            <Trans>Conditions</Trans>
          </StyledTitle>
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <ConditionsTable tableData={conditions} />
        </Col>
      </Row>
    </>
  );
};
