import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { Table, Menu } from 'antd';

export const StyledMenuItem = styled(Menu.Item)`
  padding: 6px 20px;
`;

export const ButtonWrapper = styled.div`
  text-align: end;
  padding: 30px 0 0;
`;

export const StyledTable = styled(Table)`
  th {
    font-size: 14px;
    padding: 10px 10px;
  }

  td {
    font-size: 14px;
    padding: 10px 10px;
  }
`;
