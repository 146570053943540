import React from 'react';
import _get from 'lodash/get';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Trans } from '@lingui/macro';
import moment from 'moment';

/** Custom imports */
import { Loader } from 'components/elements';
import { AllSensorsSection } from 'components/elements/AllSensorsSection';
import { ColumnCards } from 'components/elements/ColumnCards';

/** GraphQL */
import {
  GET_LOGGED_IN_USER,
  GET_HELIX_DATA_BY_DEVICE_ID,
} from 'graphql/queries';
import { activeStationVar } from 'utils/cache';

/** Data types*/
import { DevicesAvailable, DevicesID } from 'components/elements/StationTabs';

/**Styles */
import { StyledDivider } from './style';

const timezoneOffset = moment().utcOffset();

const dateTo = moment().utc().add(timezoneOffset, 'minutes');
const dateFrom = moment()
  .subtract(3, 'days')
  .utc()
  .add(timezoneOffset, 'minutes');

interface Props {
  devicesAvailable: DevicesAvailable;
  devicesId: DevicesID;
}

export const StationDashboard: React.FC<Props> = ({
  devicesAvailable,
  devicesId,
}) => {
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const userId = _get(user, 'me.id');
  const activeStation: any = useReactiveVar(activeStationVar);
  const settings = activeStation?.Settings;

  const { data, loading, error } = useQuery(GET_HELIX_DATA_BY_DEVICE_ID, {
    variables: {
      deviceID: devicesId.helix,
      windID: devicesId.wind,
      agroID: devicesId.agro,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return (
      <p>
        <Trans>Error</Trans>
      </p>
    );
  }

  const { tsHelixFulls, tsWindsJson, tsAgroesJson } = data;

  let sensorDepths: Array<string> = [];
  const sensorSettings = _get(settings, 'ground_sensor_depths');

  const createDepths = (depths: Array<number>) => {
    const depthsWithUnits = depths.map((item) => {
      const depth = item.toString();
      const units = 'cm';
      const result = depth.concat(units);
      return result;
    });

    return depthsWithUnits;
  };

  if (sensorSettings != undefined) {
    sensorDepths = createDepths(sensorSettings);
  }

  return (
    <>
      <ColumnCards
        station={activeStation}
        sensorDepths={sensorDepths}
        devicesAvailable={devicesAvailable}
        tsHelixes={tsHelixFulls}
        tsWindsJson={tsWindsJson}
        tsAgroesJson={tsAgroesJson}
      />
      <StyledDivider plain />
      <AllSensorsSection
        userId={userId}
        sensorDepths={sensorDepths}
        devicesAvailable={devicesAvailable}
        devicesId={devicesId}
        tsHelixes={tsHelixFulls}
        tsWindsJson={tsWindsJson}
        tsAgroesJson={tsAgroesJson}
      />
    </>
  );
};
