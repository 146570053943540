//@ts-nocheck
import React, { useState } from 'react';
import { Table, Button, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import _get from 'lodash/get';

/** Custom imports */
import { GET_LOGGED_IN_USER, GET_CONDITIONS_BY_USER } from 'graphql/queries';
import { MUTATION_DELETE_CONDITION } from 'graphql/mutations';
import { ConditionEditModal } from 'components/elements/ConditionEditModal';
import { Trans } from '@lingui/macro';

const infections = [
  { name: <Trans>Downy mildew</Trans>, key: 'inf_peronospora' },
  { name: <Trans>Downy mildew 2</Trans>, key: 'inf_peronospora2' },
  { name: <Trans>Botrytis</Trans>, key: 'inf_botrytida' },
  { name: <Trans>Powdery mildew</Trans>, key: 'inf_mucnatka' },
];

interface Props {
  tableData: any;
}

export const ConditionsTable: React.FC<Props> = ({ tableData }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [itemData, setItemData] = useState({});
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const userId = _get(user, 'me.id');

  const [deleteAlarm, { error: deleteError }] = useMutation(
    MUTATION_DELETE_CONDITION,
    {
      refetchQueries: [{ query: GET_CONDITIONS_BY_USER, variables: { email } }],
    },
  );

  const deleteItem = (id) => {
    deleteAlarm({
      variables: {
        input: {
          where: {
            id: id,
          },
        },
      },
    });

    if (deleteError) {
      message.error(<Trans>The condition could not be deleted</Trans>);
    }
  };

  const editItem = (record: any) => {
    setItemData(record);
    setIsEditable(true);
  };

  const getUnitName = (unit, pairs) => {
    let unitIds = [];
    let unitNames = [];
    pairs.map((item, index) => {
      unitIds[index] = item.key;
      unitNames[index] = item.name;
    });

    const unitIndex = unitIds.indexOf(unit);
    const unitName = unitNames[unitIndex];

    return unitName;
  };

  const columns = [
    {
      title: <Trans>Name</Trans>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <Trans>Weather station</Trans>,
      dataIndex: 'stations',
      key: 'stations',
      render: (items: any) => items.map((item) => `${item.name}, `),
    },
    {
      title: <Trans>Unit</Trans>,
      dataIndex: 'unit',
      key: 'unit',
      render: (item: any) =>
        item.substr(0, 3) === 'inf' ? getUnitName(item, infections) : item,
    },
    {
      title: <Trans>Operator</Trans>,
      dataIndex: 'operator',
      key: 'operator',
    },
    {
      title: <Trans>Value</Trans>,
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: <Trans>Period (h)</Trans>,
      dataIndex: 'period',
      key: 'period',
      render: (item: any) => (item === 0 ? 'Jednorázové' : item),
    },
    {
      title: <Trans>Method</Trans>,
      dataIndex: 'method',
      key: 'method',
      render: (items: any) => items.map((item) => `${item.method}, `),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => deleteItem(record.id)} danger>
          Zmazať
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button
          onClick={() => editItem(record)}
          type="primary"
          style={{ backgroundColor: '#69bd4f', borderColor: '#69bd4f' }}
        >
          Editovať
        </Button>
      ),
    },
  ];

  return (
    <>
      {isEditable ? (
        <ConditionEditModal
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          email={email}
          userId={userId}
          itemData={itemData}
        />
      ) : null}
      <Table
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 1080 }}
        pagination={{ position: ['bottomCenter'] }}
      ></Table>
    </>
  );
};
