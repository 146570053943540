import styled from 'styled-components/macro';
import { Divider } from 'antd';

export const FormItemWrapper = styled.div`
  max-width: 836px;
  margin: 0 auto;
`;

export const FormSection = styled.div`
  margin-bottom: 32px;
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
`;
