//@ts-nocheck
import React from 'react';
import { Select, Form } from 'antd';
import { Trans, t } from '@lingui/macro';
import { useQuery } from '@apollo/client';

import { GET_STATIONS_BY_USER, GET_LOGGED_IN_USER } from 'graphql/queries';
import { Loader } from '..';
import _get from 'lodash/get';

const { Option } = Select;

export const ConditionStations = () => {
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');

  const { data, loading, error } = useQuery(GET_STATIONS_BY_USER, {
    variables: { email },
  });

  const handleChange = (value) => {};

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  return (
    <Form.Item
      label={<Trans>Weather stations</Trans>}
      name="stations"
      rules={[
        { required: true, message: <Trans>Select the weather station!</Trans> },
      ]}
    >
      <Select
        mode="multiple"
        showSearch
        placeholder={t`Search to Select`}
        onChange={handleChange}
      >
        {data.stations.map((station) => (
          <Option key={station.id} value={station.id}>
            {station.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
