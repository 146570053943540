import React from 'react';
import { t } from '@lingui/macro';
import { Divider, Tabs } from 'antd';
import styled from 'styled-components/macro';

/*Custom imports*/
import { Content, Footer } from '../../../styles/layout';
import { TutorialMap } from 'components/elements/TutorialMap';
import { TutorialAlarms } from 'components/elements/TutorialAlarms';
import { TutorialStation } from 'components/elements/TutorialStation';

export { Tabs };
export const { TabPane } = Tabs;
export const StyledDivider = styled(Divider)`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`;

export const Tutorials = () => {
  return (
    <>
      <Content>
        <Tabs type="line">
          <TabPane tab={t`Map`} key="1">
            <TutorialMap />
          </TabPane>
          <TabPane tab={t`Stations`} key="2">
            <TutorialStation />
          </TabPane>
          <TabPane tab={t`Notifications`} key="3">
            <TutorialAlarms />
          </TabPane>
        </Tabs>
      </Content>
      <Footer>©{new Date().getFullYear()} Skymaps s.r.o.</Footer>
    </>
  );
};
