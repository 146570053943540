//@ts-nocheck
import React, { useState, useRef } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { useQuery, useMutation } from '@apollo/client';
import _get from 'lodash/get';

/** Custom imports */
import { GET_LOGGED_IN_USER, GET_USER_INFO } from 'graphql/queries';
import { CHANGE_USER_SETTINGS } from 'graphql/mutations';
import { Loader } from '..';
import styled from 'styled-components';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const StyledSettingIcon = styled(SettingOutlined)`
  svg {
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }
`;

const formItems = [
  {
    type: 'text',
    name: 'username',
    label: 'Užívateľské jméno',
    required: 'true',
    message: 'Zadajte',
  },
  {
    type: 'text',
    name: 'firstName',
    label: 'Meno',
    required: 'true',
    message: 'Zadajte',
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Priezvisko',
    required: 'true',
    message: 'Zadajte',
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    required: 'true',
    message: 'Zadajte',
  },
  {
    type: 'text',
    name: 'phone',
    label: 'Telefónne číslo',
    required: 'true',
    message: 'Zadajte',
  },
];

export const SettingsModal = () => {
  let formRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const { data: user, error: errorLoggedIn } = useQuery(GET_LOGGED_IN_USER);
  const userId = _get(user, 'me.id');

  const { data: userData, error: errorUserData } = useQuery(GET_USER_INFO, {
    variables: { id: userId },
  });

  const [changeSettings, { error: errorEditUser }] = useMutation(
    CHANGE_USER_SETTINGS,
    {
      refetchQueries: [
        { query: GET_LOGGED_IN_USER },
        { query: GET_USER_INFO, variables: { id: userId } },
      ],
    },
  );

  if (errorLoggedIn || errorUserData) {
    return <Trans>ERROR</Trans>;
  }

  const setUpModal = () => {
    setModalVisible(true);
    const userInfo = _get(userData, 'user');

    formRef.current.setFieldsValue({
      username: userInfo.username,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      phone: userInfo.phone,
    });
  };

  const onFinish = (values) => {
    const { username, firstName, lastName, email, phone } = values;

    changeSettings({
      variables: {
        input: {
          where: {
            id: userId,
          },

          data: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
          },
        },
      },
    });
    if (errorEditUser) {
      console.error(errorEditUser);
    }

    formRef.current.resetFields();
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title={<Trans>User settings</Trans>}
        style={{ top: 20 }}
        visible={modalVisible}
        onOk={() => onFinish()}
        onCancel={() => setModalVisible(false)}
        footer={null}
        forceRender={true}
      >
        <Form
          ref={formRef}
          {...layout}
          name="basic"
          preserve={false}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<Trans>User name</Trans>}
            name="username"
            rules={[{ required: true, message: t`Enter a username!` }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={<Trans>Name</Trans>}
            name="firstName"
            rules={[{ required: true, message: t`Enter your name!` }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={<Trans>Surname</Trans>}
            name="lastName"
            rules={[{ required: true, message: t`Enter your surname!` }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label={<Trans>Email</Trans>}
            name="email"
            rules={[{ required: true, message: t`Enter your email address!` }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label={<Trans>Phone number</Trans>}
            name="phone"
            rules={[
              { required: true, message: 'Zadejte svoje telefónne číslo !' },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            {...tailLayout}
            style={{ textAlign: 'end', padding: '20px 0 0 0' }}
          >
            <Button type="primary" htmlType="submit">
              <Trans>Save changes</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button type="link" onClick={() => setUpModal()}>
        <Trans>
          Settings <StyledSettingIcon />
        </Trans>
      </Button>
    </>
  );
};
