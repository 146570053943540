import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';
import _get from 'lodash/get';
import _first from 'lodash/first';

import {
  Layout,
  Logo,
  LogoImg,
  StyledMapItem,
  Wrapper,
  StyledAlarmItem,
  StyledSensorsItem,
  StyledDashboardItem,
  StyledHeader,
  StyledSider,
  StyledMenuIcon,
  StyledMenuUnfoldOutlined,
  StyledMenu,
} from './style';

import { WarningOutlined } from '@ant-design/icons';

import { Trans } from '@lingui/macro';

/** Custom imports */
import { GET_LOGGED_IN_USER, GET_STATIONS_BY_USER } from 'graphql/queries';

import logo from 'assets/images/Meteo_Logo-primary.svg';
import { ReactComponent as SensorsIcon } from 'assets/icons/Meteo_Senzors.svg';
import { ReactComponent as MapIcon } from 'assets/icons/Meteo_Map.svg';
import { HorizontalMenu } from 'components/elements/HorizontalMenu';
import { activeStationVar, isLoggedInVar } from 'utils/cache';
import { LocaleToggle } from 'components/elements/LocaleToggle';

export interface PageTemplate {
  header?: any;
  hero?: any;
  footer?: any;
  children: any;
  iscollapsed?: any;
}

export const PageTemplate = ({
  header,
  hero,
  children,
  footer,
  iscollapsed,
  ...props
}: PageTemplate) => {
  const [openKey, setOpenKey] = useState('');
  const location = useLocation();
  const client = useApolloClient();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { data: user, loading: loadingUser } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const remember = localStorage.getItem('remember');

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (remember === 'false') {
        client.cache.evict({ fieldName: 'me' });
        client.cache.gc();

        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('remember');
      }
    });
  });

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'dashboard') {
      setOpenKey('/dashboard');
    }
  }, [location]);

  const handleOpenChange = (item: any) => {
    if (openKey !== '/dashboard') {
      setOpenKey('/dashboard');
    } else {
      setOpenKey('');
    }
  };

  const { data, loading, error } = useQuery(GET_STATIONS_BY_USER, {
    skip: !email,
    variables: { email },
    onCompleted({ stations }) {
      activeStationVar(_first(stations));
    },
  });

  if (loadingUser || loading) {
    return null;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const onCollapse = () => {
    isMenuOpened ? setIsMenuOpened(false) : setIsMenuOpened(true);
  };

  const getStationOptions = () => {
    const stations = _get(data, 'stations');

    try {
      const options = stations.map((station: any) => {
        return (
          <StyledDashboardItem
            id={`${station.id}`}
            key={`/dashboard/${station.id}`}
            data-id={`${station.id}`}
          >
            <Link to={`/dashboard/${station.id}`}>{`${station.name}`}</Link>
          </StyledDashboardItem>
        );
      });
      return options;
    } catch (error) {
      console.log(error);
      client.cache.evict({ fieldName: 'me' });
      client.cache.gc();
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      isLoggedInVar(false);
    }
  };

  return (
    <Wrapper {...props}>
      <Layout>
        <StyledSider trigger={null} className={isMenuOpened ? 'isHidden' : ''}>
          {isMenuOpened ? null : <StyledMenuIcon onClick={onCollapse} />}
          <Logo>
            <Link to="/">
              <LogoImg src={logo} alt="Meteo Logo" />
            </Link>
          </Logo>
          <StyledMenu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['/worldmap']}
            selectedKeys={[location.pathname]}
            onOpenChange={handleOpenChange}
            openKeys={[openKey]}
          >
            <StyledMapItem key="/worldmap" icon={<MapIcon />}>
              <Link to="/worldmap">
                <Trans>Map</Trans>
              </Link>
            </StyledMapItem>
            <StyledAlarmItem key="/alarms" icon={<WarningOutlined />}>
              <Link to="/alarms">
                <Trans>Notifications</Trans>
              </Link>
            </StyledAlarmItem>
            {loading ? null : (
              <StyledSensorsItem
                key="/dashboard"
                icon={<SensorsIcon />}
                title={<Trans>Stations</Trans>}
              >
                {loading ? null : getStationOptions()}
              </StyledSensorsItem>
            )}
          </StyledMenu>
        </StyledSider>

        <Layout>
          {/* @ts-ignore */}
          <StyledHeader iscollapsed={isMenuOpened.toString()}>
            <div>
              <StyledMenuUnfoldOutlined onClick={onCollapse} />
            </div>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <LocaleToggle />
              <HorizontalMenu />
            </div>
          </StyledHeader>
          {children}
        </Layout>
      </Layout>
    </Wrapper>
  );
};
