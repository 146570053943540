import React from 'react';

const COLORS = {
  success: '#69bd4f',
  warning: '#f8ad14',
  error: '#ff3d5e',
};

const coloredCell = (text: string, color: string) => {
  return {
    props: {
      style: {
        borderBottom: `3px solid ${color}`,
        color: `${color}`,
      },
    },
    children: <div>{text}</div>,
  };
};

export const errorCell = (text: string) => coloredCell(text, COLORS.error);
export const warningCell = (text: string) => coloredCell(text, COLORS.warning);
export const successCell = (text: string) => coloredCell(text, COLORS.success);
