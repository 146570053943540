//@ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { Col, Row, Table } from 'antd';
import {
  errorCell,
  successCell,
  warningCell,
} from '../TableSettings/TableSettings';
import { t, Trans } from '@lingui/macro';

const StyledTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px !important;
  }
  .ant-table.ant-table-small {
    font-size: 12px;
  }
  .ant-table-cell {
    text-align: center !important;
  }
  .ant-alert {
    padding: 0px !important;
  }
`;

const renderPathogenStateCell = (text, record) => {
  if (record.index <= 30 && record.initd == false)
    return <Trans>Without infection</Trans>;
  else if (record.index <= 30 && record.initd == true)
    return <Trans>Present</Trans>;
  else if (record.index < 60)
    return <Trans>Reproductive interval 15 days</Trans>;
  else return <Trans>Reproductive interval 5 days</Trans>;
};

const renderInfectionPressureCell = (text, record) => {
  if (record.index <= 30 && record.initd === false)
    return successCell(<Trans>None</Trans>);
  else if (record.index <= 30 && record.initd === true)
    return successCell(<Trans>Low</Trans>);
  else if (record.index < 60) return warningCell(<Trans>Medium</Trans>);
  else return errorCell(<Trans>High</Trans>);
};

const columns = [
  {
    title: t`Date`,
    dataIndex: 'date_day',
    render: (item: any) => item.split('T')[0],
    defaultSortOrder: 'descend',
    sorter: (a, b) => Date.parse(a.date_day) - Date.parse(b.date_day),
  },
  {
    title: t`Hours with temperature 21 °C < t < 30 °C`,
    dataIndex: 'total_hrs_1',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: t`Hours with temperature t > 35 °C`,
    dataIndex: 'total_hrs_2',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: t`Index value`,
    dataIndex: 'index',
    render: (item: any) => (item === null ? '' : item),
  },
  {
    title: t`Pathogen status`,
    dataIndex: ['index', 'initd'],
    render: renderPathogenStateCell,
  },
  {
    title: t`Disease pressure`,
    dataIndex: ['index', 'initd'],
    render: renderInfectionPressureCell,
  },
];

export const MucnatkaTable = ({ data }: any) => {
  let i = 0;
  return (
    <>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTable
            bordered
            size="small"
            rowKey={() => i++}
            pagination={{ defaultPageSize: 20 }}
            columns={columns}
            dataSource={data}
          />
        </Col>
      </Row>
    </>
  );
};
