import _round from 'lodash/round';
import _get from 'lodash/get';
import fill from 'lodash/fill';
import { EChartOption } from 'echarts';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */

export const getSoilMoistureChartOptions = (
  chartData = [],
  type: any,
  depth: string,
): any => {
  let label: Array<string> = [];
  for (let i = 0; i <= 10; i++) {
    label[i] = (i * 10).toString();
  }

  const getData = () => {
    let newData = fill(Array(16), '');
    //@ts-ignore
    if (chartData[0]) {
      let index = chartData[0][type];
      //@ts-ignore
      newData[index] = chartData[0][type];
    }

    return newData;
  };

  const currnetSoilMoisture = _get({ chartData }, `chartData[0].${type}`);

  const soilMoistureChartOptions: EChartOption = {
    series: [
      {
        type: 'gauge',
        min: 0,
        max: -300,
        detail: {
          //@ts-ignore
          formatter: currnetSoilMoisture
            ? `${_round(currnetSoilMoisture, 2)} kPa`
            : '-',
          fontSize: 18,
          fontWeight: 'bolder',
          color: 'rgba(0, 0, 0, 1)',
        },
        data: [Math.max(currnetSoilMoisture, -300)],
        radius: '100%',
        splitLine: {
          length: 25,
          lineStyle: {
            color: '#000000',
          },
        },
        axisTick: {
          lineStyle: {
            color: '#000000',
          },
        },
        axisLine: {
          lineStyle: {
            width: 20,
            color: [
              [0.1, '#4169E1'],
              [0.2, '#a9a9a9'],
              [0.4, '#6e6e6e'],
              [1, '#e6c55c'],
            ],
          },
        },
        axisLabel: {
          show: false,
          color: '#000000',
        },
        itemStyle: {
          color: '#000000',
        },
      },
    ],
  };

  return soilMoistureChartOptions;
};
