import React from 'react';
import { Col, Row } from 'antd';
import _round from 'lodash/round';
import moment from 'moment';

/** Custom imports */
import { WindChart } from 'components/elements';
import { StationMap } from 'components/elements/StationMap';
import { Popovers } from 'components/elements/Popovers';
import { ChartsWrapper } from './style';
/**Data types */
import { DevicesAvailable } from 'components/elements/StationTabs';

interface Props {
  tsHelixes: any;
  tsWindsJson: any;
  tsAgroesJson: any;
  station: any;
  sensorDepths: Array<string>;
  devicesAvailable: DevicesAvailable;
}

export const ColumnCards: React.FC<Props> = ({
  tsHelixes,
  tsWindsJson,
  tsAgroesJson,
  station,
  sensorDepths,
  devicesAvailable,
}) => {
  let isWindData = true;

  const filterInterval = (
    data: any,
    interval: moment.DurationInputArg1,
    intervalType: moment.DurationInputArg2,
  ) => {
    const filteredData = data.filter(
      (item: any) =>
        moment(item.timestamp) > moment().subtract(interval, intervalType),
    );

    return filteredData;
  };

  const lastDayHelixes = filterInterval(tsHelixes, 1, 'days');
  const lastDayWinds = filterInterval(tsWindsJson, 1, 'days');
  const lastDayAgroes = filterInterval(tsAgroesJson, 1, 'days');
  const last30Helixes = filterInterval(tsHelixes, 30, 'minutes');

  if (lastDayWinds.length === 0) {
    isWindData = false;
  }

  const colSpan = isWindData ? 6 : 12;
  const colLg = isWindData ? 9 : 12;

  return (
    <ChartsWrapper>
      <Row gutter={[24, 24]}>
        <Col span={colSpan} xs={24} sm={24} md={12} lg={colLg}>
          <Popovers
            lastDayHelixes={lastDayHelixes}
            lastDayWinds={lastDayWinds}
            lastDayAgroes={lastDayAgroes}
            sensorDepths={sensorDepths}
            devicesAvailable={devicesAvailable}
          />
        </Col>
        {isWindData ? (
          <Col span={6} xs={24} sm={24} md={12} lg={6}>
            <WindChart data={lastDayWinds} />
          </Col>
        ) : null}
        <Col span={colSpan} xs={24} sm={24} md={12} lg={colLg}>
          <StationMap
            name={station.name}
            position={[station.latitude, station.longitude]}
            quantity={_round(last30Helixes.temperature, 2)}
          />
        </Col>
      </Row>
    </ChartsWrapper>
  );
};
