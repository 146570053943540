import React from 'react';
import ReactEcharts from 'echarts-for-react';

/** Custom imports */
import { getBotrytisChartOptions } from './BotrytisChartOptions';

export const BotrytisChart = ({ botrytisData }: any) => {
  const option = getBotrytisChartOptions(botrytisData);

  const onChartReady = () => {};

  const handleClickEvent = (e: any) => {};
  return (
    <>
      <ReactEcharts
        option={option}
        notMerge={false}
        lazyUpdate={true}
        // theme={'theme_name'}
        onChartReady={onChartReady}
        onEvents={{ click: handleClickEvent }}
        style={{
          height: '400px',
          width: '100%',
        }}
      />
    </>
  );
};
