//@ts-nocheck
import React, { useRef } from 'react';
import {
  Button,
  Select,
  Form,
  Modal,
  Input,
  InputNumber,
  DatePicker,
} from 'antd';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { activeStationVar } from 'utils/cache';
import _get from 'lodash/get';
import { nanoid } from 'nanoid';
import moment from 'moment';

/** Custom imports */
import { GET_LOGGED_IN_USER } from 'graphql/queries';
import {
  MUTATION_EDIT_APPLICATION,
  MUTATION_DELETE_APPLICATION,
} from 'graphql/mutations';

import { GET_INFECTION_TABLE, GET_APPLICATION_BY_ID } from 'graphql/queries';

import { StyledButton } from './style';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const infections = [
  { name: <Trans>Downy mildew</Trans>, key: 1 },
  { name: <Trans>Powdery mildew</Trans>, key: 2 },
  { name: <Trans>Downy mildew 2</Trans>, key: 3 },
  { name: <Trans>Botrytis</Trans>, key: 4 },
];

const DATE_FORMAT2 = `YYYY-MM-DD`;
interface Props {
  selectedYear: number;
  isEditable: boolean;
  setIsEditable: any;
  itemId: any;
  setShowLoading: any;
}

let id = 0;

export const InfectionsEditModal: React.FC<Props> = ({
  selectedYear,
  isEditable,
  setIsEditable,
  itemId,
  setShowLoading,
}) => {
  let formRef = useRef();
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const userId = _get(user, 'me.id');

  const { data: applicationData, error: applicationError } = useQuery(
    GET_APPLICATION_BY_ID,
    {
      variables: {
        applicationId: itemId,
      },
      fetchPolicy: 'network-only',
      onCompleted(applicationData) {
        const formData = _get(applicationData, 'application');
        const date = formData.date;
        const infectionId = formData.infection_type?.id;

        formRef.current.setFieldsValue({
          preparations: formData.chemical,
          value: formData.protection_interval,
          infections: infections[infectionId - 1]?.key,
          date: moment(date),
        });
      },
    },
  );

  const activeStation: any = useReactiveVar(activeStationVar);
  const [editApplication, { error }] = useMutation(MUTATION_EDIT_APPLICATION, {
    refetchQueries: [
      {
        query: GET_INFECTION_TABLE,
        variables: {
          stationId: activeStation.id,
          userId: userId,
          year: selectedYear,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted(data: any) {
      setShowLoading(false);
    },
  });
  const [deleteApplication, { error: deleteError }] = useMutation(
    MUTATION_DELETE_APPLICATION,
    {
      onCompleted(data: any) {
        setShowLoading(false);
      },
    },
  );

  const onDeleteApplication = () => {
    setShowLoading(true);

    deleteApplication({
      variables: {
        input: {
          where: {
            id: itemId,
          },
        },
      },

      update: (cache, { data }) => {
        const application = data?.deleteApplication.application;

        const existingTable = cache.readQuery({
          query: GET_INFECTION_TABLE,
          variables: {
            stationId: activeStation.id,
            userId: userId,
            year: selectedYear,
          },
        });

        const newTableData = existingTable.infectionTables[0]?.infectionTableData.map(
          (item: any) => {
            if (item.date_day === application.date) {
              if (item['peronospora_application_id'] == application.id) {
                return Object.assign({}, item, {
                  peronospora_chemical: null,
                  peronospora_application_id: null,
                });
              } else if (
                item['peronospora2_application_id'] == application.id
              ) {
                return Object.assign({}, item, {
                  peronospora2_chemical: null,
                  peronospora2_application_id: null,
                });
              } else if (item['mucnatka_application_id'] == application.id) {
                return Object.assign({}, item, {
                  mucnatka_chemical: null,
                  mucnatka_application_id: null,
                });
              } else {
                return Object.assign({}, item, {
                  botrytis_chemical: null,
                  botrytis_application_id: null,
                });
              }
            } else {
              return item;
            }
          },
        );

        cache.writeQuery({
          query: GET_INFECTION_TABLE,
          variables: {
            stationId: activeStation.id,
            userId: userId,
            year: selectedYear,
          },
          data: {
            infectionTables: [
              {
                infectionTableData: newTableData,
              },
            ],
          },
        });
      },
    });

    setIsEditable(false);
  };

  const onFinish = (values) => {
    const { infections, preparations, date, value } = values;
    const validDate = date.format(DATE_FORMAT2);
    setShowLoading(true);

    editApplication({
      variables: {
        input: {
          data: {
            chemical: preparations,
            date: validDate,
            infection_type: infections,
            protection_interval: value,
          },
          where: {
            id: itemId,
          },
        },
      },
    });

    formRef.current.resetFields();
    setIsEditable(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getOptions = (items) => {
    return items.map((item) => {
      return (
        <Option value={item.key} key={item.name}>
          {item.name}
        </Option>
      );
    });
  };

  const disabledDate = (current: any) => {
    const myValue = current > moment().endOf('day');
    return myValue;
  };

  if (applicationError) {
    return null;
  }

  return (
    <>
      <Modal
        title="Upravit zásah"
        style={{ top: 20 }}
        visible={isEditable}
        onOk={() => onFinish()}
        onCancel={() => setIsEditable(false)}
        footer={null}
        forceRender={true}
      >
        <Form
          {...layout}
          ref={formRef}
          name={`infection ${nanoid(10)}`}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={<Trans>Select disease</Trans>}
            name="infections"
            rules={[{ required: true, message: <Trans>Select disease</Trans> }]}
            fieldKey={'infections'}
          >
            <Select mode="single" showSearch placeholder={t`Disease`}>
              {getOptions(infections)}
            </Select>
          </Form.Item>

          <Form.Item
            label={<Trans>Enter the product</Trans>}
            name="preparations"
            fieldKey={'preparations'}
            rules={[
              { required: true, message: <Trans>Enter the product</Trans> },
            ]}
          >
            <Input placeholder={t`Product`}></Input>
          </Form.Item>
          <Form.Item
            {...layout}
            label={<Trans>Select the date</Trans>}
            name="date"
            fieldKey="date"
            rules={[
              {
                required: true,
                message: <Trans>Select the date</Trans>,
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            {...layout}
            step={1}
            label={<Trans>Enter the protection period</Trans>}
            name="value"
            fieldKey="value"
            rules={[
              {
                required: true,
                message: <Trans>Enter the protection period</Trans>,
              },
            ]}
          >
            <InputNumber
              {...layout}
              style={{ width: '100%' }}
              placeholder={t`Number of days`}
            />
          </Form.Item>

          <Form.Item
            {...tailLayout}
            style={{ textAlign: 'end', padding: '20px 0 0 0' }}
          >
            <StyledButton type="primary" htmlType="submit">
              <Trans>Save changes</Trans>
            </StyledButton>
            <Button type="primary" onClick={onDeleteApplication} danger>
              <Trans>Delete</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
