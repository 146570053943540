import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { Radio, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const ChartButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 0;
  button {
    display: inline;
  }

  @media ${breakpoints.screen768} {
    flex-direction: row;
    padding: 50px 0;
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  margin: 0 -14px 15px -14px;

  @media ${breakpoints.screen768} {
    margin: 0 0;
  }
`;

export const StyledButton = styled(Radio.Button)`
  &&.noBorder {
    padding-top: 8px;
    border: none !important;
    font-size: 14px;
    line-height: 1.2;
    opacity: 0.4;
    color: #031529;
    &&.ant-radio-button-wrapper-checked {
      opacity: 1;
      background: transparent !important;
      border-bottom: 2px solid #3690ff !important;
      color: #3690ff;
    }
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent !important;
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  padding: 0;

  &&.styled-input .ant-picker-input {
    width: 80px !important;
  }
`;
