//@ts-nocheck
import React, { useRef, useEffect } from 'react';
import { Form, Modal, Input, InputNumber, Button, Select, message } from 'antd';
import { useMutation } from '@apollo/client';

/*Custom imports*/
import { MUTATION_EDIT_CONDITION } from 'graphql/mutations';
import { GET_CONDITIONS_BY_USER } from 'graphql/queries';
import { ConditionStations } from 'components/elements/ConditionStations';
import { ConditionMethods } from 'components/elements/ConditionMethods';
import { StyledFormItem } from './style';
import { Trans } from '@lingui/macro';

const { Option } = Select;

const units = [
  { name: <Trans>Temperature [°C]</Trans> },
  { name: <Trans>Pressure [hPa]</Trans> },
  { name: <Trans>Humidity [%]</Trans> },
  { name: <Trans>Total precipitation [mm]</Trans> },
];

const operators = [{ name: '>' }, { name: '<' }];

const periods = [
  { name: <Trans>One-time</Trans>, key: 0 },
  { name: 1, key: 1 },
  { name: 4, key: 4 },
  { name: 24, key: 24 },
];

const infections = [
  { name: <Trans>Downy mildew</Trans>, key: 'inf_peronospora' },
  { name: <Trans>Downy mildew 2</Trans>, key: 'inf_peronospora2' },
  { name: <Trans>Powdery mildew</Trans>, key: 'inf_mucnatka' },
  { name: <Trans>Botrytis</Trans>, key: 'inf_botrytida' },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

interface Props {
  isEditable: boolean;
  setIsEditable: any;
  email: string;
  userId: number;
  itemData: any;
}

let id = 0;

export const ConditionEditModal: React.FC<Props> = ({
  isEditable,
  setIsEditable,
  email,
  userId,
  itemData,
}) => {
  let formRef = useRef();

  const [editAlarm, { data: editCondition, error }] = useMutation(
    MUTATION_EDIT_CONDITION,
    {
      refetchQueries: [{ query: GET_CONDITIONS_BY_USER, variables: { email } }],
    },
  );

  useEffect(() => {
    let stationNames = [];
    let methodNames = [];
    id = itemData.id;
    itemData.stations.map((station) => {
      stationNames.push(station.id);
    });

    itemData.method.map((methoda) => {
      methodNames.push(methoda.id);
    });

    if (formRef) {
      formRef.current.setFieldsValue({
        name: itemData.name,
        unit: itemData.unit,
        stations: stationNames,
        operator: itemData.operator,
        period: itemData.period,
        value: itemData.value,
        method: methodNames,
      });
    }
  }, [formRef, itemData]);

  const onFinish = (values: any) => {
    const { name, unit, operator, value, period, stations, method } = values;
    editAlarm({
      variables: {
        input: {
          where: {
            id: id,
          },
          data: {
            name: name,
            unit: unit,
            operator: operator,
            value: value,
            period: period,
            method: method,
            stations: stations,
            owner: userId,
          },
        },
      },
    });
    if (error) {
      message.error(<Trans>Failed to save changes</Trans>);
    }

    formRef.current.resetFields();
    setIsEditable(false);
  };

  const getOptions = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.name} key={item.name}>
          {item.name}
        </Option>
      );
    });
  };

  const getKeyOptions = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.key} key={item.key}>
          {item.name}
        </Option>
      );
    });
  };

  const getPeriods = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.key} key={item.key}>
          {item.name}
        </Option>
      );
    });
  };

  return (
    <Modal
      title={<Trans>Add new condition</Trans>}
      style={{ top: 20 }}
      visible={isEditable}
      onCancel={() => setIsEditable(false)}
      footer={null}
      forceRender={true}
    >
      <Form
        ref={formRef}
        {...layout}
        name="control-ref"
        preserve={false}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <Form.Item
          label={<Trans>Name</Trans>}
          name="name"
          rules={[{ required: true, message: <Trans>Add alarm name!</Trans> }]}
        >
          <Input />
        </Form.Item>

        <ConditionStations />

        {itemData?.operator == null ? (
          <Form.Item
            label={<Trans>Disease pressure</Trans>}
            name="unit"
            rules={[
              {
                required: true,
                message: <Trans>Select disease pressure!</Trans>,
              },
            ]}
          >
            <Select>{getKeyOptions(infections)}</Select>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label={<Trans>Unit</Trans>}
              name="unit"
              rules={[
                { required: true, message: <Trans>Select units!</Trans> },
              ]}
            >
              <Select>{getOptions(units)}</Select>
            </Form.Item>
            <Form.Item
              label={<Trans>Operator</Trans>}
              name="operator"
              rules={[
                { required: true, message: <Trans>Select operator!</Trans> },
              ]}
            >
              <Select>{getOptions(operators)}</Select>
            </Form.Item>
            <Form.Item
              {...layout}
              step={0.1}
              label={<Trans>Value</Trans>}
              name="value"
              rules={[{ required: true, message: <Trans>Add value</Trans> }]}
            >
              <InputNumber {...layout} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              {...layout}
              label={<Trans>Period [h]</Trans>}
              name="period"
              rules={[
                {
                  required: true,
                  message: <Trans>Select time period!</Trans>,
                },
              ]}
            >
              <Select>{getPeriods(periods)}</Select>
            </Form.Item>{' '}
          </>
        )}

        <ConditionMethods />

        <StyledFormItem {...tailLayout}>
          <Button type="primary" htmlType="submit">
            <Trans>Save changes</Trans>
          </Button>
        </StyledFormItem>
      </Form>
    </Modal>
  );
};
