import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Trans } from '@lingui/macro';

/** Custom Imports */
import { PeronosporaReport } from 'components/elements/PeronosporaReport';
import { PeronosporaChart } from 'components/elements/PeronosporaChart';
import { GET_PERONOSPORA_FOR_STATION } from 'graphql/queries';
import { Loader } from 'components/elements';
import _first from 'lodash/first';
import _get from 'lodash/get';

interface Props {
  selectedYear: number;
  stationId: number;
}

export const PeronosporaSection: React.FC<Props> = ({
  selectedYear,
  stationId,
}) => {
  const { data, loading, error } = useQuery(GET_PERONOSPORA_FOR_STATION, {
    variables: { stationId: stationId, year: selectedYear },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  let currDate = new Date().toISOString().split('T')[0];

  let peronosporaData: any = _get(
    _first(data.peronosporas),
    'peronosporaData',
    [],
  );

  const currentData: any = peronosporaData.find((item: any) => {
    return item?.date_day == currDate;
  });

  let peronosporaStatus = undefined;

  if (currentData != undefined) {
    peronosporaStatus = currentData;
  }

  return (
    <>
      <PeronosporaReport peronosporaStatus={peronosporaStatus} />
      <PeronosporaChart peronosporaData={peronosporaData} />
    </>
  );
};
