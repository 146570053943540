import { Divider, Tabs } from 'antd';
import styled from 'styled-components/macro';
import { breakpoints } from '../../../styles/breakpoints';

export { Tabs };
export const { TabPane } = Tabs;

export const StyledTabPane = styled(TabPane)``;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 13px;
    margin: 0 13px 0 0;
  }

  @media ${breakpoints.screen420} {
    .ant-tabs-tab {
      font-size: 14px;
      margin: 0 24px 0 0;
    }
  }

  @media ${breakpoints.screen992} {
    .ant-tabs-tab {
      margin: 0 32px 0 0;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
`;
