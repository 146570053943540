import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { Trans } from '@lingui/macro';

const { Text } = Typography;
const { Paragraph } = Typography;

interface Props {
  peronosporaStatus: any;
}

const InfoText = styled.div`
  text-align: left;
  padding-bottom: 32px;
`;

export const PeronosporaReport: React.FC<Props> = ({ peronosporaStatus }) => {
  let infoText: JSX.Element = <Paragraph></Paragraph>;
  if (peronosporaStatus === undefined) {
    infoText = (
      <Paragraph>
        <Trans>Current status: Outside of the monitored period</Trans>
      </Paragraph>
    );
  } else if (peronosporaStatus.rain_sum < peronosporaStatus.hazard1) {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status: </Trans>
          </Text>
          <Text type="success">
            <Trans>No disease pressure</Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Trans>
            <Text strong>Recommendation: </Text>Treatment after flowering and
            additional treatments every 10-14 days.
          </Trans>
        </Paragraph>
      </>
    );
  } else if (peronosporaStatus.rain_sum < peronosporaStatus.hazard2) {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status: </Trans>
          </Text>
          <Text type="warning">
            <Trans>Sporadic-calamitous occurrence</Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Trans>
            <Text strong>Recommendation: </Text>During the 2-week period before
            flowering - one treatment before flowering and two treatments after
            flowering at intervals of 10-14 days. During flowering and shortly
            after flowering - three treatments after flowering at intervals of
            10-14 days.
          </Trans>
        </Paragraph>
      </>
    );
  } else {
    infoText = (
      <>
        <Paragraph>
          <Text strong>
            <Trans>Current status: </Trans>
          </Text>
          <Text type="danger">
            <Trans>Calamitous occurrence</Trans>
          </Text>
        </Paragraph>
        <Paragraph>
          <Trans>
            <Text strong>Recommendation: </Text>Regular treatment at intervals
            of 5-14 days (according to the level of disease pressure, growth
            intensity, and the pesticide used) until berry softening.
          </Trans>
        </Paragraph>
      </>
    );
  }

  return <InfoText>{infoText}</InfoText>;
};
