import { Input, Button, Alert } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 300px;
  margin: auto !important;
`;

export const StyledLabel = styled.span`
  font-style: italic;
  color: #7cb04a;
`;

export const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: none !important;
  :hover {
    border-bottom: 2px solid #e4e4e4;
  }
  :focus {
    border-bottom: 1px solid #e4e4e4;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover {
    border: 1px solid #e4e4e4 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:focus .ant-input-affix-wrapper-focused {
    border: 1px solid #e4e4e4;
    box-shadow: none !important;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  color: #ffffff;
  background-color: #7cb04a;
  border-color: #7cb04a;
  border-radius: 4px;
  font-weight: bold;
  &:hover,
  &:active,
  &:visited,
  &:link {
    background: #7cb04a;
    background-color: #7cb04a;
    border-color: #e8e8e8;
  }
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
`;
