import styled from 'styled-components/macro';
import { Card, Form, InputNumber, Button } from 'antd';
import { SettingOutlined, LoadingOutlined } from '@ant-design/icons';

export const StyledCard = styled(Card)`
  position: relative;
`;

export const StyledFormItem = styled(Form.Item)`
  text-align: end;
  padding: 0 0 0 0;
  margin: 0;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledSettingOutlined = styled(SettingOutlined)`
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const SaveButton = styled(Button)`
  margin-right: 88px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 6.4px 30px;
  font-size: 14px;

  & > .ant-btn-loading-icon {
    transition: all 0.15s;
  }

  .ant-btn-loading-icon {
    margin-right: 10px;
  }
`;

export const StornoButton = styled(Button)`
  text-transform: uppercase;
  font-weight: 600;
  padding: 6.4px 30px;
  font-size: 14px;
`;
