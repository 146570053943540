import overview from '../LineChart/overview.json';
import _round from 'lodash/round';
import moment from 'moment';

const KELVIN_BASE = 273.15;

const last24HoursDewPoint = overview.last24.reverse().map((item) => {
  return _round(item.dewPoint - KELVIN_BASE, 2);
});

const forecast48Date = overview.fc48.map((item) => {
  return moment(item.time).format('MMM.DD,YYYY hh:mm');
});

const forecast48DewPoint = overview.fc48.map((item) => {
  return _round(item.temperature - KELVIN_BASE, 2);
});

const getDewFrostTitle = () => {
  return last24HoursDewPoint[last24HoursDewPoint.length - 1] < 0
    ? 'Frost'
    : 'Dew';
};

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
export const getDewPointChartOptions = (options = {}): any => {
  const dewPointChartOptions = {
    backgroundColor: '#e83e8c',
    title: {
      text: `${
        last24HoursDewPoint[last24HoursDewPoint.length - 1]
      }°C ${getDewFrostTitle()} Point`,
      top: 10,
      left: 10,
      textStyle: {
        color: '#ffffff',
      },
    },
    tooltip: {
      show: true,
      trigger: 'item',
      position: 'inside',
    },
    grid: {
      show: false,
      left: 56,
      top: 46,
      right: 16,
      bottom: 26,
      borderColor: 'transparent',
    },
    xAxis: {
      type: 'category',
      data: forecast48Date,
      show: true,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          show: true,
          color: '#ffffff',
        },
      },
      axisLabel: {
        show: false,
        formatter: '',
      },
    },

    yAxis: [
      {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        axisLabel: {
          formatter: '{value} °C',
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
    ],
    series: [
      {
        name: 'Last 24 Hours',
        type: 'line',
        smooth: true,
        tooltip: {
          formatter: '{b} {c}°C',
        },
        itemStyle: {
          color: '#ffffff',
        },
        data: last24HoursDewPoint,
      },
      {
        name: 'Forecast',
        type: 'line',
        smooth: true,
        tooltip: {
          formatter: '{b} {c} °C',
        },
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
        itemStyle: {
          color: '#ffffff',
        },
        data: forecast48DewPoint,
      },
    ],
  };

  return dewPointChartOptions;
};
