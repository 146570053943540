import React from 'react';
import { Marker } from 'react-leaflet';
import L, { LatLngExpression, LeafletEventHandlerFnMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';

/** Custom imports */
/** Styles */
import 'styles/arrow.css';
import { StyledTooltip } from './style';

export interface MarkerOptions {
  name: string;
  position: LatLngExpression;
  quantity?: number;
  units?: string;
  eventHandlers?: LeafletEventHandlerFnMap;
  draggable?: boolean;
  ref?: React.RefObject<L.Marker>;
  showTooltip?: boolean;
}

interface Props {
  markerOptions: MarkerOptions;
}

export const MapMarker: React.FC<Props> = ({ markerOptions }) => {
  let stringToPrint: string;

  if (markerOptions.quantity == null || isNaN(markerOptions.quantity)) {
    stringToPrint = '';
  } else {
    stringToPrint = markerOptions.quantity + (markerOptions.units ?? '');
  }

  const icon = new L.DivIcon({
    className: 'icon-div',
    html:
      `<div class="arrow"><span class="arrow-text">` +
      stringToPrint +
      `</span></div>`,
    iconAnchor: [3, 15],
  });

  return (
    <>
      <Marker
        position={markerOptions.position}
        icon={icon}
        eventHandlers={markerOptions.eventHandlers ?? undefined}
        draggable={markerOptions.draggable}
        ref={markerOptions.ref ?? undefined}
      >
        {markerOptions.showTooltip && (
          <StyledTooltip>{markerOptions.name}</StyledTooltip>
        )}
      </Marker>
    </>
  );
};
