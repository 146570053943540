import React from 'react';
import { Divider } from 'antd';

/** Custom imports */
import { Conditions } from 'components/elements/Conditions';
import { EventsTable } from 'components/elements/EventsTable';
import { Content, Footer } from '../../../styles/layout';

export const Alarms = () => {
  return (
    <>
      <Content>
        <Conditions />
        <Divider />
        <EventsTable />
      </Content>
      <Footer>©{new Date().getFullYear()} Skymaps s.r.o.</Footer>
    </>
  );
};
