import styled from 'styled-components/macro';
import { breakpoints } from './breakpoints';
import { Typography, Select } from 'antd';

const { Text } = Typography;

export const SelectLabel = styled(Text)`
  margin: 0 16px 0 0;
`;

export const StyledSelect = styled(Select)`
  width: 100px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
`;
