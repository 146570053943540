import React, { useState } from 'react';
import { Row, Col } from 'antd';
/** Custom imports */
import { SettingsForm } from 'components/elements/SettingsForm';

export const SettingsCard = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={24} style={{ margin: '0 auto', paddingTop: '12px' }}>
          <div style={{ maxWidth: '900px', margin: '0 auto' }}>
            <SettingsForm></SettingsForm>
          </div>
        </Col>
      </Row>
    </>
  );
};
