import React from 'react';
import { Trans } from '@lingui/macro';

/** Custom Imports */
import { MucnatkaReport } from 'components/elements/MucnatkaReport';
import { MucnatkaChart } from 'components/elements/MucnatkaChart';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_MUCNATKA } from '../../../graphql/queries';
import { Loader } from '..';
import { activeStationVar } from '../../../utils/cache';
import _get from 'lodash/get';
import _first from 'lodash/first';
import { MucnatkaTable } from '../MucnatkaTable';

interface Props {
  selectedYear: number;
}

let currDate = new Date().toISOString().split('T')[0];
export const MucnatkaSection: React.FC<Props> = ({ selectedYear }) => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const { data, loading, error } = useQuery(GET_MUCNATKA, {
    variables: { stationId: activeStation.id, year: selectedYear },
    onCompleted(data: any) {},
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const mucnatkaData = _get(_first(data.mucnatkas), 'mucnatkaData', []);

  let mucnatkaIndex = undefined;

  const currentData: any = mucnatkaData.find((item: any) => {
    return item?.date_day == currDate;
  });

  if (currentData != undefined) {
    mucnatkaIndex = currentData.index;
  }

  return (
    <>
      <MucnatkaReport mucnatkaIndex={mucnatkaIndex} />
      <MucnatkaChart data={mucnatkaData} />
      <MucnatkaTable data={mucnatkaData} />
    </>
  );
};
