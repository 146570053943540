import styled from 'styled-components';
import { Menu } from 'antd';
import { breakpoints } from 'styles/breakpoints';
//@ts-ignore
import { CSVLink } from 'react-csv';

export const StyledMenuItem = styled(Menu.Item)`
  padding: 6px 20px;
`;

export const StyledCSVLink = styled(CSVLink)`
  padding: 0 0 0 10px;
`;
