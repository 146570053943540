import React from 'react';
import { Typography, Form, Input } from 'antd';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';

/*Custom imports*/
import { StyledDivider, FormSection, FormItemWrapper } from 'styles/settings';

const { Title } = Typography;

interface Props {
  activeStation: any;
}

export const SettingsStationInfo: React.FC<Props> = ({ activeStation }) => {
  const devices = activeStation.devices;
  const serialNumbers = devices.map((device: any, index: number) => {
    return (
      <div key={index}>
        {/*
        Hack for Farna station, replace with line below to remove hack
        {device.device_type.name}: {device.sn}
         */}
        {device.device_type.name}:{' '}
        {device.id === '160' ? '2207SH024' : device.sn}
      </div>
    );
  });

  return (
    <FormSection>
      <Form.Item>
        <Title level={5}>
          <Trans>Weather station</Trans>
        </Title>
        <StyledDivider />
      </Form.Item>
      <FormItemWrapper>
        <Form.Item
          key={1}
          label={<Trans>Weather station name</Trans>}
          name="name"
          rules={[
            {
              required: true,
              message: <Trans>Enter weather station name!</Trans>,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item key={2} label={<Trans>Sensors</Trans>}>
          {serialNumbers}
        </Form.Item>
      </FormItemWrapper>
    </FormSection>
  );
};
