import React from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { useLingui } from '@lingui/react';
import { dynamicActivate } from 'utils/i18n';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  vertical-align: middle;
  display: flex;
  text-transform: uppercase;
  padding: 0;
  span {
    padding-top: 3px;
    padding-right: 20px;
  }
  svg {
    width: 25px;
    height: 25px;
    margin: 0 0 0 10px;
  }
`;

export const LocaleToggle = () => {
  const { i18n } = useLingui();

  const menu = (
    <Menu>
      <Menu.Item key="sk">
        <Button type="link" onClick={() => dynamicActivate('sk')}>
          SK
        </Button>
      </Menu.Item>
      <Menu.Item key="en">
        <Button type="link" onClick={() => dynamicActivate('en')}>
          EN
        </Button>
      </Menu.Item>
      {/*<Menu.Item key="da">*/}
      {/*  <Button type="link" onClick={() => dynamicActivate('da')}>*/}
      {/*    DA*/}
      {/*  </Button>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <StyledButton className="ant-dropdown-link" type="text">
          {i18n.locale}
        </StyledButton>
      </Dropdown>
    </>
  );
};
