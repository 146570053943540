//@ts-nocheck
import React, { useMemo, useRef } from 'react';
import { Form, Row, Col, Typography, InputNumber } from 'antd';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import _round from 'lodash/round';
import { TileLayer, useMap } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

/*Custom imports*/
import { StyledDivider, FormSection, FormItemWrapper } from 'styles/settings';
import { StyledFormItem, StyledMapContainer } from './style';
import 'styles/arrow.css';
import { MapMarker, MarkerOptions } from '../MapMarker';
import { Trans, t } from '@lingui/macro';

const { Title } = Typography;
const { Text } = Typography;

interface Props {
  activeStation: any;
  mapPosition: any;
  setMapPosition: any;
  form: any;
}

export const SettingsMap: React.FC<Props> = ({
  activeStation,
  mapPosition,
  setMapPosition,
  form,
}) => {
  const markerRef: React.RefObject<L.Marker> = useRef<L.Marker>(null);
  const name: string = activeStation.name;
  const mPosition: LatLngExpression = [mapPosition.lat, mapPosition.lng];

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setMapPosition(marker.getLatLng());
          let nmarker = marker.getLatLng();

          form.setFieldsValue({
            lat: _round(nmarker.lat, 5),
            lng: _round(nmarker.lng, 5),
          });
        }
      },
    }),
    [],
  );

  const ChangeView = ({ center }: any) => {
    const map = useMap();
    map.panTo(center);
    return null;
  };

  const markerOptions: MarkerOptions = {
    name: name,
    position: mPosition,
    eventHandlers: eventHandlers,
    draggable: true,
    ref: markerRef,
  };

  return (
    <FormSection>
      <Form.Item>
        <Title level={5}>
          <Trans>Location settings</Trans>
        </Title>
        <StyledDivider />
      </Form.Item>

      <FormItemWrapper>
        <Form.Item>
          <Text>
            <Trans>
              You can change the station's location by dragging the pin on the
              map or by entering coordinates.
            </Trans>
          </Text>
        </Form.Item>
        <Form.Item>
          <StyledMapContainer
            center={mPosition}
            zoom={16}
            scrollWheelZoom={true}
          >
            <ChangeView center={mPosition} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              style={{ width: '100%' }}
            />
            <MapMarker markerOptions={markerOptions} />
          </StyledMapContainer>
        </Form.Item>

        <StyledFormItem>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={<Trans>Latitude</Trans>}
                name="lat"
                rules={[{ required: true, message: t`Add latitude!` }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={-90}
                  max={90}
                  step={1}
                  stringMode
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<Trans>Longitude</Trans>}
                name="lng"
                rules={[{ required: true, message: t`Add longitude!` }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={-90}
                  max={90}
                  step={1}
                  stringMode
                />
              </Form.Item>
            </Col>
          </Row>
        </StyledFormItem>
      </FormItemWrapper>
    </FormSection>
  );
};
