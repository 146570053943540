/* eslint-disable @typescript-eslint/no-unused-vars */
import fill from 'lodash/fill';
import round from 'lodash/round';
import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
export const getWindChartOptions = (chartData = [], options = {}): any => {
  let label = fill(Array(16), '');
  label[0] = t`N`;
  label[2] = t`NE`;
  label[4] = t`E`;
  label[6] = t`SE`;
  label[8] = t`S`;
  label[10] = t`SW`;
  label[12] = t`W`;
  label[14] = t`NW`;

  const getData = () => {
    let newData = fill(Array(16), '');
    //@ts-ignore
    let index = round((chartData[0]?.dir_ave10 / 3.6) * 0.16);
    //@ts-ignore
    newData[index] = chartData[0]?.wind_ave10;

    return newData;
  };

  const windChartOptions = {
    color: ['#74fc47'],
    angleAxis: {
      type: 'category',
      data: label,
      boundaryGap: false,
    },
    radiusAxis: {
      axisLabel: {
        verticalAlign: 'bottom',
        margin: 4,
      },
    },
    polar: {},
    series: [
      {
        type: 'bar',
        data: getData(),
        coordinateSystem: 'polar',
        name: 'A',
        stack: 'a',
      },
    ],
    legend: {
      show: false,
      data: ['m/s'],
    },
    tooltip: {
      show: true,
      formatter: `${t`Wind speed`}: {c} m/s`,
    },
    label: {
      position: 'top',
    },
  };

  return windChartOptions;
};
