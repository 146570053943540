import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Trans, t } from '@lingui/macro';

/** Custom imports */
import { FORGOT_PASSWORD } from 'graphql/mutations';
import {
  Wrapper,
  StyledLabel,
  StyledInput,
  StyledButton,
  StyledAlert,
} from './style';

export const RecoverMailForm = () => {
  const [mailError, setMailError] = useState(false);
  let history = useHistory();
  const [mail, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted({ forgotPassword }) {
      if (forgotPassword.ok) {
        history.push('/recover/code');
      }
    },
    onError(error) {
      handleError(error);
    },
  });

  const handleError = (error: any) => {
    console.error('error :>>', error.message);
    setMailError(true);
  };

  const onFinish = (values: any) => {
    mail({
      variables: {
        email: values.email,
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Form name="reset_password" onFinish={onFinish} layout="vertical">
          {mailError ? (
            <StyledAlert
              message={
                <Trans>The user with the given email does not exist</Trans>
              }
              type="error"
              showIcon
            />
          ) : null}
          <Form.Item
            name="email"
            label={
              <StyledLabel>
                <Trans>Email</Trans>
              </StyledLabel>
            }
            rules={[{ required: true, message: t`Please fill in your email!` }]}
          >
            <StyledInput prefix={<MailOutlined />} placeholder={t`Email`} />
          </Form.Item>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit">
              <Trans>Reset password</Trans>
            </StyledButton>
          </Form.Item>
        </Form>
      </Wrapper>
    </Spin>
  );
};
