import styled from 'styled-components/macro';
import { breakpoints } from './breakpoints';
import { Divider, Typography } from 'antd';

const { Title } = Typography;

export const StyledDivider = styled(Divider)`
  margin: 32px 0px;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 32px;
`;

export const TextBox = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
`;

export const TextBoxInner = styled.div`
  padding: 16px;
  text-align: center;
`;

export const FancyList = styled.ul`
  list-style-type: disc;
  margin: 20px 0 0 0;
`;

export const ListItem = styled.li`
  margin-bottom: 32px;
`;

export const ListItemWrapper = styled.div`
  display: flex;
`;

export const ListContent = styled.div`
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const StandardText = styled.div`
  p {
    margin-bottom: 24px;
  }

  i {
    color: #3690ff;
    font-style: italic;
  }

  b {
    font-weight: bold;
  }

  ul {
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 24px;

    &::before {
      content: '•';
      display: inline-block;
      width: 1em;
    }
  }
`;

export const UpperLetter = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 1rem;
`;

export const A = styled.span`
  color: #ff3d5e;
`;

export const B = styled.span`
  color: #69bd4f;
`;
export const C = styled.span`
  color: #3690ff;
`;

export const ListItemInner = styled.div`
  margin-top: 3px;
`;

export const ListOfDetails = styled.ul`
  margin-top: 8px;
  padding: 0;
  list-style-type: none;
`;

export const DetailItem = styled.li`
  font-size: 10px;
  color: #3690ff;
`;

export const VideoInner = styled.div`
  display: block;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  display: flex;
`;
