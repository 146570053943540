import styled from 'styled-components';
import { TileLayer, MapContainer, Tooltip } from 'react-leaflet';

export const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  min-height: calc(100vh - 64px);
`;

export const StyledTileLayer = styled(TileLayer)`
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  background-color: white;
  font-family: Poppins, sans-serif;
`;
