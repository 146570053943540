import { Table, Typography } from 'antd';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  font-size: 18px !important;
  text-align: center;

  @media ${breakpoints.screen420} {
    font-size: 20px !important;
  }
`;

export const StyledTable = styled(Table)`
  .alarmRed {
    background-color: rgba(255, 77, 79, 0.3);

    &:hover td {
      background-color: rgba(255, 77, 79, 0) !important;
    }
  }
`;
