import React from 'react';
import { Trans } from '@lingui/macro';
import video6_peronospora from '../../../assets/videos/video_6_peronospora.mp4';
import video6_peronospora2 from '../../../assets/videos/video_6_peronospora2.mp4';
import video6_mucnatka from '../../../assets/videos/video_6_mucnatka.mp4';
import video6_botrytida from '../../../assets/videos/video_6_botrytida.mp4';

/*Styles*/
import { Row, Col } from 'antd';
import {
  Container,
  TextBox,
  TextBoxInner,
  VideoInner,
  Video,
  FancyList,
  ListItem,
  ListContent,
  UpperLetter,
  A,
  B,
  C,
  ListOfDetails,
  DetailItem,
  ListItemInner,
  ListItemWrapper,
  StyledDivider,
  StandardText,
  StyledTitle,
} from '../../../styles/tutorials';

export const TutorialInfection = () => {
  return (
    <>
      <TextBox style={{ marginTop: '32px' }}>
        <TextBoxInner>
          <Container>
            <StandardText>
              <p>
                <Trans>
                  Evaluation of the infection risk is based on calculating the
                  disease pressure index and comparing it with its theoretical
                  model. If the current date falls within the monitored period,
                  the value of the disease pressure index and recommendations
                  for interventions based on the model will be displayed.
                </Trans>
              </p>
              <p>
                <b>
                  <Trans>
                    Tracked diseases and their models for predicting disease
                    pressure:
                  </Trans>
                </b>
              </p>
            </StandardText>
          </Container>
        </TextBoxInner>
      </TextBox>
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Downy mildew</Trans>
            </StyledTitle>
            <StandardText>
              <Trans>
                For evaluating disease pressure, the Šteberly's model is
                utilized. It evaluates the total rainfall each day from the
                beginning of May (1st May). The methodology is valid from 15th
                May until the end of August. The current value is compared to
                the tabulated values for the specific day. The graph displays{' '}
                <b>3 curves:</b>
              </Trans>
            </StandardText>
            <FancyList>
              <ListItem>
                <ListItemWrapper>
                  <UpperLetter>
                    <A>A</A>
                  </UpperLetter>
                  <ListItemInner>
                    <ListContent>
                      <StandardText>
                        <p>
                          <Trans>
                            Above the prognostic curve A -{' '}
                            <b>calamitous occurrence</b>
                          </Trans>
                        </p>
                      </StandardText>
                      <ListOfDetails>
                        <DetailItem>
                          <Trans>
                            Regular treatment at intervals of 5-14 days
                            (depending on the level of disease pressure, growth
                            intensity, and the type of preparation) until the
                            berries soften.
                          </Trans>
                        </DetailItem>
                      </ListOfDetails>
                    </ListContent>
                    <ListContent>
                      <StandardText>
                        <p>
                          <Trans>
                            Between curves A and B -{' '}
                            <b>sporadic-calamitous occurrence</b>
                          </Trans>
                        </p>
                      </StandardText>
                      <ListOfDetails>
                        <DetailItem>
                          <Trans>
                            - During the period 2 weeks before flowering, one
                            treatment before flowering and two treatments after
                            blooming are indicated at intervals of 10-14 days.
                          </Trans>
                        </DetailItem>
                        <DetailItem>
                          <Trans>
                            - During flowering and shortly after blooming, three
                            treatments after blooming are indicated at intervals
                            of approximately 10-14 days
                          </Trans>
                        </DetailItem>
                      </ListOfDetails>
                    </ListContent>
                  </ListItemInner>
                </ListItemWrapper>
              </ListItem>
              <ListItem>
                <ListItemWrapper>
                  <UpperLetter>
                    <B>B</B>
                  </UpperLetter>
                  <ListItemInner>
                    <ListContent>
                      <StandardText>
                        <Trans>
                          Under curve B - <b>non-calamitous occurrence</b>
                        </Trans>
                      </StandardText>
                      <ListOfDetails>
                        <DetailItem>
                          <Trans>
                            - treatment after flowering and then approximately
                            10-14 days later
                          </Trans>
                        </DetailItem>
                      </ListOfDetails>
                    </ListContent>
                  </ListItemInner>
                </ListItemWrapper>
              </ListItem>
              <ListItem>
                <ListItemWrapper>
                  <UpperLetter>
                    <C>C</C>
                  </UpperLetter>
                  <ListItemInner>
                    <ListContent>
                      <StandardText>
                        <b>
                          <Trans>Current state</Trans>
                        </b>
                      </StandardText>
                    </ListContent>
                  </ListItemInner>
                </ListItemWrapper>
              </ListItem>
            </FancyList>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video6_peronospora} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <StyledDivider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Downy mildew 2</Trans>
            </StyledTitle>
            <StandardText>
              <p>
                <Trans>
                  The description of the method is provided at the top of the
                  page (Goidanich model), where we monitor the fulfillment of
                  the one-day and two-day infection conditions.
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video6_peronospora2} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <StyledDivider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Powdery mildew</Trans>
            </StyledTitle>
            <StandardText>
              <p>
                <Trans>
                  For calculating the disease pressure index, the Kasta method
                  model is utilized. The graph displays the measured value of
                  the disease pressure index along with two boundary curves
                  indicating the boundary between weak, moderately strong, and
                  strong disease pressure.
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video6_mucnatka} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <StyledDivider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Botrytis</Trans>
            </StyledTitle>
            <StandardText>
              <p>
                <Trans>
                  Using the Broom model, we evaluate the measured disease
                  pressure. The result is an assessment of the risk.
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video6_botrytida} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
