/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'containers/App';
import * as serviceWorker from 'serviceWorker';

/** import css */
import 'antd/dist/antd.css';
import 'assets/css/styles.css';

interface Props {
  Comp: typeof App;
}

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const ConnectedApp = ({ Comp }: Props) => <Comp />;

const render = (Comp: typeof App) => {
  ReactDOM.render(<ConnectedApp Comp={Comp} />, MOUNT_NODE);
};

if (module.hot) {
  module.hot.accept(['./containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./containers/App').App;
    render(App);
  });
}

render(App);

/**
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
