//@ts-nocheck
import React from 'react';
import { Modal, Form, Input, Button, Select, InputNumber } from 'antd';
import { Trans } from '@lingui/macro';
import { useQuery, useMutation } from '@apollo/client';
import _get from 'lodash/get';

/** Custom imports */
import { ConditionMethods } from 'components/elements/ConditionMethods';
import { ConditionStations } from 'components/elements/ConditionStations';
import { GET_LOGGED_IN_USER, GET_CONDITIONS_BY_USER } from 'graphql/queries';
import { MUTATION_CREATE_CONDITION } from 'graphql/mutations';

import { StyledFormItem } from './style';

const { Option } = Select;

const units = [
  { name: <Trans>Temperature [°C]</Trans> },
  { name: <Trans>Pressure [hPa]</Trans> },
  { name: <Trans>Humidity [%]</Trans> },
  { name: <Trans>Total precipitation [mm]</Trans> },
];

const operators = [{ name: '>' }, { name: '<' }];

const periods = [
  { name: <Trans>One-time</Trans>, key: 0 },
  { name: 1, key: 1 },
  { name: 4, key: 4 },
  { name: 24, key: 24 },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

interface Props {
  conditionModal: boolean;
  setConditionModal: any;
  setIsModalVisible: any;
}

export const ConditionModal: React.FC<Props> = ({
  conditionModal,
  setConditionModal,
  setIsModalVisible,
}) => {
  const [form] = Form.useForm();
  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const email = _get(user, 'me.email');
  const userId = _get(user, 'me.id');

  const [createAlarm, { error }] = useMutation(MUTATION_CREATE_CONDITION, {
    refetchQueries: [{ query: GET_CONDITIONS_BY_USER, variables: { email } }],
  });

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const onFinish = (values: any) => {
    const { name, unit, operator, value, period, stations, method } = values;
    createAlarm({
      variables: {
        input: {
          data: {
            name: name,
            unit: unit,
            operator: operator,
            value: value,
            period: period,
            method: method,
            stations: stations,
            owner: userId,
          },
        },
      },
    });
    if (error) {
      console.error(error);
    }

    form.resetFields();
    setConditionModal(false);
    setIsModalVisible(false);
  };

  const getOptions = (items) => {
    return items.map((item) => {
      return (
        <Option value={item.name} key={item.name}>
          {item.name}
        </Option>
      );
    });
  };

  const getPeriods = (items) => {
    return items.map((item) => {
      return (
        <Option value={item.key} key={item.key}>
          {item.name}
        </Option>
      );
    });
  };

  return (
    <>
      <Modal
        title={<Trans>Add condition</Trans>}
        style={{ top: 20 }}
        visible={conditionModal}
        onOk={() => onFinish()}
        onCancel={() => setConditionModal(false)}
        footer={null}
      >
        <Form
          form={form}
          {...layout}
          name="basic"
          preserve={false}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label={<Trans>Name</Trans>}
            name="name"
            rules={[
              { required: true, message: <Trans>Add alarm name!</Trans> },
            ]}
          >
            <Input />
          </Form.Item>

          <ConditionStations />

          <Form.Item
            label={<Trans>Unit</Trans>}
            name="unit"
            rules={[{ required: true, message: <Trans>Select units!</Trans> }]}
          >
            <Select>{getOptions(units)}</Select>
          </Form.Item>

          <Form.Item
            label={<Trans>Operator</Trans>}
            name="operator"
            rules={[
              { required: true, message: <Trans>Select operator!</Trans> },
            ]}
          >
            <Select>{getOptions(operators)}</Select>
          </Form.Item>

          <Form.Item
            {...layout}
            step={0.1}
            label={<Trans>Value</Trans>}
            name="value"
            rules={[{ required: true, message: <Trans>Add value</Trans> }]}
          >
            <InputNumber {...layout} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            {...layout}
            label={<Trans>Period [h]</Trans>}
            name="period"
            rules={[
              {
                required: true,
                message: <Trans>Select time period!</Trans>,
              },
            ]}
          >
            <Select>{getPeriods(periods)}</Select>
          </Form.Item>

          <ConditionMethods />

          <StyledFormItem {...tailLayout}>
            <Button type="primary" htmlType="submit">
              <Trans>Add</Trans>
            </Button>
          </StyledFormItem>
        </Form>
      </Modal>
    </>
  );
};
