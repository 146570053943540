//@ts-nocheck
import React, { useState } from 'react';
import { Modal, Form, Button, Select } from 'antd';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';

const { Option } = Select;

const stations = [
  { name: <Trans>station1</Trans>, SN: '01' },
  { name: <Trans>station2</Trans>, SN: '02' },
  { name: <Trans>station2</Trans>, SN: '03' },
];

const StyledButton = styled(Button)`
  width: 30%;
  margin: 40px auto 0 auto;
`;

const handleChange = (value: any) => {};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
export const FavoriteStationForm = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const onFinish = (values: any) => {
    setModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getOptions = (items: any) => {
    return items.map((item: any) => {
      return (
        <Option value={item.SN} key={item.SN}>
          {item.name}
        </Option>
      );
    });
  };

  return (
    <>
      <StyledButton onClick={() => setModalVisible(true)}>
        <Trans>Add +</Trans>
      </StyledButton>
      <Modal
        title="New Favorite"
        style={{ top: 20 }}
        visible={modalVisible}
        onOk={() => onFinish()}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          {...layout}
          name="favorite"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={<Trans>Please select station!</Trans>}
            name="favorite"
            rules={[
              {
                required: true,
                message: <Trans>Please select station!</Trans>,
              },
            ]}
          >
            <Select
              onChange={handleChange}
              mode="single"
              showSearch
              placeholder={t`Please select station!`}
            >
              {getOptions(stations)}
            </Select>
          </Form.Item>

          <Form.Item
            {...tailLayout}
            style={{ textAlign: 'end', padding: '20px 0 0 0' }}
          >
            <Button type="primary" htmlType="submit">
              <Trans>Submit</Trans>
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
