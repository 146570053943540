import React, { useState } from 'react';
import { Select, Typography } from 'antd';
import { Trans } from '@lingui/macro';

/** Custom Imports */
import { InfectionsTable } from 'components/elements/InfectionsTable';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_INFECTION_YEARS,
  GET_LOGGED_IN_USER,
  GET_INFECTION_TABLE,
} from 'graphql/queries';
import { Loader } from '..';
import { activeStationVar } from 'utils/cache';
import _get from 'lodash/get';
import _first from 'lodash/first';
import { SelectLabel } from '../../../styles/infections';

const { Option } = Select;

interface Props {}

let currYear = new Date().getFullYear();

export const InfectionsMain: React.FC<Props> = () => {
  const [selectedYear, setSelectedYear] = useState(currYear);
  const activeStation: any = useReactiveVar(activeStationVar);

  const { data: user } = useQuery(GET_LOGGED_IN_USER);
  const userId = _get(user, 'me.id');
  const { data: years, loading: yearsLoading, error: yearsError } = useQuery(
    GET_INFECTION_YEARS,
    {
      variables: { stationId: activeStation.id, userId: userId },
    },
  );

  const { data, loading, error } = useQuery(GET_INFECTION_TABLE, {
    variables: {
      stationId: activeStation.id,
      userId: userId,
      year: selectedYear,
    },
  });

  if (loading || yearsLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const changeYear = (value: any) => {
    const yearNum = parseInt(value);
    setSelectedYear(yearNum);
  };

  const infectionTableData = _get(
    _first(data.infectionTables),
    'infectionTableData',
    [],
  );

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div style={{ paddingBottom: '32px' }}>
        <SelectLabel>
          <Trans>Select the year</Trans>
        </SelectLabel>
        <Select
          defaultValue={currentYear}
          style={{ width: '120px' }}
          onChange={changeYear}
        >
          {years.infectionTables.map((infections: any) => (
            <Option key={infections.year} value={infections.year}>
              {infections.year}
            </Option>
          ))}
        </Select>
      </div>
      <InfectionsTable
        selectedYear={selectedYear}
        user={user}
        data={infectionTableData}
      />
    </>
  );
};
