import styled from 'styled-components/macro';
import { breakpoints } from '../../../styles/breakpoints';

export const ChartsWrapper = styled.div`
  padding: 0px;

  @media ${breakpoints.screen420} {
    padding: 16px;
  }
`;
