import * as React from 'react';
import ReactEcharts from 'echarts-for-react';

/** Custom imports */
import { getWindChartOptions } from './WindChartOptions';

export const WindChart = ({ data }: any) => {
  const onChartReady = () => {};

  const handleClickEvent = (e: any) => {};

  const mockWindData = [
    {
      wind_ave10: '0',
      dir_ave10: 0,
    },
  ];

  let chartData = data.length > 0 ? data : mockWindData;

  const option = getWindChartOptions(chartData);

  return (
    <>
      <ReactEcharts
        option={option}
        onChartReady={onChartReady}
        onEvents={{ click: handleClickEvent }}
        style={{
          height: '200px',
        }}
      />
    </>
  );
};
