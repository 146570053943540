import React, { useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

/** Custom imports */
import { getAllSensorsChartOptions } from './AllSensorsChartOptions';
import { useReactiveVar } from '@apollo/client';
import { activeStationVar } from '../../../utils/cache';
/**Data types */
import { DevicesAvailable } from 'components/elements/StationTabs';

interface Props {
  data: any;
  windsData: any;
  agroData: any;
  sensorDepths: Array<string>;
  devicesAvailable: DevicesAvailable;
}

export const AllSensorsChart: React.FC<Props> = ({
  data,
  windsData,
  agroData,
  sensorDepths,
  devicesAvailable,
}) => {
  const activeStation: any = useReactiveVar(activeStationVar);
  const eChartsRef = useRef(null as any);

  if (data.length === 0 && windsData.length === 0 && agroData.length === 0) {
    return null;
  }

  const option = getAllSensorsChartOptions(
    data,
    windsData,
    agroData,
    activeStation,
    sensorDepths,
    devicesAvailable,
  );

  if (eChartsRef && eChartsRef.current) {
    eChartsRef.current?.getEchartsInstance().on('legendselectchanged');
  }

  return (
    <>
      <ReactEcharts
        ref={eChartsRef}
        option={option}
        notMerge={false}
        lazyUpdate={true}
        style={{
          height: '500px',
          width: '100%',
        }}
      />
    </>
  );
};
