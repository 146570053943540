import React from 'react';
import ReactEcharts from 'echarts-for-react';

/** Custom imports */
import { getMucnatkaChartOptions } from './MucnatkaChartOptions';

export const MucnatkaChart = ({ data }: any) => {
  const option = getMucnatkaChartOptions(data);

  const onChartReady = () => {};

  const handleClickEvent = (e: any) => {};
  return (
    <>
      <ReactEcharts
        option={option}
        notMerge={false}
        lazyUpdate={true}
        // theme={'theme_name'}
        onChartReady={onChartReady}
        onEvents={{ click: handleClickEvent }}
        style={{
          height: '400px',
          width: '100%',
        }}
      />
    </>
  );
};
