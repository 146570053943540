import _round from 'lodash/round';
import { EChartOption } from 'echarts';

/**Custom imports */
import { getHumidityChartOptions } from './options/HumidityChartOptions';
import { getPressureChartOptions } from './options/PressureChartOptions';
import { getIrradiationChartOptions } from './options/IrradiationChartOptions';
import { getBatteryChartOptions } from './options/BatteryChartOptions';
import { getRainChartOptions } from './options/RainChartOptions';
import { getCumulativeRainChartOptions } from './options/CumlulativeRainChartOptions';
import { getWindChartOptions } from './options/WindChartOptions';
import { getTemperatureChartOptions } from './options/TemperatureChartOptions';
import { getPlantChartOptions } from './options/PlantChartOptions';
import { getPlantTempChartOptions } from './options/PlantTempChartOptions';
import { getSoilTempChartOptions } from './options/SoilTempChartOptions';
import { getSoilMoistureChartOptions } from './options/SoilMoistureChartOptions';
import { t } from '@lingui/macro';

export interface PopoverOptions {
  id: number;
  name: string;
  quantity: string;
  color: string;
  value: number;
  displayValue: any;
  units: string;
  options: EChartOption;
  hardware: boolean;
  settings: boolean | string;
}

export const getPopoverOptions = (
  tsHelixes: any,
  tsWindsJson: any,
  tsAgroesJson: any,
  devicesAvailable: any,
  sensorDepths: Array<string>,
): any => {
  const calcCumulativeRain = (data: any) => {
    let total = 0;
    data.map((item: any) => {
      total = total + item.rain;
    });
    return total;
  };

  let sensorPlantTemp = false;
  if (
    tsAgroesJson[0]?.plant_canopy_temperature !== null &&
    tsAgroesJson[0]?.plant_canopy_temperature !== undefined
  ) {
    sensorPlantTemp = true;
  }

  const popoversData: Array<PopoverOptions> = [
    {
      id: 1,
      name: t`Temperature`,
      quantity: 'temperature',
      color: '#f86c6b',
      value: tsHelixes[0]?.temperature,
      displayValue: tsHelixes[0]?.temperature,
      units: '°C',
      options: getTemperatureChartOptions(tsHelixes, '#f86c6b'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 2,
      name: t`Pressure`,
      quantity: 'pressure',
      color: '#6f42c1',
      value: tsHelixes[0]?.pressure ? tsHelixes[0]?.pressure / 100 : null,
      displayValue: tsHelixes[0]?.pressure
        ? tsHelixes[0]?.pressure / 100
        : null,
      units: 'hPa',
      options: getPressureChartOptions(tsHelixes, '#6f42c1'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 3,
      name: t`Humidity`,
      quantity: 'humidity',
      color: '#20a8d8',
      value: tsHelixes[0]?.humidity,
      displayValue: tsHelixes[0]?.humidity,
      units: '%',
      options: getHumidityChartOptions(tsHelixes, '#20a8d8'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 4,
      name: t`Irradiation`,
      quantity: 'irradiation',
      color: '#ffc107',
      value: tsHelixes[0]?.irradiation,
      displayValue: tsHelixes[0]?.irradiation,
      units: 'W/m2',
      options: getIrradiationChartOptions(tsHelixes, '#ffc107'),
      hardware: devicesAvailable.helix,
      settings: true,
    },

    {
      id: 5,
      name: t`Battery voltage`,
      quantity: 'battery',
      color: '#20c997',
      value: tsHelixes[0]?.battery,
      displayValue: tsHelixes[0]?.battery,
      units: 'V',
      options: getBatteryChartOptions(tsHelixes, '#20c997'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 6,
      name: t`Precipitation in the last 10 minutes`,
      quantity: 'rain',
      color: '#0f75f1',
      value: tsHelixes[0]?.rain,
      displayValue: tsHelixes[0]?.rain,
      units: 'mm',
      options: getRainChartOptions(tsHelixes, '#0f75f1'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 7,
      name: t`Cumulative precipitation in the last 24 hours`,
      quantity: 'cumulativeRain',
      color: '#e83e8c',
      value: tsHelixes[0]?.rain,
      displayValue: calcCumulativeRain(tsHelixes),
      units: 'mm',
      options: getCumulativeRainChartOptions(tsHelixes, '#e83e8c'),
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      id: 8,
      name: t`Wind speed`,
      quantity: 'wind',
      color: '#74fc47',
      value: tsWindsJson[0]?.wind_ave10,
      displayValue: tsWindsJson[0]?.wind_ave10,
      units: 'm/s',
      options: getWindChartOptions(tsWindsJson),
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      id: 9,
      name: t`Maximum wind gust in the last 10 minutes`,
      quantity: 'wind',
      color: '#74fc47',
      value: tsWindsJson[0]?.wind_max10,
      displayValue: tsWindsJson[0]?.wind_max10,
      units: 'm/s',
      options: getWindChartOptions(tsWindsJson, { max: true }),
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      id: 10,
      name: t`Leaf humidity`,
      quantity: 'plant',
      color: '#20a8d8',
      value: tsAgroesJson[0]?.plant_canopy_sensor,
      displayValue:
        tsAgroesJson[0]?.plant_canopy_sensor > 330 ? t`Wet` : t`Dry`,
      units: '',
      options: getPlantChartOptions(tsAgroesJson, '#20a8d8'),
      hardware: devicesAvailable.agro,
      settings: true,
    },
    {
      id: 11,
      name: t`Leaf temperature`,
      quantity: 'plantTemperature',
      color: '#ff7700',
      value: tsAgroesJson[0]?.plant_canopy_temperature,
      displayValue: tsAgroesJson[0]?.plant_canopy_temperature,
      units: '°C',
      options: getPlantTempChartOptions(tsAgroesJson, '#ff7700'),
      hardware: devicesAvailable.agro,
      settings: sensorPlantTemp,
    },
    {
      id: 12,
      name: t`Soil temperature at depth ${sensorDepths[0]}`,
      quantity: 'soil_temp1',
      color: '#f86c6b',
      value: tsAgroesJson[0]?.soil_temp1,
      displayValue: tsAgroesJson[0]?.soil_temp1,
      units: '°C',
      options: getSoilTempChartOptions(
        tsAgroesJson,
        '#f86c6b',
        'soil_temp1',
        sensorDepths[0],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[0],
    },

    {
      id: 13,
      name: t`Soil temperature at depth ${sensorDepths[1]}`,
      quantity: 'soil_temp2',
      color: '#6f42c1',
      value: tsAgroesJson[0]?.soil_temp2,
      displayValue: tsAgroesJson[0]?.soil_temp2,
      units: '°C',
      options: getSoilTempChartOptions(
        tsAgroesJson,
        '#6f42c1',
        'soil_temp2',
        sensorDepths[1],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[1],
    },

    {
      id: 14,
      name: t`Soil temperature at depth ${sensorDepths[2]}`,
      quantity: 'soil_temp3',
      color: '#20a8d8',
      value: tsAgroesJson[0]?.soil_temp3,
      displayValue: tsAgroesJson[0]?.soil_temp3,
      units: '°C',
      options: getSoilTempChartOptions(
        tsAgroesJson,
        '#20a8d8',
        'soil_temp3',
        sensorDepths[2],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[2],
    },
    {
      id: 15,
      name: t`Soil moisture at depth ${sensorDepths[0]}`,
      quantity: 'soil_moisture1',
      color: '#e83e8c',
      value: tsAgroesJson[0]?.soil_moisture1,
      displayValue: tsAgroesJson[0]?.soil_moisture1,
      units: 'kPa',
      options: getSoilMoistureChartOptions(
        tsAgroesJson,
        'soil_moisture1',
        sensorDepths[0],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[0],
    },

    {
      id: 16,
      name: t`Soil moisture at depth ${sensorDepths[1]}`,
      quantity: 'soil_moisture2',
      color: '#ffc107',
      value: tsAgroesJson[0]?.soil_moisture2,
      displayValue: tsAgroesJson[0]?.soil_moisture2,
      units: 'kPa',
      options: getSoilMoistureChartOptions(
        tsAgroesJson,
        'soil_moisture2',
        sensorDepths[1],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[1],
    },

    {
      id: 17,
      name: t`Soil moisture at depth ${sensorDepths[2]}`,
      quantity: 'soil_moisture3',
      color: '#20c997',
      value: tsAgroesJson[0]?.soil_moisture3,
      displayValue: tsAgroesJson[0]?.soil_moisture3,
      units: 'kPa',
      options: getSoilMoistureChartOptions(
        tsAgroesJson,
        'soil_moisture3',
        sensorDepths[2],
      ),
      hardware: devicesAvailable.agro,
      settings: sensorDepths[2],
    },
  ];

  const checkValues = (data: Array<PopoverOptions>) => {
    const filteredValues: Array<PopoverOptions> = data.filter(
      (item: PopoverOptions) => {
        if (item.hardware && item.settings) {
          return true;
        }
      },
    );

    const checkedValues: Array<PopoverOptions> = filteredValues.map(
      (item: PopoverOptions) => {
        if (item.value == null || isNaN(item.value)) {
          item.displayValue = '-';
          item.units = '';
        } else {
          if (typeof item.displayValue === 'number') {
            item.displayValue = _round(item.displayValue, 2);
          }
        }
        return item;
      },
    );

    return checkedValues;
  };

  const filteredData = checkValues(popoversData);

  return filteredData;
};
