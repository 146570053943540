import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Spin } from 'antd';
import { Trans } from '@lingui/macro';

/**Custom imports */
import { AllSensorsChart } from 'components/elements/AllSensorsChart';
import { StatisticsTable } from 'components/elements/StatisticsTable';
import { useLazyQuery } from '@apollo/client';
import { FilterButtons } from 'components/elements/FilterButtons';
import { GET_HELIX_DATA_BY_DEVICE_ID } from 'graphql/queries';
/**Data types */
import { DevicesAvailable } from 'components/elements/StationTabs';
/**Styles */
import { ChartButtons } from './style';

interface Props {
  userId: number;
  devicesAvailable: DevicesAvailable;
  tsHelixes: any;
  tsWindsJson: any;
  tsAgroesJson: any;
  sensorDepths: Array<string>;
  devicesId: any;
}

export const AllSensorsSection: React.FC<Props> = ({
  devicesAvailable,
  tsHelixes,
  tsWindsJson,
  tsAgroesJson,
  sensorDepths,
  devicesId,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filteredWData, setFilteredWData] = useState([]);
  const [filteredAData, setFilteredAData] = useState([]);

  useEffect(() => {
    setFilteredData(tsHelixes);
    setFilteredWData(tsWindsJson);
    setFilteredAData(tsAgroesJson);
  }, []);

  const [
    updateData,
    { loading: onClickLoading, error: onClickError },
  ] = useLazyQuery(GET_HELIX_DATA_BY_DEVICE_ID, {
    onCompleted({ tsHelixFulls, tsWindsJson, tsAgroesJson }: any) {
      setFilteredData(tsHelixFulls);
      setFilteredWData(tsWindsJson);
      setFilteredAData(tsAgroesJson);
    },
  });

  const loadData = (dateFrom: moment.Moment, dateTo: moment.Moment) => {
    updateData({
      variables: {
        deviceID: devicesId.helix,
        windID: devicesId.wind,
        agroID: devicesId.agro,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
  };

  if (onClickError)
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );

  return (
    <div>
      <Spin spinning={onClickLoading}>
        <AllSensorsChart
          data={filteredData}
          windsData={filteredWData}
          agroData={filteredAData}
          sensorDepths={sensorDepths}
          devicesAvailable={devicesAvailable}
        />
        <ChartButtons>
          <FilterButtons loadData={loadData} defaultSelected="c" />
        </ChartButtons>
        <StatisticsTable
          data={filteredData}
          windsData={filteredWData}
          agroData={filteredAData}
          sensorDepths={sensorDepths}
          devicesAvailable={devicesAvailable}
        />
      </Spin>
    </div>
  );
};
