//@ts-nocheck
import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

/** Custom imports */
import { Peronospora } from 'components/elements/Peronospora';
import { Mucnatka } from 'components/elements/Mucnatka';
import { VineMold } from 'components/elements/VineMold';
import { Botrytis } from '../Botrytis';
import { Trans } from '@lingui/macro';

const { TabPane } = Tabs;

const StyledTabPane = styled.div`
  min-width: 152px;
  text-align: center;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    border-radius: 2.5px;
  }
  .ant-tabs-nav {
    margin: 10px 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    z-index: 5;
  }
`;

export const Infections = () => {
  return (
    <>
      <StyledTabs defaultActiveKey="1" centered animated={true}>
        <TabPane
          tab={
            <StyledTabPane>
              <Trans>Downy mildew - Šteberly</Trans>
            </StyledTabPane>
          }
          key="peronospora-steberly"
        >
          <Peronospora />
        </TabPane>
        <TabPane
          tab={
            <StyledTabPane>
              <Trans>Downy mildew - Goidanich</Trans>
            </StyledTabPane>
          }
          key="peronospora-goidanich"
        >
          <VineMold />
        </TabPane>
        <TabPane
          tab={
            <StyledTabPane>
              <Trans>Powdery mildew</Trans>
            </StyledTabPane>
          }
          key="mucnatka"
        >
          <Mucnatka />
        </TabPane>
        <TabPane
          tab={
            <StyledTabPane>
              <Trans>Botrytis</Trans>
            </StyledTabPane>
          }
          key="botrytida"
        >
          <Botrytis />
        </TabPane>
      </StyledTabs>
    </>
  );
};
