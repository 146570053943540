import React, { useState } from 'react';
import { Select, Typography } from 'antd';
import { VineMoldSection } from 'components/elements/VineMoldSection';
import { Trans } from '@lingui/macro';

/** Custom Imports */
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PLESEN_YEARS } from 'graphql/queries';
import { Loader } from '..';
import { activeStationVar } from 'utils/cache';
import { SelectLabel, SelectWrapper, StyledSelect } from 'styles/infections';

const { Paragraph } = Typography;
const { Option } = Select;

interface Props {}

let currYear = new Date().getFullYear();

export const VineMold: React.FC<Props> = () => {
  const [selectedYear, setSelectedYear] = useState(currYear);
  const activeStation: any = useReactiveVar(activeStationVar);
  const { data, loading, error } = useQuery(GET_PLESEN_YEARS, {
    variables: { stationId: activeStation.id },
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const changeYear = (value: any) => {
    const yearNum = parseInt(value);
    setSelectedYear(yearNum);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <SelectWrapper>
        <SelectLabel>
          <Trans>Select a year</Trans>
        </SelectLabel>
        <StyledSelect defaultValue={currentYear} onChange={changeYear}>
          {data.plesens.map((plesen: any) => (
            <Option key={plesen.year} value={plesen.year}>
              {plesen.year}
            </Option>
          ))}
        </StyledSelect>
      </SelectWrapper>
      <Paragraph strong>
        <Trans>Model by Goidanich</Trans>
      </Paragraph>
      <VineMoldSection selectedYear={selectedYear} />
    </>
  );
};
