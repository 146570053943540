import React, { Fragment } from 'react';

import { Route, Redirect } from 'react-router-dom';

/** Custom imports */
import { User } from 'components/pages/User';
import { RecoverMail } from 'components/pages/RecoverMail';
import { RecoverCode } from 'components/pages/RecoverCode';

export const NotAuthAppRoutes = () => {
  return (
    <Fragment>
      <Route path={process.env.PUBLIC_URL + '/'}>
        <Redirect to="/users" from="/" />
      </Route>
      <Route path="/users" component={User} />
      <Route path="/recover" exact component={RecoverMail} />
      <Route path="/recover/code" component={RecoverCode} />
    </Fragment>
  );
};
