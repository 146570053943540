import styled from 'styled-components';
import { Typography } from 'antd';
import { breakpoints } from '../../../styles/breakpoints';

const { Title } = Typography;

export const StyledTitle = styled(Title)`
  font-size: 18px !important;
  text-align: center;

  @media ${breakpoints.screen420} {
    font-size: 20px !important;
  }
`;
