//@ts-nocheck
import React from 'react';
import { Table, Row, Col } from 'antd';
import styled from 'styled-components';
import {
  errorCell,
  successCell,
  warningCell,
} from '../TableSettings/TableSettings';

const StyledTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px !important;
  }

  .ant-table.ant-table-small {
    font-size: 12px;
  }
  .ant-table-cell {
    text-align: center !important;
  }

  .ant-alert {
    padding: 0px !important;
  }
`;

const renderIndexCell = (index_val) => {
  if (index_val <= 0) return successCell('Žiadne');
  else if (index_val <= 0.5) return successCell('Nízke');
  else if (index_val <= 1) return warningCell('Stredné');
  else return errorCell('Vysoké');
};

const columns = [
  {
    title: 'Dátum',
    dataIndex: 'date_day',
    render: (item: any) => item.split('T')[0],
    defaultSortOrder: 'descend',
    sorter: (a, b) => Date.parse(a.date_day) - Date.parse(b.date_day),
  },
  {
    title: 'Vlhkostný interval hod.',
    dataIndex: 'wetness_hrs',
    render: (item: any) => (item === null ? 0 : item.toFixed(1)),
  },
  {
    title: 'Priem. teplota počas vlhkostného intervalu °C',
    dataIndex: 'avg_temp',
    render: (item: any) => (item === null ? '-' : item.toFixed(1)),
  },
  {
    title: 'Hodnota indexu',
    dataIndex: 'index_val',
    render: (item: any) =>
      item === null || item < -3 ? '-3.0' : item.toFixed(1),
  },
  {
    title: 'Ohrozenie',
    dataIndex: 'index_val',
    render: renderIndexCell,
  },
];

export const BotrytisTable = (botrytisData: any) => {
  let i = 0;
  return (
    <>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTable
            bordered
            size="small"
            rowKey={() => i++}
            pagination={{ defaultPageSize: 20 }}
            columns={columns}
            dataSource={botrytisData['botrytisData']}
          />
        </Col>
      </Row>
    </>
  );
};
