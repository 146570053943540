import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spin, Row, Col } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';

/** Custom imports */
import { LOGIN_USER } from 'graphql/mutations';
import { isLoggedInVar } from 'utils/cache';

/*Styles*/
import {
  Wrapper,
  StyledLabel,
  StyledInput,
  StyledPassword,
  StyledCheckbox,
  StyledButton,
  StyledRecoveryLink,
  tailFormItemLayout,
  StyledAlert,
  StyledButtonWrapper,
} from './style';

interface Props {}

export const LoginForm: React.FC<Props> = () => {
  const [loginError, setLoginError] = useState({ show: false, text: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [remember, setRemember] = useState('false');
  let history: any = useHistory();

  const [login, { loading }] = useMutation(LOGIN_USER, {
    onCompleted({ login }: any) {
      localStorage.setItem('token', login.jwt as string);
      localStorage.setItem('userId', login.user.id as string);
      localStorage.setItem('remember', remember as string);
      isLoggedInVar(true);
    },
    onError({ graphQLErrors, networkError }) {
      handleError(graphQLErrors, networkError);
    },
  });

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.from == 'recover-page') {
        setShowAlert(true);
      }
    }
  }, []);

  const handleError = (graphQLErrors: any, networkError: any) => {
    if (graphQLErrors) {
      setLoginError({ show: true, text: t`Incorrect email or password` });
    }

    if (networkError) {
      setLoginError({
        show: true,
        text: t`Network error, please try again.`,
      });
    }
  };

  const onFinish = (values: any) => {
    login({
      variables: {
        input: {
          identifier: values.email,
          password: values.password,
        },
      },
    });
  };

  const onCheckboxChange = (e: any) => {
    const parsedResult = JSON.parse(e.target.checked);
    setRemember(parsedResult);
  };

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Form
          name="normal_login"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          layout="vertical"
        >
          {window.location.hostname === 'viti.skymaps.sk' ||
          window.location.hostname === 'www.viti.skymaps.sk' ? (
            <div style={{ textAlign: 'center' }}>
              <div>
                <img src={`/interreg.png`} style={{ width: '100%' }} />
              </div>
              <div>
                <img src={`/zvvs.jpg`} />
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <div>
                <img
                  src={`/skymaps_logo_small.png`}
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                    height: 'auto',
                    marginBottom: '32px',
                  }}
                />
              </div>
            </div>
          )}
          {showAlert ? (
            <StyledAlert
              message={<Trans>Password change successful</Trans>}
              type="success"
              showIcon
              closable
            />
          ) : null}
          {loginError.show ? (
            <StyledAlert message={loginError.text} type="error" showIcon />
          ) : null}
          <Form.Item
            name="email"
            label={
              <StyledLabel>
                <Trans>Email</Trans>
              </StyledLabel>
            }
            rules={[{ required: true, message: t`Please fill in your email!` }]}
          >
            <StyledInput prefix={<MailOutlined />} placeholder={t`Email`} />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <StyledLabel>
                <Trans>Password</Trans>
              </StyledLabel>
            }
            rules={[
              { required: true, message: t`Please fill in your password!` },
            ]}
          >
            <StyledPassword
              prefix={<LockOutlined />}
              type="password"
              placeholder={t`Password`}
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={20}>
              <Col span={14}>
                <Form.Item name="remember" valuePropName="checked">
                  <StyledCheckbox onChange={onCheckboxChange}>
                    <Trans>Stay logged in</Trans>
                  </StyledCheckbox>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <StyledRecoveryLink to="/recover">
                    <Trans>Forgot your password?</Trans>
                  </StyledRecoveryLink>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <StyledButtonWrapper>
            <StyledButton type="primary" htmlType="submit">
              <Trans>Log in</Trans>
            </StyledButton>
          </StyledButtonWrapper>
        </Form>
      </Wrapper>
    </Spin>
  );
};
