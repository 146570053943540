/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
import { t } from '@lingui/macro';

export const getMucnatkaChartOptions = (data: any, options = {}): any => {
  const dates = data.map((item: any) => {
    return item.date_day;
  });

  const index = data.map((item: any) => {
    return item.index;
  });

  const warning = data.map((item: any) => {
    return 30;
  });

  const error = data.map((item: any) => {
    return 60;
  });

  const mucnatkaChartOptions = {
    color: ['lightblue', 'orange', 'red'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'cross',
      },
    },
    xAxis: {
      type: 'category',
      name: t`Date`,
      nameLocation: 'center',
      nameTextStyle: {
        padding: 20,
      },
      data: dates,
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      scale: true,
      name: t`Index value`,
      nameLocation: 'center',
      nameTextStyle: {
        padding: 20,
      },
      axisLine: {
        lineStyle: {
          color: '#151B1E',
        },
      },
      splitLine: {
        show: true,
      },
    },
    grid: {
      top: 50,
      bottom: 80,
      show: true,
    },
    icon: 'circle',
    series: [
      {
        name: t`Index value`,
        type: 'bar',
        data: index,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
        },
      },
      {
        name: t`Moderate risk threshold`,
        type: 'line',
        data: warning,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
        },
      },
      {
        name: t`High risk threshold`,
        type: 'line',
        data: error,
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 1.5,
        },
      },
    ],
  };

  return mucnatkaChartOptions;
};
