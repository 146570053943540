//@ts-nocheck
import moment from 'moment';
import round from 'lodash/round';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import meanBy from 'lodash/meanBy';
import _get from 'lodash/get';
import { t } from '@lingui/macro';

export const getTableData = (
  data: any,
  windsData: any,
  agroData: any,
  sensorDepths: Array<string>,
  devicesAvailable: any,
) => {
  const getMinValue = (sensor: string, anyData: any) => {
    return minBy(anyData, sensor);
  };

  const getMaxValue = (sensor: string, anyData: any) => {
    return maxBy(anyData, sensor);
  };

  const getMinValueBySensor = (sensor: string, units: string, anyData: any) => {
    const min = getMinValue(sensor, anyData);
    const minQuantity = _get(min, sensor);
    if (minQuantity == null || isNaN(minQuantity)) {
      return '-';
    }
    const roundedMin = round(minQuantity, 2);
    return `${roundedMin} ${units}`;
  };

  const getMaxValueBySensor = (sensor: string, units: string, anyData: any) => {
    const max = getMaxValue(sensor, anyData);
    const maxQuantity = _get(max, sensor);
    if (maxQuantity == null || isNaN(maxQuantity)) {
      return '-';
    }
    const roundedMax = round(maxQuantity, 2);
    return `${roundedMax} ${units}`;
  };

  const getAverageValue = (sensor: string, units: string, anyData: any) => {
    const average = meanBy(anyData, sensor);
    if (average == null || isNaN(average)) {
      return '-';
    }
    const roundedAverage = round(average, 2);
    return `${roundedAverage} ${units}`;
  };

  const getMinRec = (sensor: string, anyData: any) => {
    const min = getMinValue(sensor, anyData);
    const minQuantity = _get(min, sensor);
    if (minQuantity == null || isNaN(minQuantity)) {
      return '-';
    }
    const timestamp = _get(min, 'timestamp');
    return moment(timestamp).format('DD.MM.YYYY HH:mm');
  };

  const getMaxRec = (sensor: string, anyData: any) => {
    const max = getMaxValue(sensor, anyData);
    const maxQuantity = _get(max, sensor);
    if (maxQuantity == null || isNaN(maxQuantity)) {
      return '-';
    }

    const timestamp = _get(max, 'timestamp');
    return moment(timestamp).format('DD.MM.YYYY HH:mm');
  };

  const getSum = (sensor: string, units: string, anyData: any) => {
    let total = 0;
    anyData?.map((item) => {
      const value = _get(item, sensor);
      total = total + value;
    });
    total = round(total, 2);
    return `${total} ${units}`;
  };

  let sensorPlantTemp: boolean = false;

  if (
    agroData[0]?.plant_canopy_temperature !== null &&
    agroData[0]?.plant_canopy_temperature !== undefined
  ) {
    sensorPlantTemp = true;
  }

  let tableData = [
    {
      key: 1,
      sensor: t`Temperature`,
      min: getMinValueBySensor('temperature', '°C', data),
      minRec: getMinRec('temperature', data),
      max: getMaxValueBySensor('temperature', '°C', data),
      maxRec: getMaxRec('temperature', data),
      average: getAverageValue('temperature', '°C', data),
      sum: '-',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 2,
      sensor: t`Pressure`,
      min: getMinValue('pressure', data)?.pressure
        ? `${round(getMinValue('pressure', data)?.pressure / 100, 2)} hPa`
        : '-',
      minRec: getMinRec('pressure', data),
      max: getMaxValue('pressure', data)?.pressure
        ? `${round(getMaxValue('pressure', data)?.pressure / 100, 2)} hPa`
        : '-',
      maxRec: getMaxRec('pressure', data),
      average: `${round(meanBy(data, 'pressure') / 100, 2)} hPa`,
      sum: '-',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 3,
      sensor: t`Humidity`,
      min: getMinValueBySensor('humidity', '%', data),
      minRec: getMinRec('humidity', data),
      max: getMaxValueBySensor('humidity', '%', data),
      maxRec: getMaxRec('humidity', data),
      average: getAverageValue('humidity', '%', data),
      sum: '-',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 4,
      sensor: t`Irradiation`,
      min: getMinValueBySensor('irradiation', 'W/m2', data),
      minRec: getMinRec('irradiation', data),
      max: getMaxValueBySensor('irradiation', 'W/m2', data),
      maxRec: getMaxRec('irradiation', data),
      average: '-',
      sum: getSum('irradiation', 'W/m2', data),
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 5,
      sensor: t`Battery voltage`,
      min: getMinValueBySensor('battery', 'V', data),
      minRec: getMinRec('battery', data),
      max: getMaxValueBySensor('battery', 'V', data),
      maxRec: getMaxRec('battery', data),
      average: getAverageValue('battery', 'V', data),
      sum: '-',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 6,
      sensor: t`Precipitation`,
      min: getMinValueBySensor('rain', 'mm', data),
      minRec: getMinRec('rain', data),
      max: getMaxValueBySensor('rain', 'mm', data),
      maxRec: getMaxRec('rain', data),
      average: '-',
      sum: getSum('rain', 'mm', data),
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      key: 7,
      sensor: t`Wind speed`,
      min: getMinValueBySensor('wind_ave10', 'm/s', windsData),
      minRec: getMinRec('wind_ave10', windsData),
      max: getMaxValueBySensor('wind_ave10', 'm/s', windsData),
      maxRec: getMaxRec('wind_ave10', windsData),
      average: getAverageValue('wind_ave10', 'm/s', windsData),
      sum: '-',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      key: 8,
      sensor: t`Maximum wind gust`,
      min: '-',
      minRec: '-',
      max: getMaxValueBySensor('wind_max10', 'm/s', windsData),
      maxRec: getMaxRec('wind_max10', windsData),
      average: '-',
      sum: '-',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      key: 9,
      sensor: t`Leaf humidity`,
      min: getMinValueBySensor('plant_canopy_sensor', '', agroData),
      minRec: getMinRec('plant_canopy_sensor', agroData),
      max: getMaxValueBySensor('plant_canopy_sensor', '', agroData),
      maxRec: getMaxRec('plant_canopy_sensor', agroData),
      average: getAverageValue('plant_canopy_sensor', '', agroData),
      sum: '-',
      settings: true,
      hardware: devicesAvailable.agro,
    },
    {
      key: 10,
      sensor: t`Leaf temperature`,
      min: getMinValueBySensor('plant_canopy_temperature', '°C', agroData),
      minRec: getMinRec('plant_canopy_temperature', agroData),
      max: getMaxValueBySensor('plant_canopy_temperature', '°C', agroData),
      maxRec: getMaxRec('plant_canopy_temperature', agroData),
      average: getAverageValue('plant_canopy_temperature', '°C', agroData),
      sum: '-',
      settings: sensorPlantTemp,
      hardware: devicesAvailable.agro,
    },
    {
      key: 11,
      sensor: t`Soil temperature at depth ${sensorDepths[0]}`,
      min: getMinValueBySensor('soil_temp1', '°C', agroData),
      minRec: getMinRec('soil_temp1', agroData),
      max: getMaxValueBySensor('soil_temp1', '°C', agroData),
      maxRec: getMaxRec('soil_temp1', agroData),
      average: getAverageValue('soil_temp1', '°C', agroData),
      sum: '-',
      settings: sensorDepths[0],
      hardware: devicesAvailable.agro,
    },

    {
      key: 12,
      sensor: t`Soil temperature at depth ${sensorDepths[1]}`,
      min: getMinValueBySensor('soil_temp2', '°C', agroData),
      minRec: getMinRec('soil_temp2', agroData),
      max: getMaxValueBySensor('soil_temp2', '°C', agroData),
      maxRec: getMaxRec('soil_temp2', agroData),
      average: getAverageValue('soil_temp2', '°C', agroData),
      sum: '-',
      settings: sensorDepths[1],
      hardware: devicesAvailable.agro,
    },

    {
      key: 13,
      sensor: t`Soil temperature at depth ${sensorDepths[2]}`,
      min: getMinValueBySensor('soil_temp3', '°C', agroData),
      minRec: getMinRec('soil_temp3', agroData),
      max: getMaxValueBySensor('soil_temp3', '°C', agroData),
      maxRec: getMaxRec('soil_temp3', agroData),
      average: getAverageValue('soil_temp3', '°C', agroData),
      sum: '-',
      settings: sensorDepths[2],
      hardware: devicesAvailable.agro,
    },

    {
      key: 14,
      sensor: t`Soil moisture at depth ${sensorDepths[0]}`,
      min: getMinValueBySensor('soil_moisture1', 'kPa', agroData),
      minRec: getMinRec('soil_moisture1', agroData),
      max: getMaxValueBySensor('soil_moisture1', 'kPa', agroData),
      maxRec: getMaxRec('soil_moisture1', agroData),
      average: getAverageValue('soil_moisture1', 'kPa', agroData),
      sum: '-',
      settings: sensorDepths[0],
      hardware: devicesAvailable.agro,
    },

    {
      key: 15,
      sensor: t`Soil moisture at depth ${sensorDepths[1]}`,
      min: getMinValueBySensor('soil_moisture2', 'kPa', agroData),
      minRec: getMinRec('soil_moisture2', agroData),
      max: getMaxValueBySensor('soil_moisture2', 'kPa', agroData),
      maxRec: getMaxRec('soil_moisture2', agroData),
      average: getAverageValue('soil_moisture2', 'kPa', agroData),
      sum: '-',
      settings: sensorDepths[1],
      hardware: devicesAvailable.agro,
    },

    {
      key: 16,
      sensor: t`Soil moisture at depth ${sensorDepths[2]}`,
      min: getMinValueBySensor('soil_moisture3', 'kPa', agroData),
      minRec: getMinRec('soil_moisture3', agroData),
      max: getMaxValueBySensor('soil_moisture3', 'kPa', agroData),
      maxRec: getMaxRec('soil_moisture3', agroData),
      average: getAverageValue('soil_moisture3', 'kPa', agroData),
      sum: '-',
      settings: sensorDepths[2],
      hardware: devicesAvailable.agro,
    },
  ];

  const filteredTableData = tableData.filter((item) => {
    if (item.hardware && item.settings) {
      return true;
    }
  });

  return filteredTableData;
};
