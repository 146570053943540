import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';

/*Custom imports*/
import { ConditionInfectionModal } from 'components/elements/ConditionInfectionModal';
import { ConditionModal } from 'components/elements/ConditionModal';
import { InfectionButton } from './style';
import { Trans } from '@lingui/macro';

interface Props {
  userType: string;
}

export const ConditionPickAlarmType: React.FC<Props> = ({ userType }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infectionModal, setInfectionModal] = useState(false);
  const [conditionModal, setConditionModal] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openInfectionModal = () => {
    setInfectionModal(true);
  };

  const openConditionModal = () => {
    setConditionModal(true);
  };

  const checkForUser = (userType: string) => {
    if (userType === 'Winer') {
      showModal();
    } else {
      openConditionModal();
    }
  };

  return (
    <>
      <Col span={24} style={{ textAlign: 'end' }}>
        <Button type="primary" onClick={() => checkForUser(userType)}>
          <Trans>Add</Trans>
        </Button>
        <Modal
          style={{ textAlign: 'center' }}
          title={<Trans> Add condition</Trans>}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Col>
              <InfectionButton type="primary" onClick={openInfectionModal}>
                <Trans>Disease pressure</Trans>
              </InfectionButton>
            </Col>
            <Col>
              <Button type="primary" onClick={openConditionModal}>
                <Trans>Weather variables</Trans>
              </Button>
            </Col>
          </Row>
        </Modal>
        <ConditionInfectionModal
          infectionModal={infectionModal}
          setInfectionModal={setInfectionModal}
          setIsModalVisible={setIsModalVisible}
        ></ConditionInfectionModal>

        <ConditionModal
          conditionModal={conditionModal}
          setConditionModal={setConditionModal}
          setIsModalVisible={setIsModalVisible}
        ></ConditionModal>
      </Col>
    </>
  );
};
