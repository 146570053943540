//@ts-nocheck
import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Parser } from 'json2csv';
import round from 'lodash/round';
import { CSVLink } from 'react-csv';
import XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import '../../../utils/font';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import _get from 'lodash/get';
import { getTableData } from './StatisticsData';
import { StyledMenuItem, StyledTable, ButtonWrapper } from './style';
import { Trans } from '@lingui/macro';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

const columns = [
  {
    title: <Trans>Unit</Trans>,
    dataIndex: 'sensor',
    key: 'sensor',
    width: 170,
  },
  {
    title: <Trans>Min.</Trans>,
    dataIndex: 'min',
    key: 'min',
    width: 130,
  },
  {
    title: <Trans>Min. recorded</Trans>,
    dataIndex: 'minRec',
    key: 'minRec',
    width: 170,
  },
  {
    title: <Trans>Max.</Trans>,
    dataIndex: 'max',
    key: 'max',
    width: 130,
  },
  {
    title: <Trans>Max. recorded</Trans>,
    dataIndex: 'maxRec',
    key: 'maxRec',
    width: 170,
  },
  {
    title: <Trans>Average for period</Trans>,
    dataIndex: 'average',
    key: 'average',
    width: 170,
  },
  {
    title: <Trans>Sum for period</Trans>,
    dataIndex: 'sum',
    key: 'sum',
    width: 170,
  },
];

interface Props {
  data: any;
  windsData: any;
  agroData: any;
  sensorDepths: Array<string>;
  devicesAvailable: devicesAvailable;
}

export const StatisticsTable: React.FC<Props> = ({
  data,
  windsData,
  agroData,
  sensorDepths,
  devicesAvailable,
}) => {
  const tableData = getTableData(
    data,
    windsData,
    agroData,
    sensorDepths,
    devicesAvailable,
  );

  const json2csvParser = new Parser();
  const csv = tableData.length > 0 ? json2csvParser.parse(tableData) : [];

  const getFileName = (type: string, fileType: string) => {
    const date = `sensor-statistic-${type}-data_${moment(new Date()).format(
      'YYYY-MM-DD_hh-mm-ss',
    )}.${fileType}`;
    return date;
  };

  const createCumulativeValues = (anyData: any, quantity: string) => {
    let total = 0;

    const cumulativeValues = anyData
      .slice()
      .reverse()
      .map((item: any) => {
        let newData = _get(item, quantity);
        total = total + newData;
        return round(total, 2);
      });

    cumulativeValues.reverse();

    return cumulativeValues;
  };

  const createRows = (sheetData: any, anyData: any) => {
    let rows = [];

    const cumulativeValues = createCumulativeValues(anyData, 'rain');

    const getItem = (item, sheetData: any, index) => {
      const row = sheetData.map((sheetItem) => {
        if (sheetItem.type === 'cumulative') {
          return cumulativeValues[index];
        }

        if (sheetItem.type === 'time') {
          let newData = _get(item, sheetItem.quantity);
          newData = moment(newData).format(DATE_FORMAT);
          return newData;
        }

        if (sheetItem.quantity === 'pressure') {
          let newData = _get(item, sheetItem.quantity);
          newData = newData / 100;
          newData = round(newData, 2);
          return newData;
        }

        let newData = _get(item, sheetItem.quantity);
        newData = round(newData, 2);

        return newData;
      });

      rows.push(row);
    };

    anyData?.map((item, index) => {
      getItem(item, sheetData, index);
    });

    return rows;
  };

  const createHeaders = (sheetData: any) => {
    const headers = sheetData.map((item) => {
      return item.name;
    });

    return headers;
  };

  const helixSheetData = [
    {
      name: <Trans>Measurement time</Trans>,
      quantity: 'timestamp',
      type: 'time',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Temperature °C</Trans>,
      quantity: 'temperature',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Pressure hPa</Trans>,
      quantity: 'pressure',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Humidity %</Trans>,
      quantity: 'humidity',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Sunlight W/m2</Trans>,
      quantity: 'irradiation',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Battery voltage V</Trans>,
      quantity: 'battery',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Precipitation</Trans>,
      quantity: 'rain',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.helix,
    },
    {
      name: <Trans>Cumulative precipitation mm</Trans>,
      quantity: 'rain',
      type: 'cumulative',
      settings: true,
      hardware: devicesAvailable.helix,
    },
  ];

  const windsSheetData = [
    {
      name: <Trans>Measurement time</Trans>,
      quantity: 'timestamp',
      type: 'time',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      name: <Trans>Wind speed m/s</Trans>,
      quantity: 'wind_ave10',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      name: <Trans>Wind direction °</Trans>,
      quantity: 'dir_ave10',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      name: <Trans>Strongest wind gust m/s</Trans>,
      quantity: 'wind_max10',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.wind,
    },
    {
      name: <Trans>Wind gust direction °</Trans>,
      quantity: 'dir_max10',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.wind,
    },
  ];

  let sensorPlantTemp = false;
  if (
    agroData[0]?.plant_canopy_temperature !== null &&
    agroData[0]?.plant_canopy_temperature !== undefined
  ) {
    sensorPlantTemp = true;
  }

  const agroSheetData = [
    {
      name: <Trans>Measurement time</Trans>,
      quantity: 'timestamp',
      type: 'time',
    },

    {
      name: <Trans>Soil temperature at depth ${sensorDepths[0]} °C</Trans>,
      quantity: 'soil_temp1',
      type: 'normal',
      settings: sensorDepths[0],
      hardware: devicesAvailable.agro,
    },

    {
      name: <Trans>Soil temperature at depth ${sensorDepths[1]} °C</Trans>,
      quantity: 'soil_temp2',
      type: 'normal',
      settings: sensorDepths[1],
      hardware: devicesAvailable.agro,
    },

    {
      name: <Trans>Soil temperature at depth ${sensorDepths[2]} °C</Trans>,
      quantity: 'soil_temp3',
      type: 'normal',
      settings: sensorDepths[2],
      hardware: devicesAvailable.agro,
    },

    {
      name: <Trans>Soil moisture at depth ${sensorDepths[0]} kPa</Trans>,
      quantity: 'soil_moisture1',
      type: 'normal',
      settings: sensorDepths[0],
      hardware: devicesAvailable.agro,
    },

    {
      name: <Trans>Soil moisture at depth ${sensorDepths[1]} kPa</Trans>,
      quantity: 'soil_moisture2',
      type: 'normal',
      settings: sensorDepths[1],
      hardware: devicesAvailable.agro,
    },

    {
      name: <Trans>Soil moisture at depth ${sensorDepths[2]} kPa</Trans>,
      quantity: 'soil_moisture3',
      type: 'normal',
      settings: sensorDepths[2],
      hardware: devicesAvailable.agro,
    },
    {
      name: <Trans>Leaf moisture</Trans>,
      quantity: 'plant_canopy_sensor',
      type: 'normal',
      settings: true,
      hardware: devicesAvailable.agro,
    },
    {
      name: <Trans>Leaf temperature</Trans>,
      quantity: 'plant_canopy_temperature',
      type: 'normal',
      settings: sensorPlantTemp,
      hardware: devicesAvailable.agro,
    },
  ];

  const filterData = (anyData: any) => {
    const filteredSheetData: any = anyData.filter((item: any) => {
      if (item.hardware && item.settings) {
        return true;
      }
    });

    return filteredSheetData;
  };

  const helixFiltered = filterData(helixSheetData);
  const windsFiltered = filterData(windsSheetData);
  const agroFiltered = filterData(agroSheetData);

  const helixHeaders = createHeaders(helixFiltered);
  const windsHeaders = createHeaders(windsFiltered);
  const agroHeaders = createHeaders(agroFiltered);

  const helixRows = createRows(helixFiltered, data);
  const windsRows = createRows(windsFiltered, windsData);
  const agroRows = createRows(agroFiltered, agroData);

  if (!helixRows) {
    return null;
  }

  const createSheet = (headers: Array<string>, rows: any) => {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    return ws;
  };

  const saveXlsxFile = (wb: any, name: string) => {
    const fileName = getFileName(name, 'xlsx');
    XLSX.writeFile(wb, fileName);
  };

  const generateXLSXfromChart = () => {
    const wsHelix = createSheet(helixHeaders, helixRows);
    const wsWinds = createSheet(windsHeaders, windsRows);
    const wsAgro = createSheet(agroHeaders, agroRows);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsHelix, 'Data');
    XLSX.utils.book_append_sheet(wb, wsWinds, 'Winds');
    XLSX.utils.book_append_sheet(wb, wsAgro, 'Agro');

    saveXlsxFile(wb, 'chart');
  };

  const generateXLSXfromTable = () => {
    const titles = columns.map((item) => {
      return item.title;
    });
    const wsHeading = [titles];

    const filteredData = tableData.map((item) => {
      return {
        sensor: item.sensor,
        min: item.min,
        minRec: item.minRec,
        max: item.max,
        maxRec: item.maxRec,
        average: item.average,
        sum: item.sum,
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData, { skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, wsHeading);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    saveXlsxFile(wb, 'table');
  };

  const savePdfFile = (doc: any, name: string) => {
    const fileName = getFileName(name, 'pdf');
    doc.save(fileName);
  };

  const createTable = (doc: any, headers: Array<string>, rows: any) => {
    doc.autoTable(headers, rows, {
      styles: {
        font: 'Poppins-Regular',
        fontStyle: 'normal',
      },
    });
  };

  const generatePDFfromChart = () => {
    const doc = new jsPDF('l', 'mm', [297, 210]);
    createTable(doc, helixHeaders, helixRows);
    createTable(doc, windsHeaders, windsRows);
    createTable(doc, agroHeaders, agroRows);

    savePdfFile(doc, 'chart');
  };

  const generatePDFfromTable = () => {
    const doc = new jsPDF('l', 'mm', [297, 210]);
    createTable(doc, columns, tableData);

    savePdfFile(doc, 'table');
  };

  const menu = (
    <Menu>
      <StyledMenuItem key="0">
        <CSVLink
          data={helixRows}
          headers={helixHeaders}
          filename={getFileName('chart', 'csv')}
        >
          <Trans>Data from the graph (.csv)</Trans>
        </CSVLink>
      </StyledMenuItem>
      <StyledMenuItem key="1" onClick={generateXLSXfromChart}>
        <Trans>Graph data (.xlsx)</Trans>
      </StyledMenuItem>
      <StyledMenuItem key="2" onClick={generatePDFfromChart}>
        <Trans>Graph data (.pdf)</Trans>
      </StyledMenuItem>
      <StyledMenuItem key="3">
        <CSVLink data={csv} filename={getFileName('table', 'csv')}>
          <Trans>Table data (.csv)</Trans>
        </CSVLink>
      </StyledMenuItem>
      <StyledMenuItem key="4" onClick={generateXLSXfromTable}>
        <Trans>Table data (.xlsx)</Trans>
      </StyledMenuItem>
      <StyledMenuItem key="5" onClick={generatePDFfromTable}>
        <Trans>Table data (.pdf)</Trans>
      </StyledMenuItem>
    </Menu>
  );

  const width = (columns.length - 2) * 170 + 2 * 130;

  return (
    <div>
      <StyledTable
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ x: `${width}px` }}
      />
      <ButtonWrapper>
        <Dropdown overlay={menu} trigger={['click']} placement="topRight">
          <Button type="primary" icon={<DownloadOutlined />}>
            <Trans>Export</Trans>
          </Button>
        </Dropdown>
      </ButtonWrapper>
    </div>
  );
};
