//@ts-nocheck
import React from 'react';
import { Table, Row, Col } from 'antd';
import styled from 'styled-components';
import { errorCell, successCell } from '../TableSettings/TableSettings';
import { Trans } from '@lingui/macro';

const StyledTable = styled(Table)`
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px !important;
  }

  .ant-table.ant-table-small {
    font-size: 12px;
  }
  .ant-table-cell {
    text-align: center !important;
  }

  .ant-alert {
    padding: 0px !important;
  }
`;

const columns = [
  {
    title: <Trans>Date</Trans>,
    dataIndex: 'date_day',
    render: (item: any) => item.split('T')[0],
    defaultSortOrder: 'descend',
    sorter: (a, b) => Date.parse(a.date_day) - Date.parse(b.date_day),
  },
  {
    title: <Trans>Average temperature (°C)</Trans>,
    dataIndex: 'avg_t',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: <Trans>Min. temperature (°C)</Trans>,
    dataIndex: 'min_t',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: <Trans>Daily precipitation (mm)</Trans>,
    dataIndex: 'rain_sum_one_day',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: <Trans>Two-day precipitation (mm)</Trans>,
    dataIndex: 'rain_sum_two_days',
    render: (item: any) => (item === null ? '' : item.toFixed(1)),
  },
  {
    title: <Trans>Daily conditions</Trans>,
    dataIndex: 'one_day_condition',
    render: (item: any) =>
      item === true
        ? errorCell(<Trans>Fulfilled</Trans>)
        : successCell(<Trans>Unfulfilled</Trans>),
  },
  {
    title: <Trans>Two-day conditions</Trans>,
    dataIndex: 'two_day_condition',
    render: (item: any) =>
      item === true
        ? errorCell(<Trans>Fulfilled</Trans>)
        : successCell(<Trans>Unfulfilled</Trans>),
  },
];

export const VineMoldTable = (plesenData: any) => {
  let i = 0;
  return (
    <>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <StyledTable
            bordered
            size="small"
            rowKey={() => i++}
            pagination={{ defaultPageSize: 20 }}
            columns={columns}
            dataSource={plesenData['plesenData']}
          />
        </Col>
      </Row>
    </>
  );
};
