import React from 'react';
import { Form, Select, InputNumber, Typography } from 'antd';
import { Trans } from '@lingui/macro';
import { PlusOutlined } from '@ant-design/icons';

/*Custom imports*/
import { StyledDivider, FormSection, FormItemWrapper } from 'styles/settings';
import {
  StyledBinOutlined,
  StyledButton,
  StyledSpace,
  StyledFormItem,
} from './style';

const { Title } = Typography;

export const SettingsDepthSensors = () => {
  return (
    <>
      <FormSection>
        <Form.Item>
          <Title level={5}>
            <Trans>Soil sensor options</Trans>
          </Title>
          <StyledDivider />
        </Form.Item>
        <FormItemWrapper>
          <Form.List name="depthSensors">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <StyledSpace key={field.key} align="baseline">
                    <StyledFormItem
                      {...field}
                      label={`${(<Trans>Soil sensor</Trans>)} #${
                        index + 1
                      } (cm)`}
                      name={[index]}
                      fieldKey={[field.fieldKey, 'depth']}
                      rules={[
                        {
                          required: true,
                          message: <Trans>Missing depth value</Trans>,
                        },
                      ]}
                      style={{ marginBottom: '0px' }}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </StyledFormItem>
                    <StyledBinOutlined onClick={() => remove(field.name)} />
                  </StyledSpace>
                ))}

                <Form.Item>
                  <StyledButton
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={fields.length < 3 ? false : true}
                  >
                    <Trans>Add sensor</Trans>
                  </StyledButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </FormItemWrapper>
      </FormSection>
    </>
  );
};
