import _round from 'lodash/round';
import _get from 'lodash/get';
import { EChartOption } from 'echarts';
import { t } from '@lingui/macro';

/**
 * Returns ECharts option object
 * @see https://echarts.apache.org/en/option.html#title
 *
 * @param options {Ojbect} object to merge
 */
export const getPressureChartOptions = (chartData = [], color: string): any => {
  const last24HoursValues = chartData.map((item: any) => {
    return _round(item.pressure / 100, 2);
  });

  const last24HoursDates = chartData.map((item: any) => {
    return new Date(item.timestamp).toLocaleTimeString('sk', {
      hour: '2-digit',
      minute: '2-digit',
    });
  });

  const currentPressure = _get({ chartData }, 'chartData[0].pressure') / 100;

  const pressureChartOptions: EChartOption = {
    backgroundColor: color,
    title: {
      text: currentPressure ? `${_round(currentPressure, 2)} hPa` : '-',
      top: 10,
      left: 10,
      textStyle: {
        color: '#ffffff',
      },
    },
    animation: false,
    tooltip: {
      show: true,
      trigger: 'item',
      position: 'inside',
    },
    grid: {
      show: false,
      left: 56,
      top: 46,
      right: 16,
      bottom: 26,
      borderColor: 'transparent',
    },
    xAxis: {
      type: 'category',
      data: last24HoursDates.reverse(),
      show: true,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
    },

    yAxis: [
      {
        type: 'value',
        min: function (value) {
          return Math.floor(value.min);
        },
        max: function (value) {
          return Math.ceil(value.max);
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
    ],
    series: [
      {
        name: t`Last 24 hours`,
        type: 'line',
        smooth: true,
        tooltip: {
          formatter: '{b} {c} hPa',
        },
        itemStyle: {
          color: '#ffffff',
        },
        data: last24HoursValues.reverse(),
        areaStyle: {
          opacity: 0.3,
        },
      },
    ],
  };

  return pressureChartOptions;
};
