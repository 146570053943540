import { Col, Row, Spin } from 'antd';
import React from 'react';

export const Loader = () => {
  return (
    <Row
      justify="space-around"
      align="middle"
      style={{
        height: '100%',
        minHeight: '96px',
      }}
    >
      <Col
        span={24}
        style={{
          textAlign: 'center',
        }}
      >
        <Spin size={'large'} />
      </Col>
    </Row>
  );
};
