import React, { Fragment } from 'react';

import { Route, Redirect } from 'react-router-dom';

/** Custom imports */
import { List } from 'components/pages/List';
import { FormApp } from 'components/pages/Form';
import { Files } from 'components/pages/Files';
import { World } from 'components/pages/World';
import { Alarms } from 'components/pages/Alarms';
import { Dashboard } from 'components/pages/Dashboard';
import { Tutorials } from 'components/pages/Tutorials';
import { UserDashboard } from 'components/pages/UserDashboard';

export const AppRoutes = () => {
  return (
    <Fragment>
      <Route exact path={process.env.PUBLIC_URL + '/'}>
        <Redirect to="/worldmap" from="/" />
      </Route>
      <Route exact path={process.env.PUBLIC_URL + '/users'}>
        <Redirect to="/worldmap" from="/" />
      </Route>
      <Route path="/dashboard/:id" component={Dashboard} />
      <Route path="/list" component={List} />
      <Route path="/form" component={FormApp} />
      <Route path="/files" component={Files} />
      <Route path="/user-dashboard" component={UserDashboard} />
      <Route path="/worldmap" component={World} />
      <Route path="/alarms" component={Alarms} />
      <Route path="/tutorials" component={Tutorials} />
    </Fragment>
  );
};
