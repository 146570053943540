import React from 'react';
import video8 from '../../../assets/videos/video_8.mp4';
import video9 from '../../../assets/videos/video_9.mp4';

/*Styles*/
import { Divider, Row, Col } from 'antd';
import {
  Container,
  VideoInner,
  Video,
  StandardText,
  StyledTitle,
} from '../../../styles/tutorials';
import { Trans } from '@lingui/macro';

export const TutorialAlarms = () => {
  return (
    <>
      <Container>
        <Row gutter={[64, 0]} style={{ paddingTop: '32px' }}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Alerts</Trans>
            </StyledTitle>
            <StandardText>
              <p>
                <Trans>
                  Setting up alerts for meeting the configured condition (e.g.,
                  humidity exceeding 50% for 4 hours). With the "Add" function,
                  we can set alert parameters such as name, monitored weather
                  stations, measured variable, time range, and alert delivery
                  method. By default (without filling in the column), the method
                  is set to alert in the weather portal (record in the "Events"
                  section), but it is also possible to send alerts via email or
                  SMS.
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video8} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
      <Divider />
      <Container>
        <Row gutter={[64, 0]}>
          <Col sm={24} lg={8}>
            <StyledTitle level={4}>
              <Trans>Contact information</Trans>
            </StyledTitle>
            <StandardText>
              <p>
                <Trans>
                  Contact information can be set in the basic account settings.
                  The phone number needs to be entered in international format
                  (prefix +421).
                </Trans>
              </p>
            </StandardText>
          </Col>
          <Col sm={24} lg={16}>
            <VideoInner>
              <Video autoPlay muted loop>
                <source src={video9} type="video/mp4"></source>
              </Video>
            </VideoInner>
          </Col>
        </Row>
      </Container>
    </>
  );
};
