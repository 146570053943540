import { i18n } from '@lingui/core';
import { en, sk, da } from 'make-plural/plurals';

export const defaultLocale = 'en';

i18n.loadLocaleData({
  en: { plurals: en },
  sk: { plurals: sk },
  da: { plurals: da },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  let activeLocale = locale;
  let messages;

  try {
    ({ messages } = await import(`../locales/${activeLocale}/messages`));
  } catch (error) {
    console.error(
      `Failed to load messages for locale: ${activeLocale}, using default locale.`,
      error,
    );
    activeLocale = defaultLocale;
    ({ messages } = await import(`../locales/${activeLocale}/messages`));
  }

  localStorage.setItem('lang', activeLocale);
  i18n.load(activeLocale, messages);
  i18n.activate(activeLocale);
}
