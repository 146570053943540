//@ts-nocheck
import React, { useState } from 'react';
import { Table, Row, Col, Spin } from 'antd';
import moment from 'moment';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';
import 'jspdf-autotable';
import '../../../utils/font';
import { useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';

/**Custom imports */
import { GET_STATION_DATA } from 'graphql/queries';
import { activeStationVar } from 'utils/cache';
import { Loader } from 'components/elements';
import { ExportMenu } from 'components/elements/ExportMenu';
import { FilterButtons } from 'components/elements/FilterButtons';
/**Data types */
import { DevicesAvailable } from 'components/elements/StationTabs';
/*Styles */
import { StyledTitle } from './style';
import { Trans } from '@lingui/macro';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

const timezoneOffset = moment().utcOffset();

const intervals = {
  last31days: moment()
    .subtract(31, 'days')
    .utc()
    .add(timezoneOffset, 'minutes'),
  moment: moment().utc().add(timezoneOffset, 'minutes'),
};

interface Props {
  devicesAvailable: DevicesAvailable;
}

export const MeasurementTable: React.FC<Props> = ({ devicesAvailable }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const activeStation = useReactiveVar(activeStationVar);
  const settings = activeStation.Settings;

  const { loading, error } = useQuery(GET_STATION_DATA, {
    variables: {
      dateFrom: intervals.last31days,
      dateTo: intervals.moment,
      /* @ts-ignore */
      stationID: activeStation.id,
    },
    onCompleted({ getStationData }: any) {
      setFilteredData(getStationData);
      modifyData(getStationData);
    },
  });

  const [
    updateData,
    { loading: onClickLoading, error: onClickError },
  ] = useLazyQuery(GET_STATION_DATA, {
    onCompleted({ getStationData }: any) {
      setFilteredData(getStationData);
      modifyData(getStationData);
    },
  });

  let sensorDepths: Array<string> = [];
  const sensorSettings = _get(settings, 'ground_sensor_depths');

  const createDepths = (depths: Array<number>) => {
    const depthsWithUnits = depths.map((item) => {
      const depth = item.toString();
      const units = 'cm';
      const result = depth.concat(units);
      return result;
    });

    return depthsWithUnits;
  };

  if (sensorSettings !== undefined) {
    sensorDepths = createDepths(sensorSettings);
  }

  let sensorPlantTemp = false;
  if (
    filteredData[filteredData.length - 1]?.plant_canopy_temperature !== null &&
    filteredData[filteredData.length - 1]?.plant_canopy_temperature !==
      undefined
  ) {
    console.log(
      filteredData[filteredData.length - 1]?.plant_canopy_temperature,
    );
    sensorPlantTemp = true;
  }

  let columns = [
    {
      title: <Trans>Time</Trans>,
      label: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (item: any) => moment(item).format(DATE_FORMAT),
      width: '180px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Temperature °C</Trans>,
      label: 'Teplota °C',
      dataIndex: 'temperature',
      key: 'temperature',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Pressure hPa</Trans>,
      label: 'Tlak hPa',
      dataIndex: 'pressure',
      key: 'pressure',
      render: (item: any) => (item != null ? _round(item / 100, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Humidity %</Trans>,
      label: 'Vlhkosť %',
      dataIndex: 'humidity',
      key: 'humidity',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Irradiation W/m2</Trans>,
      label: 'Slnečný svit W/m2',
      dataIndex: 'irradiation',
      key: 'irradiation',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Battery voltage V</Trans>,
      label: 'Napätie batérie V',
      dataIndex: 'helix_battery',
      key: 'helix_battery',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Precipitation mm</Trans>,
      label: 'Zrážky mm',
      dataIndex: 'rain',
      key: 'rain',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.helix,
      settings: true,
    },
    {
      title: <Trans>Wind speed m/s</Trans>,
      label: 'Rýchlosť vetra m/s',
      dataIndex: 'wind_ave10',
      key: 'wind_ave10',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      title: <Trans>Wind direction °</Trans>,
      label: 'Smer Vetra °',
      dataIndex: 'dir_ave10',
      key: 'dir_ave10',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      title: 'Najsilnejší náraz vetra za 10 min m/s',
      label: <Trans>Strongest wind gust in the last 10 minutes m/s</Trans>,
      dataIndex: 'wind_max10',
      key: 'wind_max10',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      title: <Trans>Wind gust direction °</Trans>,
      label: 'Smer nárazu °',
      dataIndex: 'dir_max10',
      key: 'dir_max10',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.wind,
      settings: true,
    },
    {
      title: <Trans>Leaf humidity</Trans>,
      label: 'Vlhkosť listov',
      dataIndex: 'plant_canopy_sensor',
      key: 'plant_canopy_sensor',
      render: (item: any) => {
        if (item === null) {
          return '-';
        }
        if (item > 330) {
          return <Trans>Wet</Trans>;
        } else {
          return <Trans>Dry</Trans>;
        }
      },
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: true,
    },
    {
      title: <Trans>Leaf temperature °C</Trans>,
      label: 'Teplota listov °C',
      dataIndex: 'plant_canopy_temperature',
      key: 'plant_canopy_temperature',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorPlantTemp,
    },

    {
      title: <Trans>Soil temperature at depth ${sensorDepths[0]} °C</Trans>,
      label: `Teplota pôdy v hĺbke ${sensorDepths[0]} °C`,
      dataIndex: 'soil_temp1',
      key: 'soil_temp1',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[0],
    },

    {
      title: <Trans>Soil temperature at depth ${sensorDepths[1]} °C</Trans>,
      label: `Teplota pôdy v hĺbke ${sensorDepths[1]} °C`,
      dataIndex: 'soil_temp2',
      key: 'soil_temp2',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[1],
    },

    {
      title: <Trans>Soil temperature at depth ${sensorDepths[2]} °C</Trans>,
      label: `Teplota pôdy v hĺbke ${sensorDepths[2]} °C`,
      dataIndex: 'soil_temp3',
      key: 'soil_temp3',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[2],
    },
    {
      title: <Trans>Soil moisture at depth ${sensorDepths[0]} kPa</Trans>,
      label: `Vlhkosť pôdy v hĺbke ${sensorDepths[0]} kPa`,
      dataIndex: 'soil_moisture1',
      key: 'soil_moisture1',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[0],
    },

    {
      title: <Trans>Soil moisture at depth ${sensorDepths[1]} kPa</Trans>,
      label: `Vlhkosť pôdy v hĺbke ${sensorDepths[1]} kPa`,
      dataIndex: 'soil_moisture2',
      key: 'soil_moisture2',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[1],
    },

    {
      title: <Trans>Soil moisture at depth ${sensorDepths[2]} kPa</Trans>,
      label: `Vlhkosť pôdy v hĺbke ${sensorDepths[2]} kPa`,
      dataIndex: 'soil_moisture3',
      key: 'soil_moisture3',
      render: (item: any) => (item != null ? _round(item, 2) : '-'),
      width: '120px',
      hardware: devicesAvailable.agro,
      settings: sensorDepths[2],
    },
  ];

  const filteredColumns = columns.filter((item: any) => {
    if (item.hardware && item.settings) {
      return true;
    }
  });

  const loadData = (
    smartDateFrom: moment.Moment,
    smartDateTo: moment.Moment,
  ) => {
    updateData({
      variables: {
        dateFrom: smartDateFrom,
        dateTo: smartDateTo,
        stationID: activeStation.id,
      },
    });
  };

  const modifyData = (stationData: any) => {
    let temp: any = _cloneDeep(stationData);
    temp.forEach((item: any) => {
      item.timestamp = moment(item.timestamp).format(DATE_FORMAT);
    });
    setModifiedData(temp);
  };

  if (loading) {
    return <Loader />;
  }

  if (error || onClickError) {
    return (
      <p>
        <Trans>ERROR</Trans>
      </p>
    );
  }

  const width = (filteredColumns.length - 1) * 120 + 180;

  return (
    <div>
      <Spin spinning={onClickLoading}>
        <Row gutter={[40, 0]}>
          <Col span={6} xs={24}>
            <StyledTitle level={4}>
              <Trans>Measured data</Trans>
            </StyledTitle>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col
            span={6}
            xs={24}
            style={{ marginBottom: '16px', textAlign: 'end' }}
          >
            <FilterButtons loadData={loadData} defaultSelected="a" />
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col
            span={6}
            xs={24}
            style={{ marginBottom: '20px', textAlign: 'end' }}
          >
            <ExportMenu
              data={modifiedData}
              columns={filteredColumns}
            ></ExportMenu>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <Table
              rowKey="timestamp"
              columns={filteredColumns}
              dataSource={filteredData}
              pagination={{ position: ['bottomCenter'] }}
              scroll={{ x: `${width}px` }}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
